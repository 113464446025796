import Algorithm from '../partials/algorithm';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const PagebreakAlgorithm = (props) => {
    return (
        <section className={classnames('pagebreak-algorithm', props.blockTheme)}>
            <Algorithm {...props} />
        </section>
    );
};

PagebreakAlgorithm.propTypes = {
    blockTheme: PropTypes.string,
};

export default PagebreakAlgorithm;
