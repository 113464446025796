import defaultImage from 'static/images/svgs/art-toi.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// const ContentSetPage = () => {
const ContentSetPage = forwardRef(({ item, index, showTile }, ref) => {
    // display content type for content page. But not display for content type
    const getPageType = (data) => {
        switch (data) {
            case 'content':
                return '';
            case 'education':
                // label for education page type
                return 'Education resource';
            default:
                return data;
        }
    };

    const name = item.data.title;
    const url = item.data.uri;
    const image = item.data.thumbnail_image;
    const pageType = getPageType(item.data.page_type);

    return <article className={showTile ? 'tile tile-page' : 'tile-hide'} key={index} ref={ref}>
        <div>
            <Link className="tile-thumbnail-link" to={url}>
                <div className="tile-thumbnail">
                    <picture className="hover-area">
                        <div data-hover-text>view more</div>
                        <div className="image-container square">
                            <img className="tile-thumbnail-image" src={image ? image : defaultImage} alt={name} />
                        </div>
                    </picture>
                </div>
            </Link>
        </div>
        {/* detail section */}
        <div className="tile-details">
            <h5 className="tile-title">
                <Link to={url}>
                    {name}
                </Link>
            </h5>
            {pageType &&
                <p className="tile-page-type"><strong>{pageType}</strong></p>
            }
        </div>
    </article>;
});

ContentSetPage.displayName = 'ContentSetPage';

ContentSetPage.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showTile: PropTypes.bool
};

export default ContentSetPage;
