/* eslint-disable camelcase */
import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

const SliderArrow = (props) => {
    const { className, style, onClick } = props;
    const display = onClick === null ? 'none' : 'block';

    return (
        <button className={className} style={{ ...style, display: display }} onClick={onClick}>
            <Icon name="arrow" width="25" height="25" />
        </button>
    );
};

SliderArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
};

const RollingText = ({ data }) => {
    const { title, rolling_group } = data;
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderArrow />,
        prevArrow: <SliderArrow />,
        useTransform: false,
        variableWidth: true,
        appendDots: (dots) => (
            <div className="slick-dots">
                <ul> {dots} </ul>
            </div>
        ),
    };

    return (
        <section className="section || rolling-text-block">
            <div className="heading">
                <h3>{title}</h3>
            </div>
            <div className="rolling-text-wrapper">
                <Slider { ...settings }>
                    {rolling_group.map((item, index) => (
                        <div className="item" key={index}>
                            <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(item.text) }} />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

RollingText.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        rolling_group: PropTypes.array.isRequired
    })
};

export default RollingText;
