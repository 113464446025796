/* eslint-disable camelcase */
import ArticleTitleBar from '../partials/article-title-bar';
import Carousel from '../content-blocks/carousel';
import ContentBlocks from 'app/components/content-blocks/index';
import EventDate from '../partials/event-date';
import Image from '../content-blocks/image';
import PropTypes from 'prop-types';
import React from 'react';

const News = ({ data }) => {
    const {
        author,
        name,
        publish_at,
        short_description,
        main_images,
        blocks,
    } = data;

    const renderAuthor = () => {
        if (author) {
            return <h6>{author}</h6>;
        }

        return null;
    };

    const renderHeader = () => {
        return (
            <header className="article-header">
                {renderAuthor()}
                <ArticleTitleBar title={name} />
                <h6 className="dates">
                    <strong><EventDate startDate={publish_at} endDate={publish_at} /></strong>
                </h6>
            </header>
        );
    };

    const renderHeroCarousel = (images) => {
        if (images.length > 1) {
            return <Carousel
                isHero
                items={images.map((image) => ({ file: image, title: name }))}
            />;
        } else if (images.length === 1) {
            return <Image file={images[0]} creditline={name} hero />;
        }

        return null;
    };

    const renderNewsDetail = () => {
        return (
            <section className="news-detail">
                <h2 className="heading">Article Detail</h2>
                <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: short_description }}></div>
            </section>
        );
    };

    const renderContentBlocks = () => {
        return <ContentBlocks data={blocks} />;
    };

    return (
        <div className="content-page constrain-width">
            <article className="news-page">
                {renderHeader()}
                {renderHeroCarousel(main_images)}
                {renderNewsDetail()}
                {renderContentBlocks()}
            </article>
        </div>
    );
};

News.propTypes = {
    data: PropTypes.shape({
        author: PropTypes.string,
        name: PropTypes.string,
        publish_at: PropTypes.string,
        short_description: PropTypes.string,
        main_images: PropTypes.array,
        blocks: PropTypes.array,
    })
};

export default News;
