import artistImage from 'static/images/svgs/artist.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const ContentSetArtist = ({ blockStyle = {}, item, index, showTile }) => {
    const getArtistInital = (data) => {
        const allNames = data.trim().split(' ');
        const initials = allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === allNames.length - 1) {
                // eslint-disable-next-line no-param-reassign
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }

            return acc;
        }, '');

        return initials;
    };
    const getArtistSlug = (data) => {
        return `/explore-art-and-ideas/artist/${data.id}/${data.slug}`;
    };

    const getIconStyle = () => {
        const style = {
            backgroundImage: `url(${thumbnailIcon})`
        };

        // Allow custom background color
        if (blockStyle.backgroundColor) {
            style.backgroundColor = blockStyle.backgroundColor;
        }

        return style;
    };

    const name = item.name;
    const url = getArtistSlug(item.data);
    const initials = getArtistInital(name);
    const thumbnailIcon = artistImage;


    return (
        <article className={showTile ? 'tile tile-artist' : 'tile-hide'} key={index}>
            <div>
                <Link className="tile-thumbnail-link" to={url}>
                    <div className="tile-thumbnail">
                        <picture className="hover-area">
                            <div data-hover-text>view artist</div>
                            <div className="image-container || square || bg-grey">
                                {initials ? <h5 className="tile-initials">{initials}</h5> :
                                    ''
                                }
                            </div>
                        </picture>
                        {thumbnailIcon && <div className="tile-thumbnail-icon" style={getIconStyle()}></div>}
                    </div>
                </Link>
            </div>
            {/* detail section */}
            <div className="tile-details">
                <h5 className="tile-title">
                    <Link to={url}>
                        {name}
                    </Link>
                </h5>
            </div>
        </article>
    );
};

ContentSetArtist.propTypes = {
    blockStyle: PropTypes.object,
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showTile: PropTypes.bool
};
export default ContentSetArtist;
