import { getArtistRoute } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const ArtistNames = ({ artists, withRole = false }) => {
    if (!artists || !artists.length) {
        return null;
    }

    return (
        <span className="artist-names">
            {artists.map((artist, idx) => {
                const displayName = withRole ? artist.display_name_with_role : artist.display_name;

                return <Link to={getArtistRoute(artist.id, artist.slug)} key={idx}>{displayName}</Link>;
            }).reduce((prev, curr) => [prev, ', ', curr])}
        </span>
    );
};

ArtistNames.propTypes = {
    artists: PropTypes.array,
    withRole: PropTypes.bool,
};

export default ArtistNames;
