/* eslint-disable jsx-a11y/label-has-associated-control */
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const SearchBar = ({ formik, withAdvanceLink = false }) => {
    return (
        <div className="search-bar">
            <div className="field-group grow">
                <label htmlFor="search-query" className="label search"><Icon name="search" width="24" height="24" /></label>
                <input className="input" id="search-query" type="text" name="keywords" value={formik.values.keywords} onChange={formik.handleChange} placeholder="Search for..." autoComplete="off" />
            </div>
            <div className="field-group shrink">
                <button className="button primary small" type="submit">SEARCH</button>
            </div>
            {withAdvanceLink && (
                <div className="field-group shrink">
                    <Link className="button || default || small || block" to={FRONTEND_ROUTES.ADVANCED_SEARCH}>ADVANCED SEARCH</Link>
                </div>
            )}
        </div>
    );
};

SearchBar.propTypes = {
    formik: PropTypes.object.isRequired,
    withAdvanceLink: PropTypes.bool,
};

export default SearchBar;
