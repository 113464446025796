import ContentSet from '../content-blocks/content-set';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Wrapper around content set to fetch items on show more instead of initial load
// This fixes issues with slow archive and archive item pages
const ContentSetArchiveItems = ({ archiveId, items }) => {
    const [allItems, setAllItems] = useState(false);

    // Initial items are only partial
    const displayedItems = allItems || items;

    // format as required by content set component
    const contentSetItems = displayedItems.map((item) => {
        return {
            data: item,
            type: 'archive_item',
        };
    });

    const onShowMore = async() => {
        // Only fetch if we haven't done so already
        if (!allItems) {
            const res = await fetch(ENDPOINTS.ARCHIVE_ITEMS_ALL(archiveId));
            setAllItems(res);
        }
    };

    return (
        // If we change the initial display rows we must make sure the backend returns more items than initially displayed
        // Otherwise the Show All button won't appear
        <ContentSet items={contentSetItems} onShowMore={onShowMore} rows="1" />
    );
};

ContentSetArchiveItems.propTypes = {
    archiveId: PropTypes.number,
    items: PropTypes.array,
};
export default ContentSetArchiveItems;
