import Helmet from 'react-helmet';
import React from 'react';
import SearchGrid from 'app/components/partials/search-grid';
import SocialSharing from '../partials/social-sharing';
import { useParams } from 'react-router-dom';

const SearchPage = () => {
    const { reference } = useParams();

    return (
        <main className="page" role="main">
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />

                <title>Auckland Art Gallery</title>
                <meta name="description" content="Search" />
                <meta name="keywords" content={reference} />
            </Helmet>
            {/* pass in a key to force react to remount the search grid when the search entity is changed
            this fixes bugs with the component state retaining (e.g. after navigating from events search page to exhibitions search page */}
            <SearchGrid reference={reference} key={reference} />
            <SocialSharing />
        </main>
    );
};

SearchPage.propTypes = {
};

export default SearchPage;
