/* eslint-disable dot-notation, no-multi-spaces */
import classnames from 'classnames';
import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

const Notification = (props) => {
    const {
        description,
        handleToggle,
        id,
        isVisible,
        title,
        type,
        uncloseable,
        url
    } = props;

    const notificationClass = classnames(`notification type-${type}`, {
        'is-visible': isVisible,
        'is-uncloseable': uncloseable
    });

    return (
        <div className={notificationClass}>
            <div className="notification-inner || constrain-width">
                <Icon
                    className="notification-icon"
                    name={type}
                />
                <div className="notification-content">
                    <p className="notification-title">{title}</p>
                    {(url !== '' && description !== '') && <a href={url}>{description}</a> ||
                    (description !== '') && <p>{description}</p>}
                </div>
                {!uncloseable && <button className="notification-toggle" onClick={() => handleToggle(id)}>
                    <Icon
                        className="notification-icon-close"
                        name="close"
                    />
                </button>}
            </div>
        </div>
    );
};


Notification.propTypes = {
    description: PropTypes.string.isRequired,
    handleToggle: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    isVisible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired, // one of NOTIFICATION_TYPES[x].className
    uncloseable: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired
};

export default Notification;
