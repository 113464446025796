/* eslint-disable camelcase */
import archiveImage from 'static/images/svgs/archive.svg';
import ArticleTitleBar from '../partials/article-title-bar';
import ArtistNames from '../partials/artist-names';
import Carousel from '../content-blocks/carousel';
import ContentSetArchiveItems from '../partials/content-set-archive-items';
import EntitySpecDetails from '../partials/entity-spec-details';
import { getArchivesRoute } from '../../utilities/routes';
import Image from '../content-blocks/image';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { renderVernonField } from '../../utilities/format-content';

const ArchiveItem = ({ data }) => {
    const {
        name,
        zoom_images,
        brief_desc,
        artists,
        production_date,
        material_desc,
        dimensions,
        credit_line,
        accession_no,
        restriction,
        copyright,
        collection,
        collection_id,
        archive,
    } = data;

    const renderHeader = () => {
        return (
            <header className="article-header">
                <ArticleTitleBar title={name} />
            </header>
        );
    };

    const renderImageBanner = () => {
        if (zoom_images.length > 1) {
            return <Carousel
                isHero
                items={zoom_images.map((image) => ({ file: image, title: name }))}
            />;
        }

        return <Image file={zoom_images[0]} hero creditline={name} />;
    };

    const renderArtistNames = (withRole = false) => {
        return <ArtistNames artists={artists} withRole={withRole} />;
    };

    const getSpecDetailRows = () => {
        return [
            {
                label: 'Creator',
                value: renderArtistNames(),
            },
            {
                label: 'Title',
                value: name,
            },
            {
                label: 'Date',
                value: production_date,
            },
            {
                label: 'Medium',
                value: material_desc,
            },
            {
                label: 'Size',
                value: dimensions,
            },
            {
                label: 'Credit line',
                value: credit_line,
            },
            {
                label: 'Accession no',
                value: accession_no,
            },
            {
                label: 'Restriction type',
                value: restriction,
            },
            {
                label: 'Copyright',
                value: copyright,
            },
            {
                label: 'Collection name',
                value: collection,
            },
        ];
    };

    const getArchiveIconStyle = () => {
        return {
            backgroundImage: `url(${archiveImage})`
        };
    };

    const renderItemDetails = () => {
        return (
            <section className="item-details">
                <h2 className="heading">Item Detail</h2>

                <div className="item-details-body">
                    <div className="item-description">
                        {renderVernonField(brief_desc)}
                        <EntitySpecDetails rows={getSpecDetailRows()} />
                    </div>

                    <div className="item-collection">
                        <h4 className="heading heading-margin">Part Of</h4>
                        <div className="tile-archive">
                            <h5 className="heading heading-margin"><Link to={getArchivesRoute(collection_id)}>{archive.name}</Link></h5>
                            <Link class="tile-archive-thumbnail" to={getArchivesRoute(collection_id)}>
                                <picture>
                                    <img src={archive.thumbnail_image} alt={archive.name} />
                                </picture>
                                <div className="tile-archive-thumbnail-icon" style={getArchiveIconStyle()}></div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    const renderItems = () => {
        if (!archive.items || !archive.items.length) {
            return null;
        }

        return (
            <div>
                <h2 className="heading">Other Items In This Collection</h2>
                <ContentSetArchiveItems items={archive.items} archiveId={archive.id} />
            </div>
        );
    };

    return (
        <div className="content-page constrain-width">
            <article className="archive-item-page">
                {renderHeader()}
                {renderImageBanner()}
                {renderItemDetails()}
                {renderItems()}
            </article>
        </div>
    );
};

ArchiveItem.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        zoom_images: PropTypes.array,
        brief_desc: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ]),
        artists: PropTypes.array,
        production_date: PropTypes.string,
        material_desc: PropTypes.string,
        dimensions: PropTypes.string,
        credit_line: PropTypes.string,
        accession_no: PropTypes.string,
        restriction: PropTypes.string,
        copyright: PropTypes.string,
        collection: PropTypes.string,
        // parent archive collection
        collection_id: PropTypes.string,
        archive: PropTypes.shape({
            id: PropTypes.number,
            thumbnail_image: PropTypes.string,
            name: PropTypes.string,
            items: PropTypes.array,
        })
    })
};

export default ArchiveItem;
