import carouselHOC from 'app/utilities/carousel-single.hoc';
import classnames from 'classnames';
import { getVideoIframeUrl } from 'app/utilities/video';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

const Carousel = ({ items, isHero = false, sliderOpts = {} }) => {
    // Props are needed to remove console warning (even though they are unused)
    // See https://github.com/akiran/react-slick/issues/623#issuecomment-629764816
    const Arrow = ({ currentSlide, slideCount, ...props }) => (
        <button {...props}>
            <Icon name="arrow" width="25" height="25" />
        </button>
    );

    const settings = {
        infinite: true,
        autoplay: !isHero,
        dots: isHero,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <Arrow />,
        prevArrow: <Arrow />,
        useTransform: false,
        ...sliderOpts
    };

    const renderVideo = (videoUrl, title) => {
        if (!videoUrl) {
            return null;
        }

        return (
            <div className="video-outer">
                <div className="video">
                    <iframe
                        className="video-inner"
                        title={title}
                        src={getVideoIframeUrl(videoUrl, { controls: '0', autoplay: '1' })}
                        frameBorder="0"
                        allow="autoplay"
                    ></iframe>
                    <div className="video-cloak"></div>
                </div>
            </div>
        );
    };

    const renderImage = (file, title) => {
        if (!file) {
            return null;
        }

        return (
            <div className="image-container">
                <img src={file} alt={title} />
            </div>
        );
    };

    const renderCaption = (textBg, textColor, title, subtitle) => {
        if (!title && !subtitle) {
            return null;
        }

        return (
            <div className="content-container" style={{ backgroundColor: textBg, color: textColor }}>
                {title && !isHero && <h2 className="title">{title}</h2>}
                {subtitle && !isHero && <p className="subtitle">{subtitle}</p>}
            </div>
        );
    };

    const cls = classnames('section', 'carousel-block', {
        'hero': isHero,
    });

    return (
        <section className={cls}>
            <div className="carousel-block-inner">
                <Slider {...settings}>
                    {items.map(({
                        file,
                        title = null,
                        subtitle = null,
                        link = null,
                        video = null,
                        'text-color': textColor = null,
                        'text-bg': textBg = null
                    }, index) => (
                        <div key={index}>
                            <div>
                                <a className="slider-item" href={link}>
                                    {renderVideo(video)}
                                    {renderImage(file, title)}
                                    {renderCaption(textBg, textColor, title, subtitle)}
                                </a>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

Carousel.propTypes = {
    items: PropTypes.array.isRequired,
    sliderOpts: PropTypes.object,
    // controls slider settings and styles for template detail page hero carousel
    isHero: PropTypes.bool,
};

export default carouselHOC(Carousel);
