/* eslint-disable require-jsdoc */
/**
 * Add or remove the class to the top headroom
 */
// TODO: Consider re-implementing using RXJS and managing the 'body' N  ode?

function getTopHeadroom() {
    return document.querySelector('.top-headroom');
}

export function hideHeadroom() {
    getTopHeadroom().classList.add('hide');
}

export function showHeadroom() {
    getTopHeadroom().classList.remove('hide');
}
