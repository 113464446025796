import AdvancedSearchForm from 'app/components/partials/category-search/advanced-search-form';
import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const ADVANCED_SEARCH_FILTERS = ['only', 'objectType', 'collections', 'undated', 'nationalities', 'artist'];

const FilterDropdownForAdvancedSearch = ({ className, formik }) => {
    const [openOptionList, setOpenOptionList] = useState(false);
    const displayLabel = Object.keys(formik.values).filter((key) => {
        return ADVANCED_SEARCH_FILTERS.includes(key) && formik.values[key].length !== 0;
    }).reduce((prev, key) => {
        if (key === 'undated' && formik.values[key] === true) return prev ? `${key},${prev}` : key;
        if (typeof formik.values[key] === 'string' && key !== 'undated') return prev ? `${prev},${formik.values[key]}` : formik.values[key];
        if (Array.isArray(formik.values[key])) return prev ? `${prev},${formik.values[key].join(',')}` : formik.values[key].join(',');

        return prev;
    }, '');
    const filterRef = useRef();

    const toggleDropdownHandler = () => {
        setOpenOptionList((prevValue) => !prevValue);
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpenOptionList(false);
        } else if (event.target.type === 'submit') {
            setOpenOptionList(false);
        }
    };

    useEffect(() => {
        const optionHeight = filterRef.current.getElementsByClassName('options')[0].clientHeight;
        const marginBottom = openOptionList ? `${optionHeight}px` : null;
        filterRef.current.style['margin-bottom'] = marginBottom;

        if (openOptionList === false) {
            return;
        }
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [openOptionList]);

    return (
        <div className={ classnames('filter-dropdown', 'wide', className, openOptionList ? 'open' : '') } ref={filterRef}>
            <div className="dropdown" onClick={toggleDropdownHandler} aria-hidden="true">
                <div className="label">Refine <span>{displayLabel}</span></div>
                <Icon name="arrow" width="15" height="15" title="" />
            </div>
            <AdvancedSearchForm className={openOptionList ? '' : 'is-hidden'} formik={formik} />
        </div>
    );
};

FilterDropdownForAdvancedSearch.propTypes = {
    className: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired
};

export default FilterDropdownForAdvancedSearch;
