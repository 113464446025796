import banner from 'static/images/error-banner.jpg';
import Layout from '../layout';
import PagebreakAlgorithm from '../../content-blocks/pagebreak-algorithm';
import React from 'react';

const Error = () => (
    <Layout noIndex withoutShare>
        <div className="error-page content-page">
            <div className="error-page-inner || constrain-width">
                <PagebreakAlgorithm
                    file={banner}
                    background="black"
                    left={'mino\r\ns'}
                    right={'\r\numble'}
                    location="left"
                    algorithm_text="A minor stumble"
                />
                <div className="error-page-description">
                    <h1 className="heading-2">It looks like we have a server error</h1>
                    <p>
                    Our team is looking into it and we will fix it as soon as we can. <br className="breakline"/>
                    How can we help? Get in touch at <a href="mailto:info@aucklandunlimited.com">info@aucklandunlimited.com</a> or <span className="no-wrap">call <a href="tel:+6493092677">+64 9 309 2677</a></span>.
                    </p>
                </div>
            </div>
        </div>
    </Layout>
);

export default Error;
