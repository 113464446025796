//  TO DELETE
import { getDayAndMonth } from 'app/utilities/time';

export const getOpeningHoursMessage = () => {
    const { month, day } = getDayAndMonth();
    const isTodayClosed = month === '12' && day === '25';
    let message;

    if (isTodayClosed) {
        message = 'We\'re closed today';
    } else {
        message = 'We\'re open every day from 10am \u2014 5pm, except Christmas day';
    }

    return message;
};
