/* eslint-disable camelcase */
import Error from './error/error-page';
import Layout from './layout';
import NotFound from './error/not-found-page';
import PropTypes from 'prop-types';
import React from 'react';
import { RESPONSE } from 'config/api';
import Template from 'app/components/templates/index';
import templateType from 'app/utilities/template-type';

const ContentPage = ({ page }) => {
    // Render not error/not found page
    if (!page || page.error && page.error.status === RESPONSE.INTERNAL_SERVER_ERROR) return <Error />;
    if (page.error && page.error.status === RESPONSE.NOT_FOUND || !page.attributes) return <NotFound />;

    const { attributes, type } = page;

    const {
        slug,
        uri,
    } = attributes;

    const templateTypeToUse = templateType(slug, type, uri);

    return (
        <Layout pageAttrs={attributes}>
            <Template
                type={templateTypeToUse}
                data={page.attributes}
            />
        </Layout>
    );
};

ContentPage.propTypes = {
    page: PropTypes.object.isRequired
};

export default ContentPage;
