/* eslint-disable camelcase */
import DonationForm from '../partials/donation-form';
import PropTypes from 'prop-types';
import React from 'react';


const Donation = ({ suggestedAmounts, file, productId, no_overlay, content }) => {
    const blockInlineStyle = {
        backgroundImage: `url('${file}')`
    };

    const renderOverlay = () => {
        return (
            <div className="donation-block || overlay" style={blockInlineStyle}>
                <article className="donation-block-content">
                    <div className="donation-block-content-inner rich-editor-content" dangerouslySetInnerHTML={{ __html: content }}>
                    </div>
                </article>
                <aside className="donation-block-aside">
                    <div className="donation-block-aside-inner">
                        <DonationForm suggestedAmounts={suggestedAmounts} productId={productId}
                            overlay={true}/>
                    </div >
                </aside >
            </div >
        );
    };

    const renderNoOverlay = () => {
        return (
            <div className="donation-block || no-overlay" >
                <div className="donation-block-no-overlay-content rich-editor-content" dangerouslySetInnerHTML={{ __html: content }}>
                </div>
                <div className="donation-block-no-overlay-row">
                    <article className="donation-block-no-overlay-image" style={blockInlineStyle}>
                    </article>
                    <aside className="donation-block-no-overlay-aside">
                        <div className="donation-block-no-overlay-aside-inner">
                            <DonationForm suggestedAmounts={suggestedAmounts} productId={productId}
                                overlay={false}/>
                        </div >
                    </aside >
                </div>
            </div >
        );
    };

    return (
        <>
            {no_overlay === '1' ? renderNoOverlay() : renderOverlay()}
        </>
    );
};


Donation.propTypes = {
    suggestedAmounts: PropTypes.array.isRequired,
    file: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    no_overlay: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default Donation;

