import { url } from 'rfa-react-core';

export const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Slugify the string
 */
export function slugify(title) {
    // remove accents from UTF-8 characters
    return url.transliterate(title)
        // Convert all underscores into dashes
        .replaceAll('_', '-')
        // Replace @ with the word 'at'
        .replaceAll('@', '-at-')
        // convert all characters to lower case
        .toLowerCase()
        // Remove all characters that are not the separator, letters, numbers, or whitespace.
        .replace(/[^-\p{L}\p{N}\s]/ug, '')
        // trim whitespace from start and end
        .trim()
        // Replace all separator characters and whitespace by a single separator
        .replace(/[-\s]+/g, '-');
}
