// Data formatting
export const TIMESTAMP = 'YYYYMMDDHHMMSS';
export const DATE_FORMAT_MIN = 'D MMM';
export const DATE_FORMAT_MIN_WITH_YEAR = 'D MMM YYYY';
export const DATE_FORMAT_WITH_YEAR = 'DD MMM YYYY';
export const DATE_FORMAT_FULL = 'YYYY-MM-DD';
export const DATE_FORMAT_EVENT_PAGE = 'ddd D MMM YYYY';
export const DATE_FORMAT_DATE_PICKER = 'DD/MM/YYYY';
export const PRICE_FORMAT = '$0,0.00';
export const NZ_UTC_OFFSET = 12;
export const NZ_TIMEZONE = 'Pacific/Auckland';
export const SITE_NAME = 'Auckland Art Gallery';
export const SITE_DESC = 'Auckland Art Gallery Toi o Tāmaki is the largest art institution in New Zealand, with a collection numbering over 15,000 works. These include major holdings of New Zealand historic, modern and contemporary art, and outstanding works by Māori and Pacific Island artists, as well as European painting, sculpture and print collections ranging in date from 1376 to the present day.';
