import classnames from 'classnames';
import FilterDropdown from 'app/components/partials/category-search/filter-dropdown';
import FilterDropdownForAdvancedSearch from 'app/components/partials/category-search/filter-dropdown-for-advanced-search';
import FilterDropdownForArchive from 'app/components/partials/category-search/filter-dropdown-for-archive';
import FilterDropdownForIdea from 'app/components/partials/category-search/filter-dropdown-for-idea';
import FilterDropdownWithDate from 'app/components/partials/category-search/filter-dropdown-with-date';
import PropTypes from 'prop-types';
import React from 'react';
import SearchBar from 'app/components/partials/category-search/search-bar';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { ARCHIVE_SORT_FILTER, ARTWORK_SORT_FILTER, EVENT_DATE_FILTER, EVENT_TYPE_FILTER, EXHIBITION_DATE_FILTER, IDEA_SORT_FILTER } from 'config/search-options';

const CategorySearch = ({ reference, searchHandler, constrainWidth = true, keywordsOnly = false }) => {
    const [searchParams] = useSearchParams();
    const formik = useFormik({
        initialValues: {
            keywords: searchParams.get('keywords') || '',
            'event-type': searchParams.get('event-type') || (reference === 'events' ? EVENT_TYPE_FILTER.options[EVENT_TYPE_FILTER.defaultIndex].value : ''),
            'date-range': searchParams.get('date-range') || ((reference === 'events' || reference === 'exhibitions') ? EVENT_DATE_FILTER.options[EVENT_DATE_FILTER.defaultIndex].value : ''),
            'from-date': searchParams.get('from-date') || '',
            'to-date': searchParams.get('to-date') || '',
            only: searchParams.getAll('only[]') || [],
            objectType: searchParams.getAll('objectType[]') || [],
            collections: searchParams.getAll('collections[]') || [],
            contentType: searchParams.getAll('contentType[]') || [],
            undated: !searchParams.has('from-date') && !searchParams.has('to-date'),
            nationalities: searchParams.getAll('nationalities[]') || [],
            artist: searchParams.get('artist') || '',
        },
        onSubmit: async(values) => {
            searchHandler(values);
        },
        // ensure search param changes are synced to formik
        enableReinitialize: true,
    });

    const renderFilterGroups = (reference) => {
        if (keywordsOnly) {
            return null;
        }

        switch (reference) {
            case 'events':
                return (
                    <div className="filter-groups">
                        <FilterDropdownWithDate className="event-dates" filterName="date-range" filterOptions={EVENT_DATE_FILTER.options} filterLable="Date Range" defaultOptionIndex={EVENT_DATE_FILTER.defaultIndex} formik={formik} />
                        <FilterDropdown className="event-types" filterName="event-type" filterOptions={EVENT_TYPE_FILTER.options} filterLable="Event Type" defaultOptionIndex={EVENT_TYPE_FILTER.defaultIndex} formik={formik} />
                    </div>
                );
            case 'exhibitions':
                return (
                    <div className="filter-groups">
                        <FilterDropdownWithDate className="event-dates" filterName="date-range" filterOptions={EXHIBITION_DATE_FILTER.options} filterLable="Date Range" defaultOptionIndex={EXHIBITION_DATE_FILTER.defaultIndex} formik={formik} />
                    </div>
                );
            case 'artworks':
                return (
                    <div className="filter-groups">
                        <FilterDropdownForAdvancedSearch className="advanced-filter" formik={formik} />
                        <FilterDropdown className="sort-by" filterName="sort" filterOptions={ARTWORK_SORT_FILTER.options} filterLable="Sort by" defaultOptionIndex={ARTWORK_SORT_FILTER.defaultIndex} formik={formik} />
                    </div>
                );
            case 'archives':
                return (
                    <div className="filter-groups">
                        <FilterDropdownForArchive className="advanced-filter" formik={formik} />
                        <FilterDropdown className="sort-by" filterName="sort" filterOptions={ARCHIVE_SORT_FILTER.options} filterLable="Sort by" defaultOptionIndex={ARCHIVE_SORT_FILTER.defaultIndex} formik={formik} />
                    </div>
                );
            case 'ideas':
                return (
                    <div className="filter-groups">
                        <FilterDropdownForIdea className="advanced-filter" formik={formik} />
                        <FilterDropdown className="sort-by" filterName="sort" filterOptions={IDEA_SORT_FILTER.options} filterLable="Sort by" defaultOptionIndex={IDEA_SORT_FILTER.defaultIndex} formik={formik} />
                    </div>
                );
            case 'articles':
                return null;
            default:
                return 'default';
        }
    };

    const showAdvanceLink = () => {
        if (keywordsOnly) {
            return false;
        }

        return ['artworks', 'archives'].includes(reference);
    };

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className={classnames({ 'constrain-width': constrainWidth })}>
                <SearchBar formik={formik} withAdvanceLink={showAdvanceLink()} />
                {renderFilterGroups(reference)}
            </div>
        </form>
    );
};

CategorySearch.propTypes = {
    reference: PropTypes.string.isRequired,
    searchHandler: PropTypes.func.isRequired,
    constrainWidth: PropTypes.bool,
    keywordsOnly: PropTypes.bool,
};

export default CategorySearch;
