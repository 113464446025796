import defaultImage from 'static/images/svgs/art-toi.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const ContentSetArchiveItem = ({ item, showTile }) => {
    const getItemSlug = (collectionId, id) => {
        return `/explore-art-and-ideas/archives/${collectionId}/item/${id}`;
    };

    const {
        id,
        collection_id,
        gallery_image: image,
        name,
    } = item.data;

    const url = getItemSlug(collection_id, id);

    return (
        <article className={showTile ? 'tile tile-archive-item' : 'tile-hide'}>
            <div >
                <Link className="tile-archive-artwork tile-thumbnail-link" to={url}>
                    <div className="tile-thumbnail || rectango">
                        <picture className="hover-area">
                            <div data-hover-text>view archive item</div>
                            <div className="image-container rectango">
                                <img className="tile-thumbnail-image" src={image ? image : defaultImage} alt={name} />
                            </div>
                        </picture>
                    </div>
                </Link>
            </div>
            {/* detail section */}
            <div className="tile-details">
                <h5 className="tile-title">
                    <Link to={url}>
                        {name}
                    </Link>
                </h5>
            </div>
        </article>
    );
};

ContentSetArchiveItem.propTypes = {
    item: PropTypes.object.isRequired,
    showTile: PropTypes.bool
};

export default ContentSetArchiveItem;
