import classnames from 'classnames';
import defaultImage from 'static/images/svgs/art-toi.svg';
import formatContent from 'app/utilities/format-content';
import PropTypes from 'prop-types';
import React from 'react';

const Image = ({ file = null, creditline = '', hero = false }) => {
    const figureCls = classnames({
        'hero': hero,
    });

    const getImage = () => {
        if (file) {
            return file;
        }

        return defaultImage;
    };

    return (
        <section className="section || image-block">
            <figure className={figureCls}>
                <div className="image-container">
                    <img src={getImage()} alt={creditline} />
                </div>
                {!hero &&
                    <figcaption className="sidecaption">
                        <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(creditline) }}></div>
                    </figcaption>
                }
            </figure>
        </section>
    );
};

Image.propTypes = {
    file: PropTypes.string.isRequired,
    creditline: PropTypes.string,
    // hero image for event details page
    hero: PropTypes.bool
};

export default Image;
