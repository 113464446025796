/* eslint-disable jsx-a11y/label-has-associated-control */
import ContentBlocks from 'app/components/content-blocks/index';
import EducationTabs from '../partials/custom-page/education-tabs';
import PageHeader from 'app/components/partials/page-header';
import PropTypes from 'prop-types';
import React from 'react';

const EducationGroup = ({ data }) => {
    const {
        blocks,
        title,
        slug,
        header_image: images,
        background_color: backgroundColor,
    } = data;

    return (
        <div className="education-group-page">
            <PageHeader
                image={images}
                subTitle="School Bookings"
                title="Education groups"
                backgroundColor={backgroundColor}
            />
            <div className="constrain-width">
                {/* force re-render when a tab is clicked so that the mobile tablist closes after navigation */}
                <EducationTabs activeTab={slug} key={slug} />
                <h2 className="heading heading-margin">About {title}</h2>
            </div>
            <ContentBlocks data={blocks} pageTitle={title} extraClass="constrain-width" />
        </div>
    );
};

EducationGroup.propTypes = {
    data: PropTypes.object.isRequired
};

export default EducationGroup;
