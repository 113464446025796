/* eslint-disable camelcase */
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import hero from 'static/images/search-form-hero.jpg';
import Layout from './layout';
import Modal from 'app/components/partials/modal';
import MultipleSelect from 'app/components/partials/multiple-select';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { COLLECTION_OPTION, NATIONALITY_OPTION, ONLY_OPTION } from 'config/search-options';
import React, { useEffect, useState } from 'react';

const AdvancedCollectionSearchPage = () => {
    const [objectTypeOptions, setObjectTypeOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const nagivate = useNavigate();

    const formik = useFormik({
        initialValues: {
            section: 'collection',
            keywords: '',
            artist: '',
            name: '',
            'from-date': '',
            'to-date': '',
            undated: false,
            medium: '',
            objectType: [],
            nationalities: [],
            collections: [],
            only: [],
        },
        onSubmit: async(values) => {
            const searchParams = new URLSearchParams();
            Object.entries(values).forEach(([key, values]) => {
                // Do nothing when value is not valid
                if (!values || values.length === 0) {
                    return;
                }
                // Set search parameters
                if (typeof values === 'string' || typeof values === 'number') searchParams.append(key, values);
                if (Array.isArray(values)) values.forEach((value) => searchParams.append(`${key}[]`, value));
                if (key === 'undated' && values === true) searchParams.append(key, key);
            });
            nagivate(`/search/artworks?${searchParams.toString()}`);
        }
    });

    const fetchObjectTypeOptions = async() => {
        try {
            const resp = await fetch(ENDPOINTS.OBJECT_TYPES);
            setObjectTypeOptions(resp.map((item) => { return { label: item, value: item }; }));
        } catch (e) {
            //
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    useEffect(async() => {
        await fetchObjectTypeOptions();
    }, []);

    const renderBanner = () => {
        return (
            <figure className="banner black">
                <img src={hero} alt="Articles Banner" />
                <div className="algorithm-right">
                    <p className="white || text-block || text-right">
                        Pa<br />
                        Ques<br />
                    </p>
                    <p className="red || text-block">
                        A<br />
                        R<br />
                        T<br />
                    </p>
                    <p className="white || text-block">
                        ticular<br />
                        ion<br />
                    </p>
                </div>
            </figure>
        );
    };

    const renderSearchForm = () => {
        return (
            <div className="search-form">
                <div className="title">
                    <h2>Advanced Collection Search</h2>
                    <p>Our advanced search gives you the ability to search our entire collection and archives in one place. Use the search form below to search for specific items. All fields accept search operands (e.g. “+”, “|”, “-”). Click <i>Search operators</i> for usage details.</p>
                </div>
                <div className="form-container">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="field-group primary">
                            <label className="label" htmlFor="keywords">Keywords</label>
                            <input type="search" name="keywords" id="keywords" placeholder="e.g. oil painting" value={formik.values.keywords} onChange={formik.handleChange} />
                            <a className="message || text-small highlight right" onClick={openModal}>(Search operators)</a>
                        </div>
                        <div className="field-group primary">
                            <label className="label" htmlFor="artist">Artist / Creator</label>
                            <input type="search" name="artist" id="artist" placeholder="e.g. McCahon" value={formik.values.artist} onChange={formik.handleChange} />
                            <a className="message || text-small highlight right" onClick={openModal}>(Search operators)</a>
                        </div>
                        <div className="field-group primary">
                            <label className="label" htmlFor="name">Name / Title</label>
                            <input type="search" name="name" id="name" placeholder="e.g. Still Life" value={formik.values.name} onChange={formik.handleChange} />
                            <a className="message || text-small highlight right" onClick={openModal}>(Search operators)</a>
                        </div>

                        <div className="field-group primary">
                            <label className="label" htmlFor="from-date">Production year</label>
                            <div className="field-group secondary">
                                <div className="field-group date inline">
                                    <label className="label" htmlFor="from-date">From:</label>
                                    <input className="year" type="number" min="0" max="2023" name="from-date" id="from-date" placeholder="e.g. 1642" value={formik.values['from-date']} onChange={formik.handleChange} />
                                </div>

                                <div className="field-group date inline">
                                    <label className="label" htmlFor="to-date">To:</label>
                                    <input className="year" type="number" min="0" max="2023" name="to-date" id="to-date" placeholder="e.g. 2023" value={formik.values['to-date']} onChange={formik.handleChange} />
                                </div>

                                <div className="field-group tertiary">
                                    <div className="field-group checkbox inline left-label">
                                        <label htmlFor="undated">
                                            <input className="checkbox" type="checkbox" id="undated" name="undated" value="undated" checked={formik.values.undated} onChange={formik.handleChange} />
                                            <span>Include undated results?</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field-group primary">
                            <label className="label" htmlFor="medium">Medium / Material</label>
                            <input type="search" name="medium" id="medium" placeholder="e.g. charcoal" value={formik.values.medium} onChange={formik.handleChange} />
                        </div>

                        <MultipleSelect id="objectType" name="objectType" options={objectTypeOptions} label="Object types" values={formik.values.objectType} onChange={formik.handleChange} />
                        <MultipleSelect id="nationalities" name="nationalities" options={NATIONALITY_OPTION} label="Artist / Creator Nationality" values={formik.values.nationalities} onChange={formik.handleChange} />
                        <MultipleSelect id="collections" name="collections" options={COLLECTION_OPTION} label="Collection" values={formik.values.collections} onChange={formik.handleChange} />

                        <div className="field-group primary">
                            <label className="label" htmlFor="limit">Limit results to</label>
                            <div className="field-group">
                                {ONLY_OPTION.map((option, index) => (
                                    <div key={index} className="field-group || checkbox">
                                        <label>
                                            <input type="checkbox" name="only" value={option.value} checked={formik.values.only.includes(option.value)} onChange={formik.handleChange} />
                                            <span>{option.label}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <button className="button primary" type="submit">Search</button>
                        <button className="button" type="button" onClick={formik.resetForm}>Reset</button>
                    </form>
                </div>
            </div>
        );
    };

    return (
        <div>
            <Layout pageAttrs={{
                seo_title: 'Advanced Collection Search'
            }}>
                <div className="content-page advanced-collection-search-page constrain-width">
                    {renderBanner()}
                    {renderSearchForm()}
                </div>
            </Layout>

            <Modal isActive={isModalOpen} closeModalHandler={() => setIsModalOpen(false)}>
                <div>
                    <h2>Search Operators</h2>
                </div>
                <div className="description">
                    <p>The keyword, artist/creator and name/title fields in this form accept search operators.  These allow you to construct more complex search enquiries.</p>
                    <h4>+ AND</h4>
                    <p>
                        Adding a + before a search term will return results that match ALL terms. <br />
                        e.g. “Parekowhai +neil” will return only results that contain “Parekowhai” and “Neil”
                    </p>
                    <h4>- NOT</h4>
                    <p>
                        Adding a - before a search term excludes results that match that term. <br />
                        e.g. “Parekowhai -neil” will return results that include “Parekowhai” but do not include “neil”
                    </p>
                    <h4>| OR</h4>
                    <p>
                        Adding a | before a search term will include results that match your first search term or the search term after the |. <br />
                        e.g. “McCahon |Parekowhai” will return results that match “McCahon” or “Parekowhai”.
                    </p>
                    <h4>* Wildcard</h4>
                    <p>
                        Adding a * to your search allows you to search using a partial search term. <br />
                        e.g. “Au*” will return “Australia”, “Austria” etc.
                    </p>
                    <h4>“” Phrase</h4>
                    <p>
                        If you are looking for an exact phrase, you can wrap it in quotation marks.  This will return only results that are an exact match for that phrase. <br />
                        e.g. “They comfort me”
                    </p>
                    <h4>Combining operators</h4>
                    <p>
                        Operators can be combined to create complex search queries. <br />
                        e.g. “Parekowhai +keller -craig” will return results that contain “Parekowhai” and “keller” but not “craig”.
                    </p>
                </div>
                <div className="controls">
                    <button type="button" className="button submit primary" onClick={() => setIsModalOpen(false)}>Ok</button>
                </div>
            </Modal>
        </div>
    );
};

export default AdvancedCollectionSearchPage;
