import defaultImage from 'static/images/svgs/art-toi.svg';
import PropTypes from 'prop-types';
import React from 'react';

const ContentSetProduct = ({ item, index, showTile }) => {
    const name = item.data.title;
    const url = item.data.url;
    const price = item.data.price;
    const fromPrice = item.data.from;
    const soldOut = item.data.soldout;
    const image = item.data.photo;
    const tag = item.data.tag;
    const comparedPrice = item.data.compare_at_price;

    return (
        <article className={showTile ? 'tile tile-product' : 'tile-hide'} key={index}>
            <div className={soldOut ? 'tile-fade' : ''}>
                <a className="tile-thumbnail-link" href={url}>
                    <div className="tile-thumbnail">
                        <picture className="hover-area">
                            {tag ? tag === 'sale' ? <span className="tile-badge bg-red">{tag}</span> : <span className="tile-badge bg-black">{tag}</span> : '' }
                            <div data-hover-text>view more</div>
                            <div className="image-container || square">
                                <img className="tile-thumbnail-image" src={image ? image : defaultImage} alt={name} />
                            </div>
                        </picture>
                    </div>
                </a>
            </div>
            {/* detail section */}
            <div className={soldOut ? 'tile-details || tile-mobile-fade' : 'tile-details' } >
                <h5 className="tile-title">
                    <a href={url}>
                        {name}
                    </a>
                </h5>
                <div>
                    {fromPrice && <span className="tile-from">From</span>}
                    {comparedPrice && <strike className="tile-from">${comparedPrice}</strike>}
                    {price
                        ? soldOut
                            ? <div className="tile-soldout">
                                <strike className="tile-from">${price}</strike><span className="tile-soldout-text">SOLD OUT</span>
                            </div>
                            : <span>${price}</span>
                        : ''
                    }
                </div>
            </div>
        </article>
    );
};

ContentSetProduct.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showTile: PropTypes.bool
};
export default ContentSetProduct;
