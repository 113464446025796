import * as Yup from 'yup';
import { ERROR_MESSAGE } from 'config/api';
import Input from 'app/components/partials/input';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const NewsLetterSignUp = () => {
    const recaptcha = useRef();
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const onRecaptchaChange = (value) => {
        setRecaptchaToken(value);
    }

    const renderSubmitSuccessfully = () => (
        <div>
            <h2>THANKS FOR SIGNING UP</h2>
            <p>We’ll be sharing the latest news from the Gallery and our online shop. You can change your email preferences at any time by following the link at the bottom of our newsletters.</p>
        </div>
    );

    const formik = useFormik({
        initialValues: {
            email: '',
            emailconfirm: '',
            firstname: '',
            lastname: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Not a valid email address'),
            emailconfirm: Yup.string().oneOf([Yup.ref('email'), null], 'Emails don\'t match'),
            firstname: Yup.string().required('First Name is required'),
            lastname: Yup.string().required('Last Name is required'),
        }),
        onSubmit: async(values, { resetForm, setStatus }) => {
            const {
                email,
                firstname,
                lastname,
            } = values;

            // ac verified recaptcha token.
            const formData = {
                u: '3',
                f: '3',
                s: '',
                c: '0',
                m: '0',
                act: 'sub',
                v: '2',
                email,
                firstname,
                lastname,
                'g-recaptcha-response': recaptchaToken
            };

            setStatus();

            try {
                await fetch('https://rfal13037.activehosted.com/proc.php', {
                    method: 'POST',
                    body: new URLSearchParams(formData),
                    mode: 'no-cors',
                });

                resetForm();
                setStatus({ success: true });
            } catch (err) {
                setStatus({ error: ERROR_MESSAGE.INTERNAL_SERVER_ERROR });
            }
        }
    });

    const renderNewsletterSignupForm = () => {
        const baseInputProps = {
            className: 'input',
            onChangeHandler: formik.handleChange,
            onBlurHandler: formik.handleBlur,
            isRequired: true,
        };

        const emailInputProps = {
            ...baseInputProps,
            type: 'email',
            name: 'email',
            id: 'email',
            placeholder: 'Enter your email',
            value: formik.values.email,
            error: formik.touched.email && formik.errors.email,
        };
        const emailConfirmInputProps = {
            ...baseInputProps,
            type: 'email',
            name: 'emailconfirm',
            id: 'emailconfirm',
            placeholder: 'Confirm your email',
            value: formik.values.emailconfirm,
            error: formik.touched.emailconfirm && formik.errors.emailconfirm,
        };
        const firstNameInputProps = {
            ...baseInputProps,
            type: 'text',
            name: 'firstname',
            id: 'firstname',
            placeholder: 'First Name',
            value: formik.values.firstname,
            error: formik.touched.firstname && formik.errors.firstname,
        };
        const lastNameInputProps = {
            ...baseInputProps,
            type: 'text',
            name: 'lastname',
            id: 'lastname',
            placeholder: 'Last Name',
            value: formik.values.lastname,
            error: formik.touched.lastname && formik.errors.lastname,
        };

        return (
            <div className="newsletter-signup">
                <h2>Be in the know</h2>
                <p>Sign up to our newsletter to stay up to date</p>
                <form className="form newsletter-signup-form" onSubmit={formik.handleSubmit}>
                    <Input {...emailInputProps} />
                    <Input {...emailConfirmInputProps} />
                    <Input {...firstNameInputProps} />
                    <Input {...lastNameInputProps} />

                    <ReCAPTCHA className="g-recaptcha-container" ref={recaptcha} sitekey="6LcwIw8TAAAAACP1ysM08EhCgzd6q5JAOUR1a0Go" onChange={onRecaptchaChange} />

                    <div className='privacy-container'>
                        <Link className="link privacy-policy-link" to="/page/copyright-and-privacy">
                            Read about our privacy policy
                        </Link>
                    </div>
                    {formik.status && formik.status.error && <div className="error-message">{formik.status.error}</div>}

                    <div className="button-group left-align">
                        <button type="submit" className="button primary" disabled={!recaptchaToken || formik.isSubmitting}>Submit</button>
                    </div>
                </form>
            </div>
        );
    };

    return (
        <main class="page" role="main">
            <div className="news-letter-sign-up">
                <div className="constrain-width">
                    {formik.status && formik.status.success ? renderSubmitSuccessfully() : renderNewsletterSignupForm()}
                </div>
            </div>
        </main>
    );
};

export default NewsLetterSignUp;
