import ArtistList from './major-projects/artist-list';
import Button from './button';
import Carousel from './carousel';
import ContentSet from './content-set';
import Data from 'app/components/partials/data';
import Donation from './donation';
import Faq from './faq';
import FileDownload from './file-download';
import Gallery from './gallery';
import HeroArtwork from './major-projects/hero-artwork';
import Image from './image';
import Jotform from './jotform';
import LargeThumbnail from './major-projects/large-thumbnail';
import Media from './media';
import MenuItem from './major-projects/menu-item';
import MenuItemNav from '../partials/major-projects/menu-item-nav';
import MinimalisticVideo from './major-projects/minimalistic-video';
import PagebreakAlgorithm from './pagebreak-algorithm';
import PagebreakQuote from './pagebreak-quote';
import PropTypes from 'prop-types';
import RollingText from './rolling-text';
import Sponsor from './sponsor';
import StacklaWidget from './stackla-widget';
import TextContent from './text-content';
import { THEME_DEFAULT } from 'app/utilities/content-blocks';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

const ContentBlocks = ({ data, extraClass = '', blockTheme = THEME_DEFAULT, blockStyle = {} }) => {
    const getMenuItems = (blocks) => {
        return blocks
            .filter(({ type }) => type === 'menuitem')
            .map(({ data }) => data);
    };

    const navigate = useNavigate();
    const menuItems = getMenuItems(data);
    const menuItemRefs = useRef([]);

    const renderMenuItemNav = () => {
        if (menuItems.length) {
            return <MenuItemNav menuItems={menuItems} />;
        }

        return null;
    };

    const setMenuItemRef = (idx, node) => {
        menuItemRefs.current[idx] = node;
    };

    const handleMenuItemScroll = () => {
        const locators = menuItemRefs.current.filter((ref) => ref);
        locators.forEach((locator, index) => {
            const rect = locator.getBoundingClientRect();
            const fragment = window.location.toString().split('#')[1];

            if (index === 0 && rect.top >= 120) {
                if (fragment) {
                    navigate('', { replace: true });
                }

                return;
            }

            if (index === locators.length - 1 && rect.top < 120) {
                if (fragment !== locator.id) {
                    navigate(`#${locator.id}`, { replace: true });
                }

                return;
            }

            if (index !== locators.length - 1) {
                const rectNext = locators[index + 1].getBoundingClientRect();
                if (rect.top <= 120 && rectNext.top > 120) {
                    if (fragment !== locator.id) {
                        navigate(`#${locator.id}`, { replace: true });
                    }
                }
            }
        });
    };

    const renderImageGallery = (data, props) => {
        if (data.length > 1) {
            return <Gallery {...props} items={data} />;
        }

        return <Image {...props} {...data[0]} />;
    };

    const renderBlock = (block, index) => {
        // Base props for all blocks
        const props = {
            key: index,
            blockTheme,
            blockStyle
        };

        switch (block.type) {
            case 'carousel':
                return <Carousel {...props} items={block.data} />;
            case 'gallery':
                return renderImageGallery(block.data, props);
            case 'faq':
                return <Faq {...props} items={block.data} />;
            case 'file':
                return <FileDownload {...props} { ...block.data } />;
            case 'content':
            case 'contentaside':
            case 'contentquote':
                return <TextContent {...props} {...block.data} />;
            case 'contentset':
                return <ContentSet {...props} {...block.data} />;
            case 'button':
                return <Button {...props} {...block.data} />;
            case 'pagebreakalgorithm':
                return <PagebreakAlgorithm {...props} {...block.data} />;
            case 'pagebreakquote':
                return <PagebreakQuote {...props} {...block.data} />;
            case 'stackla':
                return <StacklaWidget {...props} {...block.data} />;
            case 'rollingtext':
                return <RollingText {...props} {...block} />;
            case 'donation':
                return <Donation {...props} {...block.data} />;
            case 'sponsor':
                return <Sponsor {...props} items={block.data} />;
            case 'media':
                return <Media {...props} {...block} />;
            // Major project specific blocks
            case 'menuitem':
                return <MenuItem {...props} {...block.data} ref={(node) => setMenuItemRef(index, node)} />;
            case 'artistlist':
                return <ArtistList {...props} {...block.data} />;
            case 'largethumbnail':
                return <LargeThumbnail {...props} {...block.data} />;
            case 'heroartwork':
                return <HeroArtwork {...props} {...block.data} />;
            case 'minimalisticvideo':
                return <MinimalisticVideo {...props} {...block.data} />;
            case 'jotform':
                return <Jotform key={index} {...block} />;
            default:
                return (
                    <Data {...props} title={`Missing content block type: ${block.type}`} />
                );
        }
    };

    if (!data.length) {
        return null;
    }

    useEffect(() => {
        // Handle dynamic URL hash for major project menu item scrolling
        if (menuItems.length > 0) {
            window.addEventListener('scroll', () => {
                handleMenuItemScroll();
            });
        }
    }, []);

    return (
        <div className={`content-blocks ${extraClass}`}>
            {data.map((data, index) => {
                return renderBlock(data, index);
            })}
            {renderMenuItemNav(data)}
        </div>
    );
};


ContentBlocks.propTypes = {
    data: PropTypes.array.isRequired,
    extraClass: PropTypes.string,
    blockTheme: PropTypes.string,
    blockStyle: PropTypes.object,
    isLarge: PropTypes.bool,
    isNews: PropTypes.bool,
    pageTitle: PropTypes.string
};

ContentBlocks.defaultProps = {
    isLarge: false,
    isNews: false,
    pageTitle: ''
};

export default ContentBlocks;
