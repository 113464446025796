// Custom pages
export const FRONTEND_ROUTES = {
    ADVANCED_SEARCH: '/explore-art-and-ideas/advanced-collection-search',
    GLOBAL_SEARCH: '/s',
    EVENT_SEARCH: '/search/events',
    EXHIBITION_SEARCH: '/search/exhibitions',
    ARTWORK_SEARCH: '/search/artworks',
    ARCHIVE_SEARCH: '/search/archives',
    IDEA_SEARCH: '/search/ideas',
    MORE_SEARCH: '/search/more',
};

export const isFrontendRoute = (pathname) => {
    const frontendRoutesArray = Object.values(FRONTEND_ROUTES);

    return frontendRoutesArray.includes(pathname);
};

export const getArtistRoute = (id, slug) => {
    return `/explore-art-and-ideas/artist/${id}/${slug}`;
};

export const getArchivesRoute = (id) => {
    return `/explore-art-and-ideas/archives/${id}`;
};
