import classnames from 'classnames';
import ContentSetEvent from 'app/components/partials/content-sets/content-set-event';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import FullScreenDialog from 'app/components/partials/full-screen-dialog';
import GlobalSearchGrid from 'app/components/partials/global-search/global-search-grid';
import GlobalSearchTag from 'app/components/partials/global-search/global-search-tag';
import Helmet from 'react-helmet';
import Icon from 'app/components/partials/icon';
import Loader from 'app/components/partials/loader';
import Logo from 'app/components/partials/logo';
import SearchBar from 'app/components/partials/category-search/search-bar';
import { useFormik } from 'formik';
import { Link, useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

const GlobalSearchPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const fullScreenRef = useRef(null);
    const reference = searchParams.get('reference') || 'artworks';
    const [isLoading, setIsLoading] = useState(false);
    const [resultsCount, setResultsCount] = useState({
        'exhibitions': null,
        'events': null,
        'artworks': null,
        'archives': null,
        'ideas': null,
        'articles': null,
        'more': null
    });
    const [featureExhibitions, setFeatureExhibitions] = useState(null);
    const formik = useFormik({
        initialValues: {
            reference: reference,
            keywords: searchParams.get('keywords') || '',
            'from-date': searchParams.get('from-date') || '',
            'to-date': searchParams.get('to-date') || '',
            'date-range': searchParams.get('date-range') || '',
            'event-type': searchParams.get('event-type') || '',
            only: searchParams.getAll('only[]') || [],
            objectType: searchParams.getAll('objectType[]') || [],
            collections: searchParams.getAll('collections[]') || [],
            contentType: searchParams.getAll('contentType[]') || [],
            undated: !searchParams.has('from-date') && !searchParams.has('to-date'),
            nationalities: searchParams.getAll('nationalities[]') || []
        },
        onSubmit: async(values) => {
            searchHandler(values);
        }
    });

    const [backToTopVisible, setbackToTopVisible] = useState(false);
    const backtoTopBtnClass = classnames('back-to-top || button || default', backToTopVisible && ' || show-button');
    // listens to back to top button
    useEffect(() => {
        if (fullScreenRef.current) {
            fullScreenRef.current.addEventListener('scroll', handleOnScroll);
        }

        return () => {
            if (fullScreenRef.current) {
                fullScreenRef.current.removeEventListener('scroll', handleOnScroll);
            }
        };
    }, [fullScreenRef]);

    // for back to top button
    const handleOnScroll = () => {
        if (fullScreenRef.current) {
            const isHalfScrolled = fullScreenRef.current.scrollTop > (window.innerHeight / 2);
            setbackToTopVisible(isHalfScrolled);
        }
    };
    const scrollToTop = () => {
        if (fullScreenRef.current) {
            fullScreenRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };
    const renderBackToTopButton = () => {
        return (
            <button className={backtoTopBtnClass} onClick={scrollToTop}>
                <Icon name="arrow-top" width="40" height="40"></Icon>
            </button>
        );
    };

    const searchHandler = (inputs) => {
        const query = new URLSearchParams();
        Object.entries(inputs).forEach(([key, values]) => {
            // Do nothing when value is not valid
            if (!values || values.length === 0) {
                return;
            }

            // Set search parameters
            if (typeof values === 'string' || typeof values === 'number') query.append(key, values);
            if (Array.isArray(values)) values.forEach((value) => query.append(`${key}[]`, value));
            if (key === 'undated' && values === true) query.append(key, key);
        });

        setSearchParams(query);
    };

    const formatEventData = (entities) => {
        return entities.map((entity) => {
            return {
                data: entity
            };
        });
    };

    const fetchResultCount = async() => {
        setIsLoading(true);

        try {
            const results = await fetch(`${ENDPOINTS.GLOBAL_SEARCH_COUNT}?${searchParams.toString()}`, { method: 'GET' }, true);
            setResultsCount(results.count);
            if (results.featureExhibitions) {
                setFeatureExhibitions(formatEventData(results.featureExhibitions.data));
            } else {
                setFeatureExhibitions(null);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!searchParams.has('keywords')) {
            return;
        }
        fetchResultCount();
    }, [searchParams]);

    const renderIneligible = () => {
        return (
            <div className="search-modal-note">
                <p>Use the field above to search our entire website. If you’re looking for a specific artwork, our advanced search below gives you the ability to search by artist, title, keyword, date range and more.</p>
                <Link className="button || default || small" to={FRONTEND_ROUTES.ADVANCED_SEARCH}>ADVANCED SEARCH</Link>
            </div>
        );
    };

    const renderFeatureExhibitions = () => {
        return (
            <>
                <div className="search-modal-note">
                    We couldn’t find any results for your search but here are some popular pages that might help:
                </div>
                <div className="feature-exhibitions || constrain-width">
                    <div className="tile-block">
                        <div className="tile-block-inner">
                            {featureExhibitions && featureExhibitions.map((item, index) => {
                                // Only display 6 item here
                                // eslint-disable-next-line no-magic-numbers
                                if (index >= 6) {
                                    return;
                                }

                                return <ContentSetEvent
                                    key={index}
                                    index={index}
                                    item={item}
                                    showTile={true} />;
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const showSearchResults = () => {
        return !featureExhibitions && !isLoading && searchParams.has('keywords');
    };

    const renderSearchGrid = () => {
        return <GlobalSearchGrid reference={reference} show={showSearchResults()} />;
    };

    const renderSearchTags = () => {
        return <GlobalSearchTag reference={reference} formik={formik} resultsCount={resultsCount} show={showSearchResults()} />;
    };

    return (
        <main className="page" role="main">
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />

                <title>Auckland Art Gallery</title>
                <meta name="description" content="Search" />
                <meta name="keywords" content="Global Search" />
            </Helmet>
            <FullScreenDialog extraClass="search-dialog" ref={fullScreenRef}>
                <header className="header">
                    <div className="header-inner || constrain-width">
                        <Link className="header-logo" to="/">
                            <Logo name="aag-logo" title="Auckland Art Gallery" />
                        </Link>
                        <Link className="button" to="/">
                            <Icon className="icon" name="close" width="30" height="30" />
                        </Link>
                    </div>
                </header>
                <div className="global-search-result-page">
                    <div className="global-search-inner || constrain-width">
                        <form className="form" onSubmit={formik.handleSubmit}>
                            <SearchBar formik={formik} />
                            {renderSearchTags()}
                        </form>
                        {renderSearchGrid()}
                        {!searchParams.has('keywords') && renderIneligible()}
                        {isLoading && <Loader type="loader-center"/>}
                        {featureExhibitions && renderFeatureExhibitions()}
                    </div>
                </div>
                {renderBackToTopButton()}
            </FullScreenDialog>
        </main>
    );
};

GlobalSearchPage.propTypes = {
};

export default GlobalSearchPage;
