export const ONLY_OPTION = [
    { label: 'Items currently on display', value: 'On Display' },
    { label: 'Items with images', value: 'With Images' },
    { label: 'Items without copyright restrictions', value: 'No Copyright' },
];

export const COLLECTION_OPTION = [
    { label: 'Auckland Art Gallery Collection', value: 'Auckland Art Gallery Collection' },
    { label: 'Chartwell', value: 'Chartwell' },
    { label: 'Mackelvie Trust', value: 'Mackelvie Trust' },
    { label: 'Grey Collection', value: 'Grey Collection' },
];

export const NATIONALITY_OPTION = [
    { label: 'New Zealander', value: 'New Zealander' },
    { label: 'British', value: 'British' },
    { label: 'French', value: 'French' },
    { label: 'Australian', value: 'Australian' },
    { label: 'English', value: 'English' },
    { label: 'Italian', value: 'Italian' },
    { label: 'American', value: 'American' },
    { label: 'Dutch', value: 'Dutch' },
    { label: 'German', value: 'German' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Flemish', value: 'Flemish' },
    { label: 'Unknown', value: 'Unknown' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Irish', value: 'Irish' },
    { label: 'Swiss', value: 'Swiss' },
    { label: 'Austrian', value: 'Austrian' },
    { label: 'Samoan', value: 'Samoan' },
    { label: 'Belgian', value: 'Belgian' },
    { label: 'Brazilian', value: 'Brazilian' },
    { label: 'Indian', value: 'Indian' },
    { label: 'Canadian', value: 'Canadian' },
    { label: 'Polish', value: 'Polish' },
    { label: 'Swedish', value: 'Swedish' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'Russian', value: 'Russian' },
    { label: 'Greek', value: 'Greek' },
    { label: 'Cook Islander', value: 'Cook Islander' },
    { label: 'Danish', value: 'Danish' },
    { label: 'Europe', value: 'Europe' },
    { label: 'Hungarian', value: 'Hungarian' },
    { label: 'Korean', value: 'Korean' },
    { label: 'Bohemian', value: 'Bohemian' },
    { label: 'Fijian', value: 'Fijian' },
    { label: 'Mexican', value: 'Mexican' },
    { label: 'Middle East', value: 'Middle East' },
    { label: 'Tongan', value: 'Tongan' },
    { label: 'Uzbek', value: 'Uzbek' },
    { label: 'Argentinian', value: 'Argentinian' },
    { label: 'Chilean', value: 'Chilean' },
    { label: 'Croatian', value: 'Croatian' },
    { label: 'Czech', value: 'Czech' },
    { label: 'Czechoslovakia', value: 'Czechoslovakia' },
    { label: 'Dominican', value: 'Dominican' },
    { label: 'Estonian', value: 'Estonian' },
    { label: 'Filipino', value: 'Filipino' },
    { label: 'Indonesian', value: 'Indonesian' },
    { label: 'Lithuanian', value: 'Lithuanian' },
    { label: 'Malaysian', value: 'Malaysian' },
    { label: 'Nigerian', value: 'Nigerian' },
    { label: 'Niuean', value: 'Niuean' },
    { label: 'Palestinian', value: 'Palestinian' },
    { label: 'Papua New Guinean', value: 'Papua New Guinean' },
    { label: 'Peruvian', value: 'Peruvian' },
    { label: 'Romanian', value: 'Romanian' },
    { label: 'Rotuman', value: 'Rotuman' },
    { label: 'Samoa', value: 'Samoa' },
    { label: 'Slovenian', value: 'Slovenian' },
    { label: 'Soloman Islander', value: 'Soloman Islander' },
    { label: 'South African', value: 'South African' },
    { label: 'Turkish', value: 'Turkish' },
    { label: 'Tuvaluan', value: 'Tuvaluan' },
    { label: 'Uruguayan', value: 'Uruguayan' },
    { label: 'Welsh', value: 'Welsh' },
    { label: 'Yugoslavian', value: 'Yugoslavian' },
];

export const CONTENT_TYPE_OPTION =  [
    { label: 'Audio', value: 'audio' },
    { label: 'Education resource', value: 'education' },
    { label: 'Publication', value: 'publication' },
    { label: 'Video', value: 'video' },
];

export const EVENT_DATE_FILTER = {
    options: [
        { label: 'Future', value: 'future' },
        { label: 'Today', value: 'today' },
        { label: 'Tomorrow', value: 'tomorrow' },
        { label: 'This week', value: 'this-week' },
        { label: 'Next week', value: 'next-week' },
        { label: 'This weekend', value: 'this-weekend' },
        { label: 'Next weekend', value: 'next-weekend' },
        { label: 'This month', value: 'this-month' },
        { label: 'Next month', value: 'next-month' },
        { label: 'Past events', value: 'past' },
        { label: 'Any date', value: 'all' },
    ],
    defaultIndex: 10
};

export const EVENT_TYPE_FILTER = {
    options: [
        { label: 'All events', value: 'all' },
        { label: 'Talks and tours', value: 'talks-and-tours' },
        { label: 'Families', value: 'families' },
        { label: 'Films', value: 'films' },
        { label: 'Members', value: 'members' },
        { label: 'Workshops', value: 'workshops' },
        { label: 'Performances', value: 'performances' },
    ],
    defaultIndex: 0
};

export const EXHIBITION_DATE_FILTER = {
    options: [
        { label: 'Future', value: 'future' },
        { label: 'Today', value: 'today' },
        { label: 'Tomorrow', value: 'tomorrow' },
        { label: 'This week', value: 'this-week' },
        { label: 'Next week', value: 'next-week' },
        { label: 'This weekend', value: 'this-weekend' },
        { label: 'Next weekend', value: 'next-weekend' },
        { label: 'This month', value: 'this-month' },
        { label: 'Next month', value: 'next-month' },
        { label: 'Past exhibitions', value: 'past' },
        { label: 'Any date', value: 'all' },
    ],
    defaultIndex: 1
};

export const ARTWORK_SORT_FILTER = {
    options: [
        { label: 'Relevance', value: 'relevance' },
        { label: 'Title (A to Z)', value: 'title-asc' },
        { label: 'Title (Z to A)', value: 'title-desc' },
        { label: 'Production date (newest first)', value: 'production_date-desc' },
        { label: 'Production date (oldest first)', value: 'production_date-asc' },
        { label: 'Accession date (newest first)', value: 'accession_date-desc' },
        { label: 'Accession date (oldest first)', value: 'accession_date-asc' },
        { label: 'Artist surname (A to Z)', value: 'artist_surname-asc' },
        { label: 'Artist surname (Z to A)', value: 'artist_surname-desc' },
    ],
    defaultIndex: 0,
};

export const ARCHIVE_SORT_FILTER = {
    options: [
        { label: 'Title (A to Z)', value: 'title-asc' },
        { label: 'Title (Z to A)', value: 'title-desc' },
        { label: 'Accession date (newest first)', value: 'accession_date-desc' },
        { label: 'Accession date (oldest first)', value: 'accession_date-asc' },
    ],
    defaultIndex: 0,
};

export const IDEA_SORT_FILTER = {
    options: [
        { label: 'Title (A to Z)', value: 'title-asc' },
        { label: 'Title (Z to A)', value: 'title-desc' },
        { label: 'Post date (newest first)', value: 'date-desc' },
        { label: 'Post date (oldest first)', value: 'date-asc' },
    ],
    defaultIndex: 2,
};
