import proceedIcon from 'static/images/svgs/proceed.svg';
import PropTypes from 'prop-types';
import React from 'react';

const ButtonProceed = ({ target, style = {} }) => {
    const btnStyle = {
        backgroundImage: `url(${proceedIcon})`,
        ...style
    };

    const clickHandler = () => {
        if (target.current) {
            target.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    };

    return <button
        className="btn-proceed"
        style={btnStyle}
        onClick={clickHandler}
    ></button>;
};

ButtonProceed.propTypes = {
    target: PropTypes.object,
    style: PropTypes.object
};

export default ButtonProceed;
