import classnames from 'classnames';
import Icon from '../icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const tabs = [
    {
        label: 'Early childhood',
        slug: 'early-childhood',
    },
    {
        label: 'Primary',
        slug: 'primary',
    },
    {
        label: 'Secondary',
        slug: 'secondary',
    },
    {
        label: 'Tertiary',
        slug: 'tertiary',
    },
];

const EducationTabs = ({ activeTab }) => {
    // track tablist open state for mobile view
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <nav className="tabs">
            {/* mobile only */}
            <p className="tabs-intro">
                Select an education level:
            </p>
            {/* current active tab (mobile only) */}
            <button
                className={classnames(
                    'tab-current',
                    { 'is-active': isOpen }
                )}
                onClick={() => setIsOpen(!isOpen)}
            >
                {tabs.find((tab) => tab.slug === activeTab).label}
                <Icon name="arrow" width="15" height="15" />
            </button>
            {/* tab links container */}
            <div
                className={classnames(
                    'tab-results',
                    { 'is-active': isOpen }
                )}
                role="tablist"
            >
                {tabs.map(({ label, slug }) => (
                    <div
                        key={slug}
                        role="tab"
                    >
                        <Link
                            className={
                                classnames('tab-link', {
                                    'is-active': slug === activeTab
                                })
                            }
                            to={`/visit/education-groups/${slug}`}
                        >{label}</Link>
                    </div>
                ))}
            </div>
        </nav>
    );
};

EducationTabs.propTypes = {
    activeTab: PropTypes.string.isRequired
};

export default EducationTabs;
