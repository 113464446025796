import { capitalizeFirst } from 'app/utilities/string';
import CategorySearch from 'app/components/partials/category-search/index';
import ContentSetArchive from 'app/components/partials/content-sets/content-set-archive';
import ContentSetArtwork from 'app/components/partials/content-sets/content-set-artwork';
import ContentSetEvent from 'app/components/partials/content-sets/content-set-event';
import ContentSetNews from './content-sets/content-set-news';
import ContentSetPage from 'app/components/partials/content-sets/content-set-page';
import Loader from 'app/components/partials/loader';
import PageHeader from 'app/components/partials/page-header';
import PropTypes from 'prop-types';
import useReferenceSearch from 'app/hooks/use-reference-search';
import { useSearchParams } from 'react-router-dom';
import React, { useCallback, useRef, useState } from 'react';

const SearchGrid = ({ reference, hideSearchBar = false }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(1);
    const { data, hasMore, isLoading } = useReferenceSearch(
        reference,
        searchParams.toString(),
        page
    );

    // Handle scroll search by observer
    const observer = useRef();
    const lastEntityRef = useCallback((node) => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((preValue) => preValue + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    const renderSearchItem = (item, index, reference) => {
        switch (reference) {
            case 'events':
            case 'exhibitions':
                return <ContentSetEvent
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            case 'artworks':
                return <ContentSetArtwork
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            case 'archives':
                return <ContentSetArchive
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            case 'ideas':
                return <ContentSetPage
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            case 'articles':
                return <ContentSetNews
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            default:
                return 'default';
        }
    };

    // Search handler for category search component
    const searchHandler = (inputs) => {
        setPage(1);
        Object.entries(inputs).forEach(([key, values]) => {
            // Delete the previous value
            if (searchParams.has(key)) searchParams.delete(key);
            if (searchParams.has(`${key}[]`)) searchParams.delete(`${key}[]`);
            // Do nothing when value is not valid
            if (!values || values.length === 0) {
                return;
            }

            // Set search parameters
            if (typeof values === 'string' || typeof values === 'number') searchParams.append(key, values);
            if (Array.isArray(values)) values.forEach((value) => searchParams.append(`${key}[]`, value));
            if (key === 'undated' && values === true) searchParams.append(key, key);
        });

        setSearchParams(searchParams);
    };

    return (
        <div className="search-result-grid">
            {!hideSearchBar &&
                <React.Fragment>
                    <PageHeader title={capitalizeFirst(reference)} />
                    <CategorySearch reference={reference} searchHandler={searchHandler}/>
                </React.Fragment>
            }
            <div className="constrain-width">
                <div className="tile-block">
                    <div className="tile-block-inner">
                        {data && data.map((item, index) => {
                            return renderSearchItem(item, index, reference);
                        })}
                    </div>
                    {isLoading && <Loader type="loader-center"/>}
                    {!isLoading && data.length === 0 && <h6 className="no-results">Sorry, there are no results for your search.</h6>}
                </div>
            </div>
        </div>
    );
};

SearchGrid.propTypes = {
    reference: PropTypes.string.isRequired,
    hideSearchBar: PropTypes.bool
};

export default SearchGrid;
