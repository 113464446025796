import PropTypes from 'prop-types';
import React from 'react';

const Button = ({ title, url }) =>
    <section className="button-block">
        <div className="button-block-inner">
            <a className="button || primary || small" href={url}>
                <div className="button-inner">{title}</div>
            </a>
        </div>
    </section>;

Button.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default Button;
