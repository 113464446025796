import MAP_MARKER from 'static/images/map-marker.png';

const styles = [
    {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#E9E9E9'
            },
            {
                'lightness': 17
            }
        ]
    },
    {
        'featureType': 'landscape',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#F5F5F5'
            },
            {
                'lightness': 20
            }
        ]
    },
    {
        'featureType': 'road.highway',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#FFFFFF'
            },
            {
                'lightness': 17
            }
        ]
    },
    {
        'featureType': 'road.highway',
        'elementType': 'geometry.stroke',
        'stylers': [
            {
                'color': '#000000'
            },
            {
                'lightness': 29
            },
            {
                'weight': 0.5
            }
        ]
    },
    {
        'featureType': 'road.arterial',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#FFFFFF'
            },
            {
                'lightness': 18
            }
        ]
    },
    {
        'featureType': 'road.arterial',
        'elementType': 'geometry.stroke',
        'stylers': [
            {
                'color': '#000000'
            },
            {
                'lightness': 29
            },
            {
                'weight': 0.5
            }
        ]
    },
    {
        'featureType': 'road.local',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#FFFFFF'
            },
            {
                'lightness': 16
            }
        ]
    },
    {
        'featureType': 'road.local',
        'elementType': 'geometry.stroke',
        'stylers': [
            {
                'color': '#000000'
            },
            {
                'lightness': 29
            },
            {
                'weight': 0.5
            }
        ]
    },
    {
        'featureType': 'poi',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#F5F5F5'
            },
            {
                'lightness': 21
            }
        ]
    },
    {
        'featureType': 'poi.park',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#DEDEDE'
            },
            {
                'lightness': 21
            }
        ]
    },
    {
        'elementType': 'labels.text.stroke',
        'stylers': [
            {
                'visibility': 'off'
            },
            {
                'color': '#FFFFFF'
            },
            {
                'lightness': 16
            }
        ]
    },
    {
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'saturation': 36
            },
            {
                'color': '#333333'
            },
            {
                'lightness': 40
            }
        ]
    },
    {
        'elementType': 'labels.icon',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'transit',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#F2F2F2'
            },
            {
                'lightness': 19
            }
        ]
    },
    {
        'featureType': 'administrative',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#FEFEFE'
            },
            {
                'lightness': 20
            }
        ]
    },
    {
        'featureType': 'administrative',
        'elementType': 'geometry.stroke',
        'stylers': [
            {
                'color': '#FEFEFE'
            },
            {
                'lightness': 17
            },
            {
                'weight': 1.2
            }
        ]
    }
];

export const initMap = (mapEle) => {
    const galleryPosition = {
        lat: parseFloat(-36.851354),
        lng: parseFloat(174.766237)
    };

    // Offset the map to the left
    const galleryPositionOffset = Object.create(galleryPosition); // eslint-disable-line
    galleryPositionOffset.lat -= 0.000045; // eslint-disable-line
    galleryPositionOffset.lng += 0.003763; // eslint-disable-line

    // map position
    const mapOptions = { // eslint-disable-line
        center: galleryPositionOffset,
        zoom: 17,
        styles,
        disableDefaultUI: true,
        draggable: false,
        scrollwheel: false
    };

    const map = new window.google.maps.Map(mapEle, mapOptions);

    // map icon
    const image = { // eslint-disable-line
        url: MAP_MARKER,
        size: new window.google.maps.Size(88, 116), // eslint-disable-line
        scaledSize: new window.google.maps.Size(40, 52), // eslint-disable-line
        origin: new window.google.maps.Point(0, 0), // eslint-disable-line
        anchor: new window.google.maps.Point(20, 52) // eslint-disable-line
    };

    // map marker
    const marker = new window.google.maps.Marker({ // eslint-disable-line
        position: galleryPosition,
        map: map,
        title: 'Auckland Art Gallery',
        icon: image,
        optimized: false
    });
};
