/* eslint-disable camelcase */
import banner from 'static/images/articles-banner.jpg';
import Layout from './layout';
import React from 'react';
import SearchGrid from '../partials/search-grid';

const ArticlesPage = () => {
    const renderBanner = () => {
        return (
            <figure className="banner black">
                <img src={banner} alt="Articles Banner" />
                <figcaption className="caption">
                    <h1 className="heading">Articles</h1>
                    <h2 className="subheading">VIEWS AND INSIGHTS</h2>
                </figcaption>
            </figure>
        );
    };

    const renderSearchGrid = () => {
        return <SearchGrid reference="articles" hideSearchBar />;
    };

    return (
        <Layout pageAttrs={{
            seo_title: 'Articles'
        }}>
            <div className="content-page articles-page constrain-width">
                {renderBanner()}
                {renderSearchGrid()}
            </div>
        </Layout>
    );
};

export default ArticlesPage;
