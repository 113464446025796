/* eslint-disable jsx-a11y/no-onchange */
import ArchiveFilter from 'app/components/partials/global-search/archive-filter';
import ArtworkFilter from 'app/components/partials/global-search/artwork-filter';
import { capitalizeFirst } from 'app/utilities/string';
import classnames from 'classnames';
import EventFilter from 'app/components/partials/global-search/event-filter';
import ExhibitionFilter from 'app/components/partials/global-search/exhibition-filter';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import Icon from 'app/components/partials/icon';
import IdeaFilter from 'app/components/partials/global-search/idea-filter';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const REFERENCES = ['exhibitions', 'events', 'artworks', 'archives', 'ideas', 'articles', 'more'];

const FILTER_OPTIONS = {
    'exhibitions': ['reference', 'keywords', 'date-range', 'from-date', 'to-date'],
    'events': ['reference', 'keywords', 'date-range', 'from-date', 'to-date', 'event-type'],
    'artworks': ['reference', 'keywords', 'sort', 'only', 'objectType', 'collections', 'from-date', 'to-date', 'undated', 'nationalities'],
    'archives': ['reference', 'keywords', 'sort', 'from-date', 'to-date', 'undated'],
    'ideas': ['reference', 'keywords', 'sort', 'contentType'],
    'articles': ['reference', 'keywords'],
    'more': ['reference', 'keywords']
};

const GlobalSearchTag = ({ reference, formik, resultsCount, show = true }) => {
    const switchCategory = (category) => {
        // Switch reference params
        formik.setFieldValue('reference', category);
        // Clear filter params according to reference
        Object.entries(formik.values).forEach(([key, value]) => {
            if (FILTER_OPTIONS[category].includes(key)) return;
            if (typeof value === 'string' || typeof value === 'number') formik.setFieldValue(key, '');
            if (Array.isArray(value)) formik.setFieldValue(key, []);
        });

        formik.submitForm();
    };

    const renderFilterGroups = () => {
        switch (reference) {
            case 'exhibitions':
                return <ExhibitionFilter formik={formik} />;
            case 'events':
                return <EventFilter formik={formik} />;
            case 'artworks':
                return <ArtworkFilter formik={formik} />;
            case 'archives':
                return <ArchiveFilter formik={formik} />;
            case 'ideas':
                return <IdeaFilter formik={formik} />;
            default:
                return '';
        }
    };

    const getStyle = () => {
        if (!show) {
            return {
                display: 'none'
            };
        }

        return null;
    };

    return (
        <div className="search-categories-wrapper" style={getStyle()}>
            <div className="search-tags">
                <nav className="search-categories">
                    <div className="search-categories-row">
                        {REFERENCES.map((item, index) => {
                            return <div key={index}><button className={ classnames('search-modal-category', reference === item ? 'is-active' : '') } onClick={() => switchCategory(item)}>{capitalizeFirst(item)} {`(${resultsCount[item]})`}</button></div>;
                        })}
                    </div>
                    <div className="search-categories-dropdown">
                        <button type="button" className="button || default">
                            <span>{capitalizeFirst(reference)} {`(${resultsCount[reference]})`}</span>
                            <Icon name="arrow" width="15" height="15" title="" />
                        </button>
                        <select className="system-select" value={reference} onChange={(e) => switchCategory(e.target.value)}>
                            {REFERENCES.map((item, index) => {
                                return <option key={index} value={item}>{capitalizeFirst(item)} {`(${resultsCount[item]})`}</option>;
                            })}
                        </select>
                    </div>
                </nav>
                <div>
                    <Link to={FRONTEND_ROUTES.ADVANCED_SEARCH}>Go to Advanced Search</Link>
                </div>
            </div>
            {renderFilterGroups()}
        </div>
    );
};

GlobalSearchTag.propTypes = {
    reference: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired,
    resultsCount: PropTypes.object.isRequired,
    show: PropTypes.bool
};

export default GlobalSearchTag;
