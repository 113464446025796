import canUseDom from './utilities/dom';
import { configureStore } from './store';
import { customUrlsInitAction } from './ducks/custom-urls';
import { globalFieldsInitAction } from './ducks/global-fields';
import { HistoryRouter } from 'redux-first-history/rr6';
import { Provider } from 'react-redux';
import React from 'react';
import Routes from './router';
import { StaticRouter } from 'react-router-dom/server';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { formatMenus, menusFetchSuccessAction } from 'app/ducks/menus';

// Import styles
import './sass/index';

/**
 * Both client and server side share this code to create the redux store and
 * the router, which can be static or connected. You can also use the extra state
 * to manually dispatch actions in the redux store.
 * @param {String} context The context where this file is being used ('server' or 'client' sides)
 * @param {String} url Url path ('/', '/whats-on')
 * @param {Map} initialState The app initial state immutable map
 * @param {Object} extraState Extra state from multiple API calls
 * @returns {Object} The connected app and the latest app state
 */
export default function getApp(context, url, initialState, extraState) {
    const history = canUseDom() ?
        createBrowserHistory() :
        createMemoryHistory({ initialEntries: [url] });

    const { reduxStore, reduxHistory } = configureStore(initialState, history);

    /**
     * Re-build the correct state for SSR
     */
    if (context === 'server' && extraState) {
        // For menus
        if (extraState.menus) { // Should always be true
            const newAction = menusFetchSuccessAction(formatMenus(extraState.menus));
            reduxStore.dispatch(newAction);
        }
        // For custom URLs (always present)
        reduxStore.dispatch(customUrlsInitAction(extraState.customUrls));
        // Global fields are always present
        reduxStore.dispatch(globalFieldsInitAction(extraState.globalFields));
    }

    const Router = {
        server: StaticRouter,
        client: HistoryRouter
    }[context];

    const routerProps = {
        server: { context: {}, location: url },
        client: { history: reduxHistory }
    }[context];

    const app = (
        <Provider store={reduxStore}>
            <Router {...routerProps}>
                <Routes />
            </Router>
        </Provider>
    );

    return {
        app: app,
        latestState: reduxStore.getState()
    };
}
