import env from './env';

export const { API_BASE_URL, CLIENT_ID, CLIENT_SECRET } = env;

const APP_ID = 'aag';

const LIMIT_PER_PAGE = 1000;

export const ENDPOINTS = {
    // AAG specific: search page using uri not slug
    PAGES: (uri) => `${API_BASE_URL}/${APP_ID}/pages?uri=${encodeURIComponent(uri)}`,
    PAGES_ALL: `${API_BASE_URL}/${APP_ID}/pages?limit=${LIMIT_PER_PAGE}`,
    TOKEN: `${API_BASE_URL}/oauth/token`,
    MENUS: `${API_BASE_URL}/${APP_ID}/menus`,
    NOTIFICATIONS: `${API_BASE_URL}/${APP_ID}/notifications`,
    ARTISTS: (id) => `${API_BASE_URL}/vernon/artists?id=${id}`,
    ARTWORKS: (id) => `${API_BASE_URL}/vernon/artworks?id=${id}`,
    ARCHIVES: (id) => `${API_BASE_URL}/vernon/archives?id=${id}`,
    ARCHIVE_ITEMS: (archiveId, itemId) => `${API_BASE_URL}/vernon/archives/${archiveId}/items/${itemId}`,
    ARCHIVE_ITEMS_ALL: (archiveId) => `${API_BASE_URL}/vernon/archives/${archiveId}/items`, // non-paginated endpoint
    NEWS: (slug) => `${API_BASE_URL}/${APP_ID}/news?slug=${encodeURIComponent(slug)}`,
    EXHIBITIONS: (slug) => `${API_BASE_URL}/${APP_ID}/exhibitions?slug=${encodeURIComponent(slug)}`,
    EVENTS: (slug) => `${API_BASE_URL}/${APP_ID}/events?slug=${encodeURIComponent(slug)}`,
    FEATURED_EXHIBITIONS: `${API_BASE_URL}/${APP_ID}/exhibitions?is_featured=1&is_published=true`,
    SEARCH: (reference) => {
        if (reference) {
            return `${API_BASE_URL}/${APP_ID}/search/${reference}`;
        }

        return `${API_BASE_URL}/${APP_ID}/search`;
    },
    OBJECT_TYPES: `${API_BASE_URL}/${APP_ID}/artworks-object-types`,
    GLOBAL_SEARCH_COUNT: `${API_BASE_URL}/${APP_ID}/global-search/count`,
    CUSTOM_URLS: `${API_BASE_URL}/${APP_ID}/custom-urls`,
    GLOBAL_FIELDS: `${API_BASE_URL}/${APP_ID}/global-fields`,
    SITEMAP: `${API_BASE_URL}/${APP_ID}/sitemap`,
    SITEMAP_VERNON: `${API_BASE_URL}/${APP_ID}/sitemap-vernon`,
};

export const RESPONSE = {
    OK: 200,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
};

export const ERROR_MESSAGE = {
    INTERNAL_SERVER_ERROR: 'Oops, something went wrong. Please try again later.'
};

export const TIMEOUT = 0;
