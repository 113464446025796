import ContentSetContainer from '../partials/content-set-container';
import PropTypes from 'prop-types';
import { THEME_DEFAULT } from 'app/utilities/content-blocks';
import React, { useEffect, useState } from 'react';

const ContentSet = ({ items, blockTheme = THEME_DEFAULT, blockStyle = {}, title, rows, onShowMore }) => {
    const [showAll, setShowAll] = useState(false);
    // line nubmer for display rows
    const lineNumber = 4;
    const showAllTiles = () => {
        setShowAll(!showAll);

        // Trigger parent show more if defined
        // For async fetch items
        if (!showAll && onShowMore) {
            onShowMore();
        }
    };

    useEffect(() => {
        if (items.length / lineNumber <= rows) {
            setShowAll(true);
        }
    }, []);

    if (!items.length) {
        return null;
    }

    return (
        <section className={`content-set ${blockTheme}`}>
            <div className="tile-block">
                {title && <h2 className="tile-block-title">{title}</h2>}
                <div className="tile-block-inner">
                    {items.map((item, index) => (
                        <ContentSetContainer key={index} item={item} index={index} showTile={(index / lineNumber) < rows || showAll} blockTheme={blockTheme} blockStyle={blockStyle} />
                    ))}
                </div>

                {showAll ? '' : <div className="tile-showbtn"><button className="button default tiny" onClick={showAllTiles}>Show All</button></div>}
            </div>
        </section>
    );
};

ContentSet.propTypes = {
    items: PropTypes.array.isRequired,
    blockTheme: PropTypes.string,
    blockStyle: PropTypes.object,
    title: PropTypes.string,
    rows: PropTypes.string,
    onShowMore: PropTypes.func,
};

export default ContentSet;
