/* eslint-disable jsx-a11y/label-has-associated-control */
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import Icon from 'app/components/partials/icon';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const QUICK_SELECT_BUTTONS = [
    { label: 'TODAY', value: 'today' },
    { label: 'TOMORROW', value: 'tomorrow' },
    { label: 'THIS WEEK', value: 'this-week' },
    { label: 'NEXT WEEK', value: 'next-week' },
    { label: 'THIS MONTH', value: 'this-month' },
    { label: 'NEXT MONTH', value: 'next-month' },
    { label: 'FUTURE EXHIBITIONS', value: 'future' },
    { label: 'PAST EXHIBITIONS', value: 'past' }
];

const ExhibitionFilter = ({ formik }) => {
    const [openOptionList, setOpenOptionList] = useState(false);
    const filterRef = useRef();

    const toggleDropdownHandler = () => {
        setOpenOptionList((prevValue) => !prevValue);
    };

    const filterHandler = (key, value) => {
        formik.setFieldValue(key, value);
        formik.setFieldValue('from-date', '');
        formik.setFieldValue('to-date', '');
        setOpenOptionList(false);
        formik.submitForm();
    };

    const setDate = (field, value) => {
        // ensure we don't pass invalid time value to datepicker otherwise it crashes the page
        const dt = moment(value);
        const newValue = dt.isValid() ? dt.format('yyyy-MM-DD') : '';
        formik.setFieldValue(field, newValue);
    };

    const renderOptions = () => {
        return (
            <div className={ classnames('options', 'exhibition-options', openOptionList ? '' : 'is-hidden') }>
                <div className="quick-select">
                    <h6>Quick select:</h6>
                    <div className="option-group-row option-items">
                        {QUICK_SELECT_BUTTONS.map((item, index) => {
                            return <button className={ classnames('button || default || small', formik.values['date-range'] === item.value ? 'selected' : '') } type="button" key={index} onClick={() => filterHandler('date-range', item.value)}>{item.label}</button>;
                        })}
                    </div>
                </div>
                <div className="date-options">
                    <h6>Date range:</h6>
                    <div className="date-range">
                        <div className="date-picker">
                            <DatePicker
                                id="from-date"
                                name="from-date"
                                className="input"
                                dateFormat="dd/MM/yyyy"
                                selected={formik.values['from-date'] && new Date(formik.values['from-date'])}
                                placeholderText="dd/mm/yyyy"
                                onChange={(value) => setDate('from-date', value)} />
                            <label htmlFor="from-date"><Icon name="calendar" width="20" height="20" /></label>
                        </div>
                        <span className="highlight" >to</span>
                        <div className="date-picker">
                            <DatePicker
                                id="to-date"
                                name="to-date"
                                className="input"
                                dateFormat="dd/MM/yyyy"
                                selected={formik.values['to-date'] && new Date(formik.values['to-date'])}
                                placeholderText="dd/mm/yyyy"
                                onChange={(value) => setDate('to-date', value)} />
                            <label htmlFor="to-date"><Icon name="calendar" width="20" height="20" /></label>
                        </div>
                    </div>
                </div>
                <div className="controls">
                    <button className="button primary" type="submit">Apply</button>
                </div>
            </div>
        );
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpenOptionList(false);
        } else if (event.target.type === 'submit') {
            setOpenOptionList(false);
        }
    };

    useEffect(() => {
        const optionHeight = filterRef.current.getElementsByClassName('options')[0].clientHeight;
        const marginBottom = openOptionList ? `${optionHeight}px` : null;
        filterRef.current.style['margin-bottom'] = marginBottom;

        if (openOptionList === false) {
            return;
        }
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [openOptionList]);

    return (
        <div className="filter-groups" ref={filterRef}>
            <div className={ classnames('filter-dropdown', 'wide', openOptionList ? 'open' : '') } ref={filterRef}>
                <button type="button" className="button || default" onClick={toggleDropdownHandler}>Refine results<Icon name="arrow" width="15" height="15" title="" /></button>
            </div>
            { renderOptions() }
        </div>
    );
};

ExhibitionFilter.propTypes = {
    formik: PropTypes.object.isRequired
};

export default ExhibitionFilter;
