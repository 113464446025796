import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { THEME_AEX, THEME_DEFAULT } from 'app/utilities/content-blocks';

const Algorithm = ({ file, background, left, right, location, algorithm_text, blockTheme = THEME_DEFAULT }) => {
    const getAlgoCls = () => {
        if (blockTheme === THEME_AEX) {
            // aex block is always black
            return classnames('banner', 'algorithm');
        }

        return classnames('banner', 'algorithm', background);
    };

    const getBlocksCls = () => {
        if (blockTheme === THEME_AEX) {
            // aex block is always centered
            return 'blocks';
        }

        return classnames('blocks', location);
    };

    return (
        <div className={getAlgoCls()}>
            <h1 className="text-hidden">{algorithm_text}</h1>
            <img src={file} alt={algorithm_text} />
            <div className={getBlocksCls()} aria-hidden="true">
                <p className="block || rich-editor-content" aria-hidden="true" dangerouslySetInnerHTML={{ __html: left }}></p>
                <p className="block" aria-hidden="true">A<br />r<br />t</p>
                <p className="block || rich-editor-content" aria-hidden="true" dangerouslySetInnerHTML={{ __html: right }}></p>
            </div>
        </div>
    );
};

Algorithm.defaultProps = {
    file: null
};

Algorithm.propTypes = {
    file: PropTypes.string,
    background: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    algorithm_text: PropTypes.string.isRequired,
    blockTheme: PropTypes.string
};

export default Algorithm;
