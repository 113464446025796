import PropTypes from 'prop-types';
import React from 'react';

const PRE = 2;

const Data = ({ data, title }) => {
    return (
        <div className="placeholder-component || constrain-width">
            <code>{title}</code>
            {data && <pre>{JSON.stringify(data, null, PRE)}</pre>}
        </div>
    );
};

Data.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string.isRequired
};

export default Data;
