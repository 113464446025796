import ButtonProceed from './button-proceed';
import PropTypes from 'prop-types';
import React from 'react';

const HeroBanner = ({ mainImage, mainImageStyle = {}, mobileImage, target, style = {}, btnStyle = {}, children }) => {
    const desktopImageStyle = {
        backgroundImage: `url(${mainImage})`,
        ...mainImageStyle,
    };

    return (
        <div className="hero-banner" style={style}>
            {mainImage &&
                <div data-hero-image="desktop" style={desktopImageStyle}></div>
            }

            {mobileImage &&
                <img data-hero-image="mobile" src={mobileImage} alt="thumbnail" />
            }

            <ButtonProceed target={target} style={btnStyle} />

            {children}
        </div>
    );
};

HeroBanner.propTypes = {
    mainImage: PropTypes.string,
    mainImageStyle: PropTypes.object,
    mobileImage: PropTypes.string,
    target: PropTypes.object,
    btnStyle: PropTypes.object,
    style: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};

export default HeroBanner;
