import { slugify } from './string';

/**
 * Extract the tag names for the given normalized tags
 * These params are provided by the events API
 */
export function getTagInfo(normalizedTags, allTagNames) {
    // Return the tag name and the normalized slug
    const convertedTags = allTagNames.map((tag) => {
        return { tag: tag, url: slugify(tag) };
    });

    return convertedTags.filter((tag) => normalizedTags.includes(tag.url));
}
