import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { THEME_AEX, THEME_DEFAULT } from 'app/utilities/content-blocks';

const TextContent = ({ blockTheme = THEME_DEFAULT, aside, content, quote }) => {
    const getPrimaryCls = () => {
        const hasAside = aside || quote;

        return classnames('content-primary', hasAside ? 'text-aside' : 'text-columns', 'rich-editor-content');
    };

    return (
        <section className={`text-content-block ${blockTheme}`}>
            <div className={getPrimaryCls()} dangerouslySetInnerHTML={{ __html: content }}></div>
            {aside && (
                <aside className="content-aside">
                    <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: aside }}></div>
                </aside>
            )}
            {quote && (
                <aside className="content-quote">
                    <blockquote className="pullout-quote || rich-editor-content" dangerouslySetInnerHTML={{ __html: quote }}></blockquote>
                </aside>
            )}
        </section>
    );
};

TextContent.propTypes = {
    blockTheme: PropTypes.string,
    aside: PropTypes.string,
    content: PropTypes.string.isRequired,
    quote: PropTypes.string
};

export default TextContent;
