import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const MenuItemNav = ({ menuItems }) => {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState(null);

    useEffect(() => {
        setActiveItem(location.hash);
    }, [location]);

    return (
        <div className="menu-item-wrapper">
            {menuItems.map(({ title, slug }, idx) => {
                const cls = classnames('menu-item', {
                    'is-active': activeItem === `#${slug}`
                });

                return (
                    <a className={cls} key={idx} href={`#${slug}`}>
                        <p className="menu-item-title">{title}</p>
                    </a>
                );
            })}
        </div>
    );
};

MenuItemNav.propTypes = {
    menuItems: PropTypes.array.isRequired,
};

export default MenuItemNav;
