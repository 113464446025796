/* eslint-disable jsx-a11y/label-has-associated-control */
import ContentBlocks from 'app/components/content-blocks/index';
import FilterDropdown from 'app/components/partials/category-search/filter-dropdown';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import hero from 'static/images/search-art-ideas-hero.jpg';
import Icon from 'app/components/partials/icon';
import PageHeader from 'app/components/partials/page-header';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';

const REFERENCE_TYPE = {
    options: [
        { label: 'Artworks', value: 'artworks' },
        { label: 'Archives', value: 'archives' },
        { label: 'Ideas', value: 'ideas' }
    ],
    defaultIndex: 0,
};

const ArtIdeas = ({ data }) => {
    const navigate = useNavigate();

    const {
        blocks,
        title,
        header_image: images,
        subtitle: subTitle,
        background_color: backgroundColor,
    } = data;

    const formik = useFormik({
        initialValues: {
            reference: 'artworks',
            keywords: '',
        },
        onSubmit: async(values) => {
            const searchString = values.keywords === '' ? '' : `?keywords=${encodeURIComponent(values.keywords)}`;
            navigate(`/search/${values.reference}${searchString}`);
        }
    });

    const renderArtIdeasBanner = () => {
        return (
            <div className="art-ideas-banner || constrain-width">
                <div className="algorithm-wrapper">
                    <h1 className="text-hidden">A Search Tool</h1>
                    <img src={hero} alt="A Search Tool" />
                    <div className="algorithm-left">
                        <p className="white || text-block">
                            Sea<br />
                            <br />
                        </p>
                        <p className="red || text-block">
                            A<br />
                            R<br />
                            T<br />
                        </p>
                        <p className="white || text-block">
                            ch<br />
                            ool<br />
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const renderArtIdeasSearchForm = (formik) => {
        return (
            <form className="art-ideas-search-form || search-bar || constrain-width" onSubmit={formik.handleSubmit}>
                <div className="field-group grow">
                    <label htmlFor="search-query" className="label search"><Icon name="search" width="24" height="24" /></label>
                    <input className="input" id="search-query" type="text" name="keywords" value={formik.values.keywords} onChange={formik.handleChange} placeholder="Search for..." autoComplete="off" />
                </div>
                <div className="field-group shrink no-padding reference-filter">
                    <FilterDropdown className="reference-type" filterName="reference" filterOptions={REFERENCE_TYPE.options} filterLable="Reference" defaultOptionIndex={REFERENCE_TYPE.defaultIndex} formik={formik} clickToSubmit={false} />
                </div>
                <div className="field-group shrink">
                    <button className="button primary small" type="submit">SEARCH</button>
                </div>
                <div className="field-group shrink">
                    <Link className="button || default || small || block" to={FRONTEND_ROUTES.ADVANCED_SEARCH}>ADVANCED SEARCH</Link>
                </div>
            </form>
        );
    };

    return (
        <div className="art-idea-page">
            <PageHeader
                image={images}
                subTitle={subTitle}
                title={title}
                backgroundColor={backgroundColor}
            />
            {renderArtIdeasBanner()}
            {renderArtIdeasSearchForm(formik)}
            <ContentBlocks data={blocks} pageTitle={title} extraClass="constrain-width" />
        </div>
    );
};

ArtIdeas.propTypes = {
    data: PropTypes.object.isRequired
};

export default ArtIdeas;
