export const ARTIST_LIST_ITEMS = [
    {
        'name': 'Sandy Adsett',
        'id': 'person-2200',
        'type': 'person',
        'artist_id': '2200',
        'slug': 'sandy-adsett'
    },
    {
        'name': 'Hiria Anderson',
        'id': 'person-11767',
        'type': 'person',
        'artist_id': '11767',
        'slug': 'hiria-anderson'
    },
    {
        'name': 'Reweti Arapere',
        'id': 'person-12005',
        'type': 'person',
        'artist_id': '12005',
        'slug': 'reweti-arapere'
    },
    {
        'name': 'Margaret Aull',
        'id': 'person-11987',
        'type': 'person',
        'artist_id': '11987',
        'slug': 'margaret-aull'
    },
    {
        'name': 'Erena Baker',
        'id': 'person-12015',
        'type': 'person',
        'artist_id': '12015',
        'slug': 'erena-baker'
    },
    {
        'name': 'Gabrielle Belz',
        'id': 'person-12024',
        'type': 'person',
        'artist_id': '12024',
        'slug': 'gabrielle-belz'
    },
    {
        'name': 'Israel Tangaroa Birch',
        'id': 'person-11993',
        'type': 'person',
        'artist_id': '11993',
        'slug': 'israel-tangaroa-birch'
    },
    {
        'name': 'Buster Black',
        'id': 'person-900',
        'type': 'person',
        'artist_id': '900',
        'slug': 'buster-black'
    },
    {
        'name': 'Chris Bryant-Toi',
        'id': 'person-12029',
        'type': 'person',
        'artist_id': '12029',
        'slug': 'chris-bryant-toi'
    },
    {
        'name': 'Tangimoe Clay',
        'id': 'person-12014',
        'type': 'person',
        'artist_id': '12014',
        'slug': 'tangimoe-clay'
    },
    {
        'name': 'Paerau Corneal',
        'id': 'person-12022',
        'type': 'person',
        'artist_id': '12022',
        'slug': 'paerau-corneal'
    },
    {
        'name': 'Shane Cotton',
        'id': 'person-1722',
        'type': 'person',
        'artist_id': '1722',
        'slug': 'shane-cotton'
    },
    {
        'name': 'Natalie Couch',
        'id': 'person-12032',
        'type': 'person',
        'artist_id': '12032',
        'slug': 'natalie-couch'
    },
    {
        'name': 'Davina Duke',
        'id': 'person-12023',
        'type': 'person',
        'artist_id': '12023',
        'slug': 'davina-duke'
    },
    {
        'name': 'Bethany Matai Edmunds',
        'id': 'person-12000',
        'type': 'person',
        'artist_id': '12000',
        'slug': 'bethany-matai-edmunds'
    },
    {
        'name': 'Zena Elliott',
        'id': 'person-11995',
        'type': 'person',
        'artist_id': '11995',
        'slug': 'zena-elliott'
    },
    {
        'name': 'Elizabeth Ellis',
        'id': 'person-12038',
        'type': 'person',
        'artist_id': '12038',
        'slug': 'elizabeth-ellis'
    },
    {
        'name': 'John Bevan Ford',
        'id': 'person-1992',
        'type': 'person',
        'artist_id': '1992',
        'slug': 'john-bevan-ford'
    },
    {
        'name': 'Jacqueline Fraser',
        'id': 'person-1981',
        'type': 'person',
        'artist_id': '1981',
        'slug': 'jacqueline-fraser'
    },
    {
        'name': 'Darryn George',
        'id': 'person-6419',
        'type': 'person',
        'artist_id': '6419',
        'slug': 'darryn-george'
    },
    {
        'name': 'Steve Gibbs',
        'id': 'person-12035',
        'type': 'person',
        'artist_id': '12035',
        'slug': 'steve-gibbs'
    },
    {
        'name': 'Star Gossage',
        'id': 'person-6558',
        'type': 'person',
        'artist_id': '6558',
        'slug': 'star-gossage'
    },
    {
        'name': 'Brett Graham',
        'id': 'person-828',
        'type': 'person',
        'artist_id': '828',
        'slug': 'brett-graham'
    },
    {
        'name': 'Charlotte Graham',
        'id': 'person-10844',
        'type': 'person',
        'artist_id': '10844',
        'slug': 'charlotte-graham'
    },
    {
        'name': 'Fred Graham',
        'id': 'person-2279',
        'type': 'person',
        'artist_id': '2279',
        'slug': 'fred-graham'
    },
    {
        'name': 'Lyonel Grant',
        'id': 'person-11997',
        'type': 'person',
        'artist_id': '11997',
        'slug': 'lyonel-grant'
    },
    {
        'name': 'Ayesha Green',
        'id': 'person-11265',
        'type': 'person',
        'artist_id': '11265',
        'slug': 'ayesha-green'
    },
    {
        'name': 'Ngahuia Harrison',
        'id': 'person-12033',
        'type': 'person',
        'artist_id': '12033',
        'slug': 'ngahuia-harrison'
    },
    {
        'name': 'Chris Heaphy',
        'id': 'person-1752',
        'type': 'person',
        'artist_id': '1752',
        'slug': 'chris-heaphy'
    },
    {
        'name': 'Ngaahina Hohaia',
        'id': 'person-12009',
        'type': 'person',
        'artist_id': '12009',
        'slug': 'ngaahina-hohaia'
    },
    {
        'name': 'Rangituhia Hollis',
        'id': 'person-11112',
        'type': 'person',
        'artist_id': '11112',
        'slug': 'rangituhia-hollis'
    },
    {
        'name': 'Ralph Hotere',
        'id': 'person-1952',
        'type': 'person',
        'artist_id': '1952',
        'slug': 'ralph-hotere'
    },
    {
        'name': 'John Hovell',
        'id': 'person-1521',
        'type': 'person',
        'artist_id': '1521',
        'slug': 'john-hovell'
    },
    {
        'name': 'Lonnie Hutchinson',
        'id': 'person-2958',
        'type': 'person',
        'artist_id': '2958',
        'slug': 'lonnie-hutchinson'
    },
    {
        'name': 'Robert Jahnke',
        'id': 'person-1218',
        'type': 'person',
        'artist_id': '1218',
        'slug': 'robert-jahnke'
    },
    {
        'name': 'Robyn Kahukiwa',
        'id': 'person-1268',
        'type': 'person',
        'artist_id': '1268',
        'slug': 'robyn-kahukiwa'
    },
    {
        'name': 'Leilani Kake',
        'id': 'person-8432',
        'type': 'person',
        'artist_id': '8432',
        'slug': 'leilani-kake'
    },
    {
        'name': 'Eugene Kara',
        'id': 'person-12030',
        'type': 'person',
        'artist_id': '12030',
        'slug': 'eugene-kara'
    },
    {
        'name': 'Emily Karaka',
        'id': 'person-357',
        'type': 'person',
        'artist_id': '357',
        'slug': 'emily-karaka'
    },
    {
        'name': 'Natasha Keating',
        'id': 'person-4163',
        'type': 'person',
        'artist_id': '4163',
        'slug': 'natasha-keating'
    },
    {
        'name': 'Rangi Kipa',
        'id': 'person-6373',
        'type': 'person',
        'artist_id': '6373',
        'slug': 'rangi-kipa'
    },
    {
        'name': 'Te Rongo Kirkwood',
        'id': 'person-12037',
        'type': 'person',
        'artist_id': '12037',
        'slug': 'te-rongo-kirkwood'
    },
    {
        'name': 'Jimmy James Kouratoras',
        'id': 'person-12004',
        'type': 'person',
        'artist_id': '12004',
        'slug': 'jimmy-james-kouratoras'
    },
    {
        'name': 'Lily Laita',
        'id': 'person-5323',
        'type': 'person',
        'artist_id': '5323',
        'slug': 'lily-laita'
    },
    {
        'name': 'Maureen Lander',
        'id': 'person-4074',
        'type': 'person',
        'artist_id': '4074',
        'slug': 'maureen-lander'
    },
    {
        'name': 'Matekino Lawless',
        'id': 'person-12018',
        'type': 'person',
        'artist_id': '12018',
        'slug': 'matekino-lawless'
    },
    {
        'name': 'Randal Leach',
        'id': 'person-11989',
        'type': 'person',
        'artist_id': '11989',
        'slug': 'randal-leach'
    },
    {
        'name': 'Jeremy Leatinu\'u',
        'id': 'person-8431',
        'type': 'person',
        'artist_id': '8431',
        'slug': 'jeremy-leatinuu'
    },
    {
        'name': 'Janet Lilo',
        'id': 'person-8472',
        'type': 'person',
        'artist_id': '8472',
        'slug': 'janet-lilo'
    },
    {
        'name': 'Mere Lodge',
        'id': 'person-6309',
        'type': 'person',
        'artist_id': '6309',
        'slug': 'mere-lodge'
    },
    {
        'name': 'Dan Mace',
        'id': 'person-12027',
        'type': 'person',
        'artist_id': '12027',
        'slug': 'dan-mace'
    },
    {
        'name': 'Hemi Macgregor',
        'id': 'person-8646',
        'type': 'person',
        'artist_id': '8646',
        'slug': 'hemi-macgregor'
    },
    {
        'name': 'Matthew McIntyre-Wilson',
        'id': 'person-10169',
        'type': 'person',
        'artist_id': '10169',
        'slug': 'matthew-mcintyre-wilson'
    },
    {
        'name': 'Mata Aho Collective',
        'id': 'person-11990',
        'type': 'person',
        'artist_id': '11990',
        'slug': 'mata-aho-collective'
    },
    {
        'name': 'Kāterina Mataira',
        'id': 'person-7161',
        'type': 'person',
        'artist_id': '7161',
        'slug': 'katerina-mataira'
    },
    {
        'name': 'Gina Matchitt',
        'id': 'person-93',
        'type': 'person',
        'artist_id': '93',
        'slug': 'gina-matchitt'
    },
    {
        'name': 'Para Matchitt',
        'id': 'person-591',
        'type': 'person',
        'artist_id': '591',
        'slug': 'para-matchitt'
    },
    {
        'name': 'Heperi Mita',
        'id': 'person-12013',
        'type': 'person',
        'artist_id': '12013',
        'slug': 'heperi-mita'
    },
    {
        'name': 'Merata Mita',
        'id': 'person-12012',
        'type': 'person',
        'artist_id': '12012',
        'slug': 'merata-mita'
    },
    {
        'name': 'Neke Moa',
        'id': 'person-10172',
        'type': 'person',
        'artist_id': '10172',
        'slug': 'neke-moa'
    },
    {
        'name': 'Linda Munn',
        'id': 'person-12008',
        'type': 'person',
        'artist_id': '12008',
        'slug': 'linda-munn'
    },
    {
        'name': 'Claudine Muru',
        'id': 'person-12002',
        'type': 'person',
        'artist_id': '12002',
        'slug': 'claudine-muru'
    },
    {
        'name': 'Selwyn Muru',
        'id': 'person-1270',
        'type': 'person',
        'artist_id': '1270',
        'slug': 'selwyn-muru'
    },
    {
        'name': 'Manos Nathan',
        'id': 'person-11998',
        'type': 'person',
        'artist_id': '11998',
        'slug': 'manos-nathan'
    },
    {
        'name': 'Buck Nin',
        'id': 'person-6162',
        'type': 'person',
        'artist_id': '6162',
        'slug': 'buck-nin'
    },
    {
        'name': 'James Ormsby',
        'id': 'person-12006',
        'type': 'person',
        'artist_id': '12006',
        'slug': 'james-ormsby'
    },
    {
        'name': 'Rona Ngahuia Osborne',
        'id': 'person-12026',
        'type': 'person',
        'artist_id': '12026',
        'slug': 'rona-ngahuia-osborne'
    },
    {
        'name': 'Fiona Pardington',
        'id': 'person-789',
        'type': 'person',
        'artist_id': '789',
        'slug': 'fiona-pardington'
    },
    {
        'name': 'Michael Parekōwhai',
        'id': 'person-563',
        'type': 'person',
        'artist_id': '563',
        'slug': 'michael-parekowhai'
    },
    {
        'name': 'Reuben Paterson',
        'id': 'person-4007',
        'type': 'person',
        'artist_id': '4007',
        'slug': 'reuben-paterson'
    },
    {
        'name': 'Nova Paul',
        'id': 'person-11980',
        'type': 'person',
        'artist_id': '11980',
        'slug': 'nova-paul'
    },
    {
        'name': 'Matt Pine',
        'id': 'person-913',
        'type': 'person',
        'artist_id': '913',
        'slug': 'matt-pine'
    },
    {
        'name': 'Nathan Pohio',
        'id': 'person-10529',
        'type': 'person',
        'artist_id': '10529',
        'slug': 'nathan-pohio'
    },
    {
        'name': 'Rachael Rakena',
        'id': 'person-6881',
        'type': 'person',
        'artist_id': '6881',
        'slug': 'rachael-rakena'
    },
    {
        'name': 'Shona Rapira-Davies',
        'id': 'person-879',
        'type': 'person',
        'artist_id': '879',
        'slug': 'shona-rapira-davies'
    },
    {
        'name': 'Aimee Ratana',
        'id': 'person-6569',
        'type': 'person',
        'artist_id': '6569',
        'slug': 'aimee-ratana'
    },
    {
        'name': 'Lisa Reihana',
        'id': 'person-4064',
        'type': 'person',
        'artist_id': '4064',
        'slug': 'lisa-reihana'
    },
    {
        'name': 'Bridget Reweti',
        'id': 'person-9661',
        'type': 'person',
        'artist_id': '9661',
        'slug': 'bridget-reweti'
    },
    {
        'name': 'Baye Riddell',
        'id': 'person-12021',
        'type': 'person',
        'artist_id': '12021',
        'slug': 'baye-riddell'
    },
    {
        'name': 'Natalie Robertson',
        'id': 'person-3652',
        'type': 'person',
        'artist_id': '3652',
        'slug': 'natalie-robertson'
    },
    {
        'name': 'Peter Robinson',
        'id': 'person-2278',
        'type': 'person',
        'artist_id': '2278',
        'slug': 'peter-robinson'
    },
    {
        'name': 'Emily Schuster',
        'id': 'person-12007',
        'type': 'person',
        'artist_id': '12007',
        'slug': 'emily-schuster'
    },
    {
        'name': 'Maree Sheehan',
        'id': 'person-11988',
        'type': 'person',
        'artist_id': '11988',
        'slug': 'maree-sheehan'
    },
    {
        'name': 'Huhana Smith',
        'id': 'person-10754',
        'type': 'person',
        'artist_id': '10754',
        'slug': 'huhana-smith'
    },
    {
        'name': 'Kereama Taepa',
        'id': 'person-12028',
        'type': 'person',
        'artist_id': '12028',
        'slug': 'kereama-taepa'
    },
    {
        'name': 'Ngatai Taepa',
        'id': 'person-8645',
        'type': 'person',
        'artist_id': '8645',
        'slug': 'ngatai-taepa'
    },
    {
        'name': 'Wi Taepa',
        'id': 'person-8483',
        'type': 'person',
        'artist_id': '8483',
        'slug': 'wi-taepa'
    },
    {
        'name': 'Tāwera Tahuri',
        'id': 'person-12036',
        'type': 'person',
        'artist_id': '12036',
        'slug': 'tawera-tahuri'
    },
    {
        'name': 'Kelcy Taratoa',
        'id': 'person-12031',
        'type': 'person',
        'artist_id': '12031',
        'slug': 'kelcy-taratoa'
    },
    {
        'name': 'Shannon Te Ao',
        'id': 'person-9619',
        'type': 'person',
        'artist_id': '9619',
        'slug': 'shannon-te-ao'
    },
    {
        'name': 'Jasmine Te Hira',
        'id': 'person-10622',
        'type': 'person',
        'artist_id': '10622',
        'slug': 'jasmine-te-hira'
    },
    {
        'name': 'Te Maari',
        'id': 'person-12073',
        'type': 'person',
        'artist_id': '12073',
        'slug': 'te-maari'
    },
    {
        'name': 'Saffronn Te Ratana',
        'id': 'person-1067',
        'type': 'person',
        'artist_id': '1067',
        'slug': 'saffronn-te-ratana'
    },
    {
        'name': 'Toi Te Rito Maihi',
        'id': 'person-9542',
        'type': 'person',
        'artist_id': '9542',
        'slug': 'toi-te-rito-maihi'
    },
    {
        'name': 'Kura Te Waru Rewiri',
        'id': 'person-2044',
        'type': 'person',
        'artist_id': '2044',
        'slug': 'kura-te-waru-rewiri'
    },
    {
        'name': 'Donna Tupaea-Petero',
        'id': 'person-12019',
        'type': 'person',
        'artist_id': '12019',
        'slug': 'donna-tupaea-petero'
    },
    {
        'name': 'Colleen Waata Urlich',
        'id': 'person-12020',
        'type': 'person',
        'artist_id': '12020',
        'slug': 'colleen-waata-urlich'
    }
];
