import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { useEffect, useRef, useState } from 'react';

const useReferenceSearch = (reference, queryString, page) => {
    const [data, setData] = useState([]);
    const [currentEntity, setCurrentEntity] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [error, setError] = useState(null);
    const controllerRef = useRef();

    const formatEventData = (entities) => {
        return entities.map((entity) => {
            return {
                data: entity
            };
        });
    };

    const getSearchParams = () => {
        const params = new URLSearchParams(queryString);
        params.append('page', page);
        // for news articles order by newest published first
        if (reference === 'articles') {
            params.append('orderBy', 'publish_at');
            params.append('direction', 'desc');
        }

        return params;
    };

    const fetchData = async() => {
        setIsLoading(true);
        setError(null);
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;
        try {
            const results = await fetch(`${ENDPOINTS.SEARCH(reference)}?${getSearchParams()}`, null, {
                signal: controllerRef.current.signal,
                method: 'GET'
            });
            const data = formatEventData(results[reference].data);
            const { last_page: lastPage } = results[reference];
            if (page === 1) {
                setData(data);
            } else {
                setData((preValue) => [...preValue, ...data]);
            }
            setCurrentEntity(reference);
            setHasMore(page < lastPage);
        } catch (error) {
            setError(error);
        } finally {
            controllerRef.current = null;
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [reference, queryString, page]);

    return {
        data,
        currentEntity,
        isLoading,
        hasMore,
        error
    };
};

export default useReferenceSearch;
