import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const FullScreenDialog = forwardRef(({ children, extraClass }, ref) => (
    <div className={`full-screen-dialog ${extraClass}`} ref={ref}>
        {children}
    </div>
));

FullScreenDialog.displayName = 'FullScreenDialog';
FullScreenDialog.defaultProps = {
    extraClass: ''
};

FullScreenDialog.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    extraClass: PropTypes.string
};

export default FullScreenDialog;
