import { DATE_FORMAT_WITH_YEAR } from 'config/app';
import defaultImage from 'static/images/svgs/art-toi.svg';
import { Link } from 'react-router-dom';
import { momentNZ } from 'app/utilities/date-time';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const ContentSetNews = forwardRef(({ item, index, showTile }, ref) => {
    const generateEventDate = (date) => momentNZ(date).isValid() && momentNZ(date).format(DATE_FORMAT_WITH_YEAR) || '';
    const name = item.data.name;
    const url = `/article/${item.data.slug}`;
    const image = item.data.thumbnail;
    const newsDate = generateEventDate(item.data.publish_at);

    const renderAuthor = () => {
        const author = item.data.author;
        if (author) {
            return <p>{author}</p>;
        }

        return null;
    };

    return (
        <article className={showTile ? 'tile tile-news' : 'tile-hide'} key={index} ref={ref}>
            <div>
                <Link className="tile-thumbnail-link" to={url}>
                    <div className="tile-thumbnail">
                        <picture className="hover-area">
                            <div data-hover-text>view more</div>
                            <div className="image-container || square">
                                <img className="tile-thumbnail-image" src={image ? image : defaultImage} alt={name} />
                            </div>
                        </picture>
                    </div>
                </Link>
            </div>
            {/* detail section */}
            <div className="tile-details">
                <h5 className="tile-title">
                    <Link to={url}>
                        {name}
                    </Link>
                </h5>
                {/* only display on major project pages */}
                <hr className="tile-show--mp" />
                {newsDate &&
                    <p className="tile-news-date">{newsDate}</p>
                }
                {renderAuthor()}
            </div>
        </article>
    );
});

ContentSetNews.displayName = 'ContentSetNews';

ContentSetNews.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showTile: PropTypes.bool
};
export default ContentSetNews;
