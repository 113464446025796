import Icon from './icon';
import PropTypes from 'prop-types';
import React from 'react';

const VirtualTour = React.forwardRef(({ intro, link }, ref) => {
    if (!intro || !link) {
        return null;
    }

    return (
        <div ref={ref} className="aex-virtual-tour">
            <div className="constrain-width">
                <div className="aex-virtual-tour-inner">
                    <Icon className="icon aex-virtual-tour-icon" name="360" width="27" height="27" title="" />
                    <div className="aex-virtual-tour-intro">{intro}</div>
                    <a className="button default inverse || aex-virtual-tour-cta" href={link}>
                        Take the tour
                    </a>
                </div>
            </div>
        </div>
    );
});

VirtualTour.displayName = 'VirtualTour';

VirtualTour.propTypes = {
    intro: PropTypes.string,
    link: PropTypes.string,
};

export default VirtualTour;
