/* eslint-disable jsx-a11y/label-has-associated-control */
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import Icon from 'app/components/partials/icon';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

function initDate(formikValue) {
    if (formikValue) {
        return new Date(formikValue);
    }

    return null;
}

const FilterDropdownWithDate = ({ className, filterName, filterOptions, filterLable, defaultOptionIndex, formik }) => {
    const [searchParams] = useSearchParams();
    const [openOptionList, setOpenOptionList] = useState(false);
    const [fromDate, setFromDate] = useState(initDate(formik.values['from-date']));
    const [toDate, setToDate] = useState(initDate(formik.values['to-date']));
    const [dateSelectError, setDateSelectError] = useState(null);
    const filterRef = useRef();

    const selectedOption = filterOptions.find((option) => option.value === formik.values['date-range']);

    const getDateFilterLabel = () => {
        // Display the actual date filters applied
        // instead of the internal fromDate/toDate
        if (formik.values['from-date'] && formik.values['to-date']) {
            const start = moment(formik.values['from-date'], 'YYYY-MM-DD').format('DD/MM/YYYY');
            const end = moment(formik.values['to-date'], 'YYYY-MM-DD').format('DD/MM/YYYY');
            
            return `${start} to ${end}`;
        }

        return selectedOption?.label;
    };

    const toggleDropdownHandler = () => {
        setOpenOptionList((prevValue) => !prevValue);
    };

    const filterHandler = (key, value) => {
        setDateSelectError(null);
        formik.setFieldValue(key, value);
        formik.setFieldValue('from-date', '');
        formik.setFieldValue('to-date', '');
        setFromDate(null);
        setToDate(null);
        setOpenOptionList(false);
        formik.submitForm();
    };

    // Clear date range when applying custom dates
    const handleApplyDate = (e) => {
        if (fromDate && toDate) {
            // validate custom date
            if (fromDate > toDate) {
                setDateSelectError('Start date must not be later than end date');
                e.preventDefault();
                return false;
            } else {
                setDateSelectError(null);
            }

            // set formik form values from internal state
            formik.setFieldValue('from-date', moment(fromDate).format('yyyy-MM-DD'));
            formik.setFieldValue('to-date', moment(toDate).format('yyyy-MM-DD'));
            // unset date range
            // doesn't work cause formik is reinitialized to default values but at least it's reset back to "any date"
            formik.setFieldValue('date-range', '');
            // close the form
            setOpenOptionList(false);
        } else {
            e.preventDefault();
        }
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpenOptionList(false);
        }
    };

    useEffect(() => {
        if (openOptionList === false) {
            filterRef.current.style['margin-bottom'] = 0;

            return;
        }

        const optionHeight = filterRef.current.getElementsByClassName('options')[0].clientHeight;
        const marginBottom = openOptionList ? `${optionHeight}px` : null;
        filterRef.current.style['margin-bottom'] = marginBottom;
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [openOptionList]);

    const renderOptions = (options, parameterName) => {
        const optionDoms = (
            <div className={ classnames('options', {
                'is-hidden': !openOptionList,
            })}>
                <ul>
                    {options.map((option, index) => {
                        const cls = classnames('link', {
                            selected: selectedOption === option,
                        });

                        return <li key={index}><button type="button" className={cls} onClick={() => filterHandler(parameterName, option.value)}>{option.label}</button></li>;
                    })}
                </ul>
                <div className="date-options">
                    <p className="text-regular">or select dates</p>
                    <div className="date-picker">
                        <DatePicker
                            id="from-date"
                            name="from-date"
                            className="input"
                            dateFormat="dd/MM/yyyy"
                            selected={fromDate}
                            placeholderText="dd/mm/yyyy"
                            onChange={(value) => setFromDate(value)} />
                        <label htmlFor="from-date"><Icon name="calendar" width="20" height="20" /></label>
                    </div>
                    <div className="date-picker">
                        <DatePicker
                            id="to-date"
                            name="to-date"
                            className="input"
                            dateFormat="dd/MM/yyyy"
                            selected={toDate}
                            placeholderText="dd/mm/yyyy"
                            onChange={(value) => setToDate(value)} />
                        <label htmlFor="to-date"><Icon name="calendar" width="20" height="20" /></label>
                    </div>
                    {dateSelectError &&
                        <p className="error-message">{dateSelectError}</p>
                    }
                    <button className="button primary" type="submit" onClick={handleApplyDate}>Apply Dates</button>
                </div>
            </div>
        );

        return optionDoms;
    };

    return (
        <div className={ classnames('filter-dropdown', className, openOptionList ? 'open' : '') } ref={filterRef}>
            <div className="dropdown" onClick={toggleDropdownHandler} aria-hidden="true">
                {openOptionList ? (
                    <div className="label">Select <span>{filterLable}</span></div>
                ) : (
                    <div className="label">For <span>{getDateFilterLabel()}</span></div>
                )}
                <Icon name="arrow" width="15" height="15" title="" />
            </div>
            {renderOptions(filterOptions, filterName)}
        </div>
    );
};

FilterDropdownWithDate.propTypes = {
    className: PropTypes.string.isRequired,
    filterName: PropTypes.string.isRequired,
    filterOptions: PropTypes.array.isRequired,
    filterLable: PropTypes.string.isRequired,
    defaultOptionIndex: PropTypes.number,
    formik: PropTypes.object.isRequired
};

export default FilterDropdownWithDate;
