import banner from 'static/images/error-banner.jpg';
import { buildUrl } from 'app/utilities/url';
import CategorySearch from '../../partials/category-search';
import Layout from '../layout';
import PagebreakAlgorithm from '../../content-blocks/pagebreak-algorithm';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();
    const handleSearch = (values) => {
        const keywords = values.keywords;

        if (keywords) {
            navigate(buildUrl('/s', { keywords }));
        }
    };

    return (
        <Layout noIndex withoutShare>
            <div className="error-page content-page">
                <div className="error-page-inner || constrain-width">
                    <PagebreakAlgorithm
                        file={banner}
                        background="black"
                        left={'mino\r\ns'}
                        right={'\r\numble'}
                        location="left"
                        algorithm_text="A minor stumble"
                    />
                    <CategorySearch reference="artworks" searchHandler={handleSearch} constrainWidth={false} keywordsOnly />
                    <div className="error-page-description">
                        <h2 className="heading heading-margin">PAGE NOT FOUND</h2>
                        <p>Sorry, we have recently re-organised the site and the page you were looking for appears to have been moved, deleted or does not exist. You could go back to where you were or head straight to our <Link to="/">homepage</Link>.</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default NotFound;
