import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';


const PageHeader = ({ title, image, backgroundColor, subTitle }) => {
    const containerClass = image ? 'page-header-container-image' : 'page-header-container';
    // background color always black.
    const headerClass = classnames('page-header', image ? backgroundColor ? 'page-header-black' : '' : '');
    const subTitleClass = classnames('page-header-subtitle', image && 'page-header-font-white');
    const titleClass = classnames('page-header-title', image && 'image-title');

    return (
        <div className="constrain-width">
            <div className={headerClass}>
                {image && <img className="page-header-image" src={image} alt="" />}
                <div className={containerClass}>
                    <h1 className={titleClass}>{title}</h1>
                    <h2 className={subTitleClass}>{subTitle}</h2>
                </div>
            </div>
        </div>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    backgroundColor: PropTypes.string,
    subTitle: PropTypes.string,
};

export default PageHeader;
