import { ARTIST_CHUNKS } from 'config/artist-list.js';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function sortArtists(a, b) {
    if (a.name === b.name) {
        return 0;
    }

    return a.name < b.name ? -1 : 1;
}

const ArtistList = ({ title, items }) => {
    const [bgName, setBgName] = useState('');
    const [activeTabIdx, setActiveTabIdx] = useState(0);

    const getTitle = () => {
        return title ? title : 'Artists';
    };

    const getActiveTabTitle = () => {
        return ARTIST_CHUNKS[activeTabIdx].title;
    };

    const getSortedListGroups = (items) => {
        // Sort alphabetically
        const sortedItems = [...items].sort((a, b) => {
            if (a.name === b.name) {
                return 0;
            }

            return a.name < b.name ? -1 : 1;
        });

        // Chunk into sections
        return ARTIST_CHUNKS.map((listGroup) => {
            return {
                ...listGroup,
                artists: sortedItems.filter((item) => {
                    return listGroup.indexLetters.includes(item.name.charAt(0).toUpperCase());
                }).sort(sortArtists),
            };
        });
    };

    const artistMouseOverHandler = (name) => {
        setBgName(name);
    };

    const artistMouseLeaveHandler = () => {
        setBgName('');
    };

    const renderArtistLink = (artist, idx) => {
        const link = `/explore-art-and-ideas/artist/${artist.artist_id}/${artist.slug}`;

        return <Link className="artist-list-item" key={idx} to={link} onMouseOver={() => artistMouseOverHandler(artist.name)} onMouseLeave={artistMouseLeaveHandler}>
            {artist.name}
        </Link>;
    };

    const renderDesktopListItem = (item, key) => {
        return (
            <div className="list-group" key={key}>
                <h3 className="list-group-title">{item.title}</h3>

                {item.artists.map((artist, innerIdx) => renderArtistLink(artist, innerIdx))}
            </div>
        );
    };

    const mobileTitleClickHandler = (tabIdx) => {
        setActiveTabIdx(tabIdx);
    };

    const renderMobileListTitle = (item, tabIdx) => {
        const cls = classnames('list-group-title', {
            'is-active': tabIdx === activeTabIdx,
        });

        return <h3 className={cls} key={tabIdx} onClick={() => mobileTitleClickHandler(tabIdx)}>{item.title}</h3>;
    };

    const renderMobileList = (item, tabIdx) => {
        const cls = classnames('list-group', {
            'is-active': tabIdx === activeTabIdx,
        });

        return (
            <div className={cls} key={tabIdx}>
                <div className="list-group-content">
                    {item.artists.map((artist, innerIdx) => renderArtistLink(artist, innerIdx))}
                </div>
            </div>
        );
    };

    const renderList = (items) => {
        const listGroups = getSortedListGroups(items);

        return (
            <div>
                {/* desktop view */}
                <div className="artist-list-section desktop">
                    <div className="artist-list-section-bg">
                        { bgName }
                    </div>
                    {listGroups.map((item, idx) => renderDesktopListItem(item, idx))}
                </div>
                {/* mobile view */}
                <div className="artist-list-section mobile">
                    <div className="artist-list-section-bg">
                        <p className="bg-content">{getActiveTabTitle()}</p>
                    </div>

                    <div className="artist-list-section-titles">
                        {listGroups.map((item, idx) => renderMobileListTitle(item, idx))}
                    </div>

                    {listGroups.map((item, idx) => renderMobileList(item, idx))}
                </div>
            </div>
        );
    };

    return (
        <section className="artist-list-block">
            <h3 className="block-heading">{getTitle()}</h3>
            <div className="block-content">
                {renderList(items)}
            </div>
        </section>
    );
};

ArtistList.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired
};

export default ArtistList;
