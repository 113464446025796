import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { THEME_AEX, THEME_DEFAULT } from 'app/utilities/content-blocks';

const PagebreakQuote = ({ cite, file, quote, background, blockTheme = THEME_DEFAULT, blockStyle = {} }) => {
    const getAexSectionStyle = () => {
        return blockStyle;
    };

    const renderAexBlock = () => {
        return (
            // Style according to aex theme
            <section className={`pagebreak-quote ${blockTheme}`} style={getAexSectionStyle()}>
                <img className="image" src={file} alt="" />
                <div className="pagebreak-contents">
                    <blockquote className="article-break">
                        <h3 className="quote">{quote}</h3>
                        <cite className="citation">{cite}</cite>
                    </blockquote>
                </div>
            </section>
        );
    };

    const cls = classnames('article-break', background);
    const style = {
        backgroundImage: `url('${file}')`
    };

    if (blockTheme === THEME_AEX) {
        return renderAexBlock();
    }

    return (
        <section className={`pagebreak-quote ${blockTheme}`}>
            <blockquote className={cls} style={style}>
                <h3 className="quote">{quote}</h3>
                <cite className="citation">{cite}</cite>
            </blockquote>
        </section>
    );
};

PagebreakQuote.defaultProps = {
    file: null
};

PagebreakQuote.propTypes = {
    cite: PropTypes.string.isRequired,
    file: PropTypes.string,
    quote: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    blockTheme: PropTypes.string,
    blockStyle: PropTypes.object,
};

export default PagebreakQuote;
