import PropTypes from 'prop-types';
import React from 'react';

const StacklaWidget = ({ hash, id, subtitle, title }) => {
    // All of these properties are needed for the embedded JS to work correctly
    const STACKLA_WIDGET_RESERVED_PROPERTIES = {
        className: 'stackla-widget', // This class name is used by the client side script to render the widget
        'data-alias': 'regionalfacilitiesauckland.stackla.com',
        'data-ct': '',
        'data-hash': hash,
        'data-id': id,
        'data-title': title,
        'data-ttl': '30'
    };

    return (
        <section className="stackla-widget-block || content-block ">
            {title && <h2 className="tile-block-title">{title}</h2>}
            {subtitle && <p>{subtitle}</p>}
            <hr />
            <div {...STACKLA_WIDGET_RESERVED_PROPERTIES} />
        </section>
    );
};


StacklaWidget.propTypes = {
    hash: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default StacklaWidget;
