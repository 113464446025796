import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import customUrls from './custom-urls';
import globalFields from './global-fields';
import menus from './menus';
import status from './status';
import notifications, { epics as notificationsEpic } from './notifications';
import pages, { epics as pageEpics } from './page';

export const epics = combineEpics(
    notificationsEpic,
    pageEpics
);

const createRootReducer = (routerReducer) => combineReducers({
    router: routerReducer,
    notifications,
    pages,
    status,
    menus,
    customUrls,
    globalFields,
});

export default createRootReducer;

