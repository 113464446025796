export const getDayAndMonth = () => {
    const today = new Date();
    const nzTime = today.toLocaleString('en-NZ', { timeZone: 'Pacific/Auckland' });

    const [day, month] = nzTime.split('/');

    return {
        month,
        day
    };
};

