import defaultImage from 'static/images/svgs/art-toi.svg';
import { getArtistRoute } from 'app/utilities/routes';
import Icon from 'app/components/partials/icon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const ContentSetArtwork = forwardRef(({ item, index, showTile }, ref) => {
    const getArtworkSlug = (data) => {
        return `/explore-art-and-ideas/artwork/${data.id}/${data.slug}`;
    };

    const name = item.data.name;
    const url = getArtworkSlug(item.data);
    const artists = item.data.artists || [];
    const onDisplayFlag = item.data.on_display;
    const onDisplay = 'On display';
    const notOnDisplay = 'Not on display';
    const creditLine = item.data.credit_line;
    const medium = item.data.material_desc;

    const getImage = () => {
        return item.data.image || defaultImage;
    };

    const renderArtists = () => {
        return (
            <div className="tile-artist" key={index}>
                {artists.map((artist, index) =>
                    <Link to={getArtistRoute(artist.id, artist.slug)} key={index}>{artist.display_name}</Link>
                )
                }
            </div>
        );
    };

    return (
        <article className={showTile ? 'tile tile-artwork' : 'tile-hide'} key={index} ref={ref}>
            <div>
                <Link className="tile-archive-artwork tile-thumbnail-link" to={url}>
                    <div className="tile-thumbnail || rectango">
                        <picture className="hover-area">
                            <div data-hover-text>view artwork</div>
                            {onDisplayFlag ?
                                <div data-hover-status><Icon name="ondisplay" width="15" height="15" /><p>{onDisplay}</p></div> : <div data-hover-status><p>{notOnDisplay}</p></div>}
                            <div className="image-container rectango">
                                <img className="tile-thumbnail-image" src={getImage()} alt={name} />
                            </div>
                        </picture>
                    </div>
                </Link>
            </div>
            {/* detail section */}
            <div className="tile-details">
                <h5 className="tile-title">
                    <Link to={url}>
                        {name}
                    </Link>
                </h5>
                {/* only need to display on a major project page */}
                <div className="tile-show--mp">
                    <hr />
                    {renderArtists()}
                    <p>{medium}</p>
                    <p>{creditLine}</p>
                </div>
                {/* display for normal pages but not major project */}
                <div className="tile-show--normal">
                    {renderArtists()}
                </div>
                {onDisplayFlag ?
                    <div className="tile-details-display"><Icon name="ondisplay" width="15" height="15" /><p>{onDisplay}</p></div> : ''
                }
            </div>
        </article>
    );
});

ContentSetArtwork.displayName = 'ContentSetArtwork';

ContentSetArtwork.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showTile: PropTypes.bool
};

export default ContentSetArtwork;
