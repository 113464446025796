// We only hydrate on initial page load
// There is no need for actions to update it
export const INITIAL_STATE = {};

// Actions
export const GLOBAL_FIELDS_INIT = 'rfa-aag-v3-website/global-fields/INIT';

// Action Creators
export const globalFieldsInitAction = (globalFields) => ({
    type: GLOBAL_FIELDS_INIT,
    payload: globalFields
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GLOBAL_FIELDS_INIT:
            return action.payload;
        default:
            return state;
    }
};
