import classnames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const ExploreFurtherTags = ({ tags, category, searchBy = null }) => {
    const getUrl = (tagInfo) => {
        // Use the tagInfo slugified url if searching by a custom param
        if (searchBy) {
            return `/search/${category}?${searchBy}=${tagInfo.url}`;
        }

        return `/search/${category}?keywords=${tagInfo.tag}`;
    };

    if (!tags.length) {
        return null;
    }

    const cls = classnames('explore-further-tags', searchBy);

    return (
        <ul className={cls}>
            {tags.map((tagInfo, idx) =>
                <li key={idx}>
                    <Link className="button default tiny btn-explore-further-tag" to={getUrl(tagInfo)}>{tagInfo.tag}</Link>
                </li>
            )}
        </ul>
    );
};

ExploreFurtherTags.propTypes = {
    // Tags provided by getTagInfo()
    tags: PropTypes.array.isRequired,
    // Search by the given query param (other than keywords)
    searchBy: PropTypes.string,
    // the search category (e.g. events, exhibitions, artworks)
    category: PropTypes.string.isRequired
};

export default ExploreFurtherTags;
