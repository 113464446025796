import classnames from 'classnames';
import { isAppProd } from 'config/env';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const DonationForm = ({ suggestedAmounts, productId, overlay }) => {
    const donationLink = isAppProd ? 'https://donate.aucklandartgallery.com/cart/' : 'https://aag-shop-uat.myshopify.com/cart/';
    // there are two amount setup. One for input box another for buttons
    const [donationAmount, setDonationAmount] = useState(0);
    const [donationBtnAmount, setDonationBtnAmount] = useState(0);
    const [selectedBtn, setSelectBtn] = useState();
    const [submitBtn, setSubmitBtn] = useState(true);
    const inputButtonIndex = 5;

    const clickBtn = (key, value) => {
        setSelectBtn(key);
        setDonationBtnAmount(value);
        setSubmitBtn(false);
    };
    const inputClass = classnames('button || default || donation-block-box-inner-input',
        inputButtonIndex === selectedBtn ? '|| selected' : '');
    const boxClass = overlay ? 'donation-block-box' : 'donation-block-no-overlay-box';

    const inputOnClick = () => {
        // setup background color black
        setSelectBtn(inputButtonIndex);
        // reset button selection
        setDonationBtnAmount(0);
        if (donationAmount === 0) {
            // disable submit button
            setSubmitBtn(true);
        }
    };

    const inputOnChange = (e) => {
        const value = parseFloat(e.target.value);
        if (value > 0 && !isNaN(value)) {
            // enable submit button
            setSubmitBtn(false);
            setDonationAmount(value);
        } else {
            // disable submit button and reset value
            setSubmitBtn(true);
            setDonationAmount(0);
        }
    };

    return (
        <div className={boxClass}>
            <div className="donation-block-box-inner">
                <p>Choose an amount</p>
                {suggestedAmounts.map((value, index) => {
                    if (value) {
                        const btnClass = classnames('button || default || donation-block-box-inner-btn',
                            index === selectedBtn ? '|| selected' : '');


                        return <button className={btnClass}
                            key={index} onClick={() => clickBtn(index, value)}>${value}</button>;
                    }
                })}

                <p>or enter a custom amount</p>
                <div className={inputClass}>
                    <div className="donation-block-box-inner-decorator">$</div>
                    <input className="donation-block-box-inner-textbox" type="number"
                        min="0" max="100000" step="0.1"
                        onClick={inputOnClick} onChange={(e) => inputOnChange(e)} />
                </div>
                <button className="donation-block-box-inner-btn || button || submit || primary"
                    disabled={submitBtn} onClick={() => {
                        window.location = `${donationLink}${productId}:${donationBtnAmount === 0 ? donationAmount : donationBtnAmount}`;
                    }}>Donate</button>

            </div>
        </div>
    );
};


DonationForm.propTypes = {
    suggestedAmounts: PropTypes.array.isRequired,
    productId: PropTypes.string.isRequired,
    overlay: PropTypes.bool.isRequired,
};


export default DonationForm;
