import React from 'react';
import SocialSharingBtn from './social-sharing-btn';

const SocialSharing = () => {
    return (
        <div className="constrain-width || share-page">
            <SocialSharingBtn />
        </div>
    );
};

export default SocialSharing;
