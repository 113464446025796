import canUseDom from './dom';
import { ENDPOINTS } from 'config/api';
import { getJsonStr } from 'app/utilities/format';
import { url } from 'rfa-react-core';

const ENTITY_URL_PREFIXES = {
    exhibition: '/whats-on/exhibition',
    event: '/whats-on/event',
    artist: '/explore-art-and-ideas/artist',
    artwork: '/explore-art-and-ideas/artwork',
    archive: '/explore-art-and-ideas/archives',
    // archiveItem is further resolved after detecting archive
    archiveItem: '/explore-art-and-ideas/archives',
    news: '/article'
};

const ENTITY_ENDPOINTS = {
    exhibition: ENDPOINTS.EXHIBITIONS,
    event: ENDPOINTS.EVENTS,
    page: ENDPOINTS.PAGES,
    artist: ENDPOINTS.ARTISTS,
    artwork: ENDPOINTS.ARTWORKS,
    archive: ENDPOINTS.ARCHIVES,
    archiveItem: ENDPOINTS.ARCHIVE_ITEMS,
    news: ENDPOINTS.NEWS
};

/**
 * Removed the trailing slash if there is one
 * @param {String} url The URL with or without the trailing slash.
 * @return {String} The URL without the trailing slash.
 */
const removeTrailingSlash = (url) => {
    if (url && url.endsWith('/')) {
        return url.slice(0, -1);
    }

    return url;
};

export const getSlug = (reqUrl) => {
    return url.getSlug(reqUrl);
};

export const getParent = (reqUrl) => {
    return url.getParent(reqUrl);
};

// Hacky solution to detect archive items
const isArchiveItem = (reqUrl, entity) => {
    return entity === 'archive' && reqUrl.split('/').length >= 5;
};

export const getEntityType = (reqUrl) => {
    // First see if it's an entity
    for (const [entity, prefix] of Object.entries(ENTITY_URL_PREFIXES)) {
        if (reqUrl.startsWith(prefix)) {
            if (isArchiveItem(reqUrl, entity)) {
                return 'archiveItem';
            }

            return entity;
        }
    }

    // Fallback to page (includes major project)
    return 'page';
};

export const getEndpoint = (entityType) => {
    return ENTITY_ENDPOINTS[entityType];
};

const normalizeUrl = (reqUrl) => {
    // need to remove trailing slash if we're not going through url.getApiUrl()
    let normalizedUrl = removeTrailingSlash(reqUrl);

    // URI decode slug into UTF-8 so we can transliterate accents properly
    try {
        normalizedUrl = decodeURI(normalizedUrl);
    } catch (e) {
        if (!(e instanceof URIError)) {
            throw e;
        }
    }

    // remove accents (e.g. te reo macrons)
    normalizedUrl = url.transliterate(normalizedUrl);

    return normalizedUrl;
};

const getVernonEndpoint = (reqUrl, entityType) => {
    // Vernon entities (artist, artwork, archive) passes both ID and slug
    // extract ID and slug from URL /explore-art-and-ideas/artist/:id/:slug
    // slug is optional and is not required by backend
    const suffix = reqUrl.replace(`${ENTITY_URL_PREFIXES[entityType]}/`, '');
    const parts = suffix.split('/');

    // Hack to find archive item from both archive ID and item ID
    if (entityType === 'archiveItem') {
        const archiveId = parts[0];
        const itemId = parts[2];

        return getEndpoint(entityType)(archiveId, itemId);
    }

    const [id] = suffix.split('/');

    return getEndpoint(entityType)(id);
};

export const getApiUrl = (reqUrl) => {
    if (reqUrl === '/') {
        return ENDPOINTS.PAGES('/page/auckland-art-gallery');
    }

    const entityType = getEntityType(reqUrl);
    // need to remove trailing slash if we're not going through url.getApiUrl()
    // and also remove accents (e.g. te reo macrons)
    const normalizedUrl = normalizeUrl(reqUrl);

    // Pages need the full URL instead of just slug
    switch (entityType) {
        case 'page':
            // Hack for custom whats-on page which accepts dynamic params
            if (reqUrl.startsWith('/whats-on/')) {
                return ENDPOINTS.PAGES('/visit/whats-on');
            }

            return ENDPOINTS.PAGES(normalizedUrl);
        case 'artist':
        case 'artwork':
        case 'archive':
        case 'archiveItem':
            return getVernonEndpoint(normalizedUrl, entityType);
        default:
            // Other entities just needs slug
            return url.getApiUrl(normalizedUrl, getEntityType, getEndpoint);
    }
};

export const getRouteArray = (reqUrl) => {
    return url.getRouteArray(reqUrl);
};

export const buildUrl = (path, params) => {
    const paramsString = Object.keys(params)
        .filter((param) => Boolean(params[param]))
        .map((param) => {
            return `${encodeURIComponent(param)}=${encodeURIComponent(params[param])}`;
        })
        .join('&');

    return `${path}${paramsString ? '?' : ''}${paramsString}`;
};

/**
 * `Entity` is one of the concepts from `CMS`, it looks like a `category` under every `project` inside `CMS`.
 *
 * @param {any} entityType
 */
const getEndpointArrByEntity = (entityType) => {
    const pageEnpoints = [
        {
            dataKey: 'page',
            endPoint: getEndpoint(entityType),
            apiUrl: ''
        },
        {
            dataKey: 'menus',
            endPoint: ENDPOINTS.MENUS,
            apiUrl: ''
        }
    ];

    switch (entityType) {
        default:
            return pageEnpoints;
    }
};

const getApiRequest = (reqUrl, entityTypeHandler, endpointHandler) => {
    const entityType = entityTypeHandler(reqUrl);
    const endpointArr = endpointHandler(entityType);

    if (!canUseDom()) {
        console.log('getApiRequest', 'entityType', entityType);
        console.log('getApiRequest', 'endpointArr', getJsonStr(endpointArr));
    }

    // Calulate the `apiUrl` for each endpoit
    const requestEndpoints = endpointArr.map((tempEndpoint) => {
        // `enpointArr[?].enpoint` can be `string | function`
        if (typeof tempEndpoint.endPoint === 'function') {
            return Object.assign({}, tempEndpoint, { apiUrl: getApiUrl(reqUrl) });
        }

        return Object.assign({}, tempEndpoint, { apiUrl: tempEndpoint.endPoint });
    });

    return requestEndpoints;
};

export const getApiRequestArr = (reqUrl) => {
    return getApiRequest(reqUrl, getEntityType, getEndpointArrByEntity);
};

// trim forward slashes and cast to lower case
export const normalizePath = (path) => {
    return path.replace(/^\/+/, '').toLowerCase();
};

export const isAbsoluteUrl = (url) => {
    return url.startsWith('http://') || url.startsWith('https://');
};
