import ContentSetEvent from 'app/components/partials/content-sets/content-set-event';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const WhatsOnEvent = () => {
    const [events, setEvents] = useState(null);

    const formatEventData = (entities) => {
        return entities.map((entity) => {
            return {
                data: entity
            };
        });
    };

    const fetchEvents = async() => {
        const queryString = 'date-range=future&size=4';
        const results = await fetch(`${ENDPOINTS.SEARCH('events')}?${queryString}`);
        setEvents(formatEventData(results.events.data));
    };

    useEffect(async() => {
        await fetchEvents();
    }, []);

    return (
        <section className="whats-on-event || content-set || constrain-width">
            <div className="tile-block">
                <h2 className="tile-block-title">UPCOMING EVENTS<Link className="button || default || tiny" to={'/search/events'}>VIEW ALL EVENTS</Link></h2>
                <div className="tile-block-inner">
                    {events && events.map((item, index) => {
                        return <ContentSetEvent key={index} item={item} index={index} showTile={true} />;
                    })}
                </div>
            </div>
        </section>
    );
};

WhatsOnEvent.propTypes = {
};

export default WhatsOnEvent;
