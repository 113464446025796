import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

const MultipleSelect = ({
    id,
    extraClass,
    name,
    options,
    label,
    values,
    onChange,
}) => {
    const inputClass = classnames('field-group multiple-select', extraClass);
    const displayOptions = values.reduce((pre, option) => {
        return pre ? `${pre},${option}` : option;
    }, '');
    const [openOptionList, setOpenOptionList] = useState(false);
    const inputRef = useRef();

    const toggleDropdownHandler = () => {
        setOpenOptionList((prevValue) => !prevValue);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setOpenOptionList(false);
        } else if (event.target.type === 'submit') {
            setOpenOptionList(false);
        }
    };

    const renderOptions = () => {
        return (
            <div className={classnames('options', openOptionList ? '' : 'is_hidden')}>
                {options.map((option, index) => (
                    <div key={index} className="field-group || checkbox">
                        <label>
                            <input type="checkbox" name={name} value={option.value} checked={values.includes(option.value)} onChange={onChange} />
                            <span>{option.label}</span>
                        </label>
                    </div>
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (openOptionList === false) {
            return;
        }
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [openOptionList]);

    return (
        <div className={inputClass} ref={inputRef}>
            {label && <label className="label" htmlFor={id}>{label}</label>}
            <div className="input-container">
                <div className="dropdown" onClick={toggleDropdownHandler} aria-hidden="true">
                    <div className="label">{displayOptions || 'All'}</div>
                    <div className="arrow">
                        <Icon name="arrow" width="15" height="15" title="" />
                    </div>
                </div>
                {renderOptions()}
            </div>
        </div>
    );
};

MultipleSelect.propTypes = {
    id: PropTypes.string.isRequired,
    extraClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    values: PropTypes.array,
    onChange: PropTypes.func
};

export default MultipleSelect;
