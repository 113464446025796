export const SOCIAL_LINKS = [
    {
        name: 'twitter',
        link: 'https://twitter.com/Auckartgal',
        width: '25',
        height: '25'
    },
    {
        name: 'facebook',
        link: 'https://www.facebook.com/aucklandartgallery',
        width: '20',
        height: '20'
    },
    {
        name: 'instagram',
        link: 'https://instagram.com/aucklandartgallery',
        width: '20',
        height: '20'
    },
    {
        name: 'youtube',
        link: 'https://youtube.com/aucklandartgallery',
        width: '28',
        height: '28'
    },
    {
        name: 'tripadvisor',
        link: 'https://tripadvisor.co.nz/Attraction_Review-g255106-d256912-Reviews-Auckland_Art_Gallery_Toi_o_Tamaki-Auckland_North_Island.html',
        width: '34',
        height: '34'
    },
    {
        name: 'weibo',
        link: 'http://weibo.com/aucklandartgallery',
        width: '26',
        height: '26'
    }
];
