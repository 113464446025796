import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const Input = ({
    children,
    id,
    extraClass,
    name,
    type,
    isRequired,
    inputIcon,
    small,
    medium,
    large,
    label,
    placeholder,
    value,
    onChangeHandler,
    onBlurHandler,
    readOnly,
    error
}) => {
    const inputClass = classnames('form-field', extraClass, {
        'is-required': isRequired,
        'small': small,
        'medium': medium,
        'large': large,
        'is-invalid': error
    });

    return (
        <div className={inputClass}>
            <div className="input-container">
                {label && <label className="label" htmlFor={id}>{label}</label>}
                <div className="input-wrapper">
                    <input
                        className="input"
                        type={type}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChangeHandler}
                        onBlur={onBlurHandler}
                        readOnly={readOnly}
                        required={isRequired}
                    />
                    {inputIcon && <Icon name={inputIcon} />}
                </div>
                {children}
            </div>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

Input.defaultProps = {
    type: 'text',
    extraClass: '',
    placeholder: '',
    isRequired: false,
    small: false,
    medium: false,
    large: false,
    readOnly: false,
    inputIcon: null,
    error: '',
    onChangeHandler: () => {},
    onBlurHandler: () => {}
};

Input.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    id: PropTypes.string.isRequired,
    extraClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    isRequired: PropTypes.bool,
    inputIcon: PropTypes.string,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    large: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChangeHandler: PropTypes.func,
    onBlurHandler: PropTypes.func,
    readOnly: PropTypes.bool,
    error: PropTypes.string
};

export default Input;
