import { DATE_FORMAT_MIN_WITH_YEAR } from 'config/app';
import { momentNZ } from 'app/utilities/date-time';
import PropTypes from 'prop-types';
import React from 'react';

const EventDate = ({ startDate, endDate, format = DATE_FORMAT_MIN_WITH_YEAR }) => {
    // Truncate the time portion to allow same day comparison
    const start = momentNZ(startDate).startOf('day');
    const end = momentNZ(endDate).startOf('day');

    return (
        <React.Fragment>
            <time className="date start" dateTime={start.format('YYYY-MM-DD')}>{start.format(format)}</time>
            {end && end.year() !== 1970 && !end.isSame(start) &&
                <React.Fragment>
                    &nbsp;&mdash;&nbsp;<time className="date end" dateTime={end.format('YYYY-MM-DD')}>{end.format(format)}</time>
                </React.Fragment>
            }
        </React.Fragment>
    );
};

EventDate.propTypes = {
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    format: PropTypes.string
};

export default EventDate;
