import logoSprite from 'static/images/logos/sprite.svg';
import PropTypes from 'prop-types';
import React from 'react';

const Logo = ({ name, title, viewBox = '0 0 549 122' }) => { // eslint-disable-line require-jsdoc
    return (
        <svg className="logo" viewBox={viewBox}>
            <title>{title}</title>
            <use xlinkHref={`${logoSprite}#${name}`}></use>
        </svg>
    );
};

Logo.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    viewBox: PropTypes.string
};

Logo.defaultProps = {
    title: 'Auckland Art Gallery',
};

export default Logo;
