import { getVideoIframeUrl } from 'app/utilities/video';
import PropTypes from 'prop-types';
import React from 'react';

const MinimalisticVideo = ({ title, video_url, description = '' }) => {
    return (
        <section className="minimalistic-video-block">
            <div className="block-content">
                <div className="text-section">
                    <h3 className="title">{title}</h3>
                    <p className="description">{description}</p>
                </div>
                <div className="video-section">
                    <div className="video-container">
                        <iframe
                            className="video"
                            type="text/html"
                            src={getVideoIframeUrl(video_url, { autoplay: '1' })}
                            frameBorder="0"
                            title={title}
                        ></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
};

MinimalisticVideo.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    video_url: PropTypes.string.isRequired,
};

export default MinimalisticVideo;
