/* eslint-disable camelcase */
import ArticleTitleBar from '../partials/article-title-bar';
import ContentSetArchive from '../partials/content-sets/content-set-archive';
import ContentSetArtwork from '../partials/content-sets/content-set-artwork';
import EntitySpecDetails from '../partials/entity-spec-details';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const ARTWORKS_GRID_LEN = 12;
const ARCHIVES_GRID_LEN = 12;

const Artist = ({ data }) => {
    // display name
    // Ignore notes, seems to be empty for all artists
    // spec-details
    // Tags (from DB)
    // artworks
    // artist content blocks (from DB)
    const {
        display_name,
        ethnicity,
        date_of_birth,
        place_of_birth,
        date_of_death,
        place_of_death,
        nationality,
        person_type: gender,
        bio_display,
        artworks,
        total_artworks,
        archives,
        total_archives,
    } = data;

    const renderHeader = () => {
        return (
            <header className="article-header">
                <ArticleTitleBar title={display_name} />
            </header>
        );
    };

    const getSpecDetailRows = () => {
        return [
            {
                label: 'Name',
                value: display_name,
            },
            {
                label: 'Iwi/Ethnicity',
                value: ethnicity,
            },
            {
                label: 'Date of birth',
                value: date_of_birth,
            },
            {
                label: 'Place of birth',
                value: place_of_birth,
            },
            {
                label: 'Date of death',
                value: date_of_death,
            },
            {
                label: 'Place of death',
                value: place_of_death,
            },
            {
                label: 'Nationality',
                value: nationality,
            },
            {
                label: 'Gender',
                value: gender,
            },
            {
                label: 'Biography',
                value: bio_display,
            },
        ];
    };

    const renderSpecDetails = () => {
        return <EntitySpecDetails rows={getSpecDetailRows()} />;
    };

    const renderArtworks = () => {
        return (
            <aside>
                <h2 className="heading">
                    Artworks
                    {total_artworks > ARTWORKS_GRID_LEN &&
                        <Link className="button default tiny btn-view-all" to={`/search/artworks?artist=${encodeURIComponent(display_name)}`}>View all items</Link>
                    }
                </h2>
                {renderArtworksGrid()}
            </aside>
        );
    };

    const renderArtworksGrid = () => {
        return (
            <section className="content-set">
                <div className="tile-block">
                    <div className="tile-block-inner">
                        {artworks.slice(0, ARTWORKS_GRID_LEN).map((artwork, idx) => {
                            // wrap data in format expected by ContentSetArtwork
                            const item = {
                                data: artwork
                            };

                            return <ContentSetArtwork key={idx} item={item} index={idx} showTile />;
                        })
                        }
                    </div>
                </div>
            </section>
        );
    };

    const renderArchives = () => {
        return (
            <aside>
                <h2 className="heading">
                    Archives
                    {total_archives > ARCHIVES_GRID_LEN &&
                        <Link className="button default tiny btn-view-all" to={`/search/archives?artist=${encodeURIComponent(display_name)}`}>View all items</Link>
                    }
                </h2>
                {renderArchivesGrid()}
            </aside>
        );
    };

    const renderArchivesGrid = () => {
        return (
            <section className="content-set">
                <div className="tile-block">
                    <div className="tile-block-inner">
                        {archives.slice(0, ARCHIVES_GRID_LEN).map((archive, idx) => {
                            // wrap data in format expected by ContentSetArtwork
                            const item = {
                                data: archive
                            };

                            return <ContentSetArchive key={idx} item={item} index={idx} showTile />;
                        })
                        }
                    </div>
                </div>
            </section>
        );
    };

    return (
        <div className="content-page constrain-width">
            <article className="artist-page">
                {renderHeader()}
                {renderSpecDetails()}
                {/* TODO: tags? don't think it was working in old AAG */}
                {total_artworks > 0 && renderArtworks()}
                {total_archives > 0 && renderArchives()}
                {/* TODO: content blocks? currently broken in CMS and no artists in prod actually have content blocks */}
            </article>
        </div>
    );
};

Artist.propTypes = {
    data: PropTypes.shape({
        display_name: PropTypes.string,
        ethnicity: PropTypes.string,
        date_of_birth: PropTypes.string,
        place_of_birth: PropTypes.string,
        date_of_death: PropTypes.string,
        place_of_death: PropTypes.string,
        nationality: PropTypes.string,
        person_type: PropTypes.string,
        bio_display: PropTypes.string,
        artworks: PropTypes.array,
        total_artworks: PropTypes.number,
        archives: PropTypes.array,
        total_archives: PropTypes.number,
    })
};

export default Artist;
