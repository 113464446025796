import classnames from 'classnames';
import { getOpeningHoursMessage } from 'app/utilities/opening-hours';
import Icon from 'app/components/partials/icon';
import { isAbsoluteUrl } from '../../utilities/url';
import PropTypes from 'prop-types';
import React from 'react';
import SocialSharingBtn from './social-sharing-btn';
import { useSelector } from 'react-redux';
import { NavLink, Link } from 'react-router-dom'; // eslint-disable-line

const Navigation = ({ isActive, itemOpen, toggleActive, updateOpenItem }) => {
    const menuItems = useSelector((state) => state.menus.items);

    const mainMenuItems = menuItems.find(({ title }) => title === 'main');

    // Handlers
    const handleToggleChildNavigation = (index) => updateOpenItem(itemOpen === index ? null : index);

    // Render
    const renderSubNavTitle = (title) => {
        let text;

        switch (title.toLowerCase()) {
            case 'visit':
                text = 'Planning your visit';
                break;
            case 'art & ideas':
                text = 'Explore our collection';
                break;
            case 'about':
                text = 'Find out more about us';
                break;
            case 'support Us':
                text = 'Ways to support the gallery';
                break;
            default:
                return;
        }

        return text ? <h4 className="sub-navigation-title">{text}</h4> : null;
    };

    const renderLink = (title, url, type, key) => {
        const baseCls = classnames('sub-navigation-item', type);

        if (isAbsoluteUrl(url)) {
            return <a href={url} className={baseCls} key={key}><Icon name="chevron" />{title}</a>;
        }

        return (
            <NavLink to={url} end className={({ isActive }) => classnames(baseCls, { 'is-active': isActive })} key={key}>
                <Icon name="chevron" />{title}
            </NavLink>
        );
    };

    const renderNavigationItem = ({ children, id, title }, index) => {
        const itemClass = classnames('navigation-item', {
            'is-active': itemOpen === index
        });

        return (
            <div className={itemClass} key={id}>
                <div className="navigation-link desktop">
                    <h5 className="label">{title}</h5>
                </div>
                <button className="navigation-link mobile" onClick={() => handleToggleChildNavigation(index)}>
                    <h5 className="label">{title}</h5>
                </button>
                {children &&
                    <div className="sub-navigation">
                        <button className="sub-navigation-controls" onClick={() => updateOpenItem(null)}>
                            <Icon name="long-arrow" width="20" height="20" />
                            <h5 className="current-item">{title}</h5>
                        </button>
                        {renderSubNavTitle(title)}
                        <nav className="sub-navigation-inner">
                            {children.map(({ title, url, children }, subIndex) => {
                                return (
                                    <div className="sub-navigation-section" key={`${index}-${subIndex}`}>
                                        {renderLink(title, url, 'primary')}
                                        {children.map(({ title, url }, subChildIndex) => (
                                            renderLink(title, url, 'secondary', `${index}-${subIndex}-${subChildIndex}`)
                                        ))}
                                    </div>
                                );
                            })}
                        </nav>
                    </div>}
            </div>
        );
    };

    const renderPrimaryNavigation = () => (
        <nav className={`navigation ${itemOpen === null ? '' : 'secondary-nav-active'}`}>
            {mainMenuItems.children.map((item, index) => {
                return renderNavigationItem(item, index);
            })}
            <div className="navigation-item">
                <a className="navigation-link" href="https://shop.aucklandartgallery.com/">
                    <h5 className="label">Shop</h5>
                </a>
            </div>
            <div className="navigation-item desktop-only">
                <Link className="navigation-link primary" to="/s">
                    <Icon name="search" width="25" height="25" />
                </Link>
            </div>
        </nav>
    );

    const renderSecondaryNavigation = () => (
        <div className="navigation-secondary">
            <NavLink className="navigation-item" to="/visit/visitor-information">{getOpeningHoursMessage()}</NavLink>
            <a className="navigation-item" href="https://www.google.com/maps/search/?api=1&query=Auckland+Art+Gallery+Toi+o+T%C4%81maki&query_place_id=ChIJbUgSLvtHDW0RFXKLUva-kD4" target="_blank" rel="noreferrer">
                Cnr Kitchener and Wellesley Streets
            </a>
            <NavLink className="navigation-item" to="/about/contact-us" end>
                Contact us
            </NavLink>
        </div>
    );

    const navigationToggleButton = classnames('navigation-toggle || icon-button || button default', {
        'is-active': isActive
    });

    const hamburgerIcon = classnames('icon-button-icon || hamburger-icon', {
        'is-active': isActive
    });

    return (
        <>
            <div className="navigation-bar">
                <div className="navigation-actions">
                    <button className={navigationToggleButton} onClick={toggleActive}>
                        <span className="icon-button-inner">
                            <span className={hamburgerIcon}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </span>
                    </button>
                    <Link className="icon-button || button default" to="/s">
                        <Icon name="search" width="22" height="22" />
                    </Link>
                    <SocialSharingBtn popupDir="right" iconOnly={true} />
                </div>
                <div className="navigation-bar-inner">
                    {renderPrimaryNavigation()}
                    {renderSecondaryNavigation()}
                </div>
            </div>
        </>
    );
};

Navigation.propTypes = {
    isActive: PropTypes.bool.isRequired,
    itemOpen: PropTypes.number, // Not required so we can pass null
    toggleActive: PropTypes.func.isRequired,
    updateOpenItem: PropTypes.func.isRequired
};

export default Navigation;
