/* eslint-disable require-jsdoc */
import { fireDelayedHistoryChange } from 'app/utilities/gtm';
import { getPageDataAction } from 'app/ducks/page';
import { isFrontendRoute } from 'app/utilities/routes';
import selectPage from 'app/selectors/page';
import { startedRoutingAction } from 'app/ducks/status';
import { isAbsoluteUrl, normalizePath } from 'app/utilities/url';
import { LOCATION_CHANGE, replace } from 'redux-first-history';

// Handle custom URL redirects before fetch the page data from API
function resolveRedirect(customUrls, action) {
    const { payload } = action;

    const path = normalizePath(payload.location.pathname);

    const redirectTo = customUrls[path];
    if (redirectTo) {
        // support absolute path redirects
        if (isAbsoluteUrl(redirectTo)) {
            window.location.replace(redirectTo);
        }

        return replace(redirectTo);
    }

    return null;
}

function createPreRouteMiddleware() {
    return ({ dispatch, getState }) => (next) => (action) => {
        if (action.type === LOCATION_CHANGE) {
            const state = getState();
            // Check if we need to do redirects
            const redirectAction = resolveRedirect(state.customUrls, action);
            if (redirectAction) {
                dispatch([redirectAction]);

                return;
            }

            const { payload } = action;
            const location = payload && payload.location ? payload.location : { pathname: '' };
            const pathname = location.pathname;
            const page = selectPage(state, pathname);

            if (page || isFrontendRoute(pathname)) {
                next(action);

                // On every route change we fire the GTM event 'delayedHistoryChange'.
                fireDelayedHistoryChange();
            } else {
                dispatch([
                    startedRoutingAction(),
                    getPageDataAction(location)
                ]);
            }
        } else {
            next(action);
        }
    };
}

const preRouteMiddleware = createPreRouteMiddleware();

export default preRouteMiddleware;
