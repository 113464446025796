/* eslint-disable camelcase */

import artToi from 'static/images/svgs/art-toi.svg';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const FileDownload = ({ display_name, file, filename, filesize, extension, preview_image }) => {
    const getFileSize = (bytes, extension) => {
        const BYTE_BASE = 1024;
        const DECIMAL_PLACES = 2;
        const KILO_BYTE = 1024;
        const MEGA_BYTE = KILO_BYTE * BYTE_BASE;
        const GIGA_BYTE = MEGA_BYTE * BYTE_BASE;
        const extensionText = `${extension.toUpperCase()} file`;

        if ((bytes >= KILO_BYTE) && (bytes < MEGA_BYTE)) {
            return `${(bytes / KILO_BYTE).toFixed(DECIMAL_PLACES)} KB ${extensionText}`;
        } else if ((bytes >= MEGA_BYTE) && (bytes < GIGA_BYTE)) {
            return `${(bytes / MEGA_BYTE).toFixed(DECIMAL_PLACES)} MB ${extensionText}`;
        }

        return `${bytes} B ${extensionText}`;
    };

    // Hack: if there's no filesize it means the image has been deleted from CMS
    // Don't display it
    if (!filesize) {
        return null;
    }

    return (
        <section className="section || file-download-block">
            <div className="file-download">
                <div className="new-file-attachment">
                    <a className="download-icon" href={ file } target="_blank" download={ filename } rel="noreferrer">
                        <Icon name="download" width="20" height="20" />
                    </a>
                    <img className="preview-image" src={preview_image || artToi } alt="Preview" />
                    <a className="small default button" href={ file } target="_blank" download={ filename } rel="noreferrer">Download</a>
                </div>

                <div className="detail">
                    <div className="heading">{ display_name || filename }</div>
                    <div className="size">{ getFileSize(filesize, extension) }</div>
                </div>
            </div>
        </section>
    );
};

FileDownload.propTypes = {
    display_name: PropTypes.string,
    extension: PropTypes.string,
    file: PropTypes.string,
    filename: PropTypes.string,
    filesize: PropTypes.string,
    preview_image: PropTypes.string,
};

export default FileDownload;
