/* eslint-disable camelcase */
import ArticleTitleBar from '../partials/article-title-bar';
import ArtistNames from '../partials/artist-names';
import Carousel from '../content-blocks/carousel';
import ContentSetArchiveItems from '../partials/content-set-archive-items';
import EntitySpecDetails from '../partials/entity-spec-details';
import ExploreFurtherTags from '../partials/explore-further-tags';
import { getTagInfo } from 'app/utilities/entity-tags';
import Image from '../content-blocks/image';
import PropTypes from 'prop-types';
import React from 'react';
import { renderVernonField } from '../../utilities/format-content';

const Archive = ({ data }) => {
    const {
        id,
        name,
        zoom_images,
        brief_desc,
        artists,
        production_date,
        linear_meters,
        finding_aids,
        credit_line,
        accession_no,
        restriction,
        copyright,
        collection,
        vernon_tags,
        vernon_tags_names,
        // first few items in the collection
        items,
    } = data;

    const renderHeader = () => {
        return (
            <header className="article-header">
                <ArticleTitleBar title={name} />
            </header>
        );
    };

    const renderImageBanner = () => {
        if (zoom_images.length > 1) {
            return <Carousel
                isHero
                items={zoom_images.map((image) => ({ file: image, title: name }))}
            />;
        }

        return <Image file={zoom_images[0]} hero creditline={name} />;
    };

    const renderArtistNames = (withRole = false) => {
        return <ArtistNames artists={artists} withRole={withRole} />;
    };

    const getSpecDetailRows = () => {
        return [
            {
                label: 'Creator',
                value: renderArtistNames(),
            },
            {
                label: 'Title',
                value: name,
            },
            {
                label: 'Date range',
                value: production_date,
            },
            {
                label: 'Linear meters',
                value: linear_meters,
            },
            {
                label: 'Finding aids',
                value: finding_aids,
            },
            {
                label: 'Credit line',
                value: credit_line,
            },
            {
                label: 'Accession no',
                value: accession_no,
            },
            {
                label: 'Restriction type',
                value: restriction,
            },
            {
                label: 'Copyright',
                value: copyright,
            },
            {
                label: 'Collection name',
                value: collection,
            },
        ];
    };

    const renderArchiveDetails = () => {
        return (
            <section className="archive-details">
                <h2 className="heading">Archive Collection Detail</h2>

                <div className="archive-details-body">
                    <div className="archive-description">
                        {renderVernonField(brief_desc)}
                        <EntitySpecDetails rows={getSpecDetailRows()} />
                    </div>

                    {vernon_tags && vernon_tags.length &&
                        <div className="archive-tags">
                            <h4 className="heading heading-margin">Explore Further</h4>
                            <ExploreFurtherTags tags={getTagInfo(vernon_tags, vernon_tags_names)} category="artworks" />
                        </div>
                    }
                </div>
            </section>
        );
    };

    const renderItems = () => {
        if (!items || !items.length) {
            return null;
        }

        return (
            <div>
                <h2 className="heading">Items In This Collection</h2>
                <ContentSetArchiveItems items={items} archiveId={id} />
            </div>
        );
    };

    return (
        <div className="content-page constrain-width">
            <article className="archive-page">
                {renderHeader()}
                {renderImageBanner()}
                {renderArchiveDetails()}
                {renderItems()}
                {/* TODO: content blocks? currently broken in old AAG */}
            </article>
        </div>
    );
};

Archive.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        zoom_images: PropTypes.array,
        brief_desc: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ]),
        artists: PropTypes.array,
        production_date: PropTypes.string,
        linear_meters: PropTypes.string,
        finding_aids: PropTypes.string,
        credit_line: PropTypes.string,
        accession_no: PropTypes.string,
        restriction: PropTypes.string,
        copyright: PropTypes.string,
        collection: PropTypes.string,
        vernon_tags: PropTypes.array,
        vernon_tags_names: PropTypes.array,
        // items in the collection
        items: PropTypes.array,
    })
};

export default Archive;
