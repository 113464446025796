/* eslint-disable camelcase */
import { DOWN_TOLERANCE } from 'config/headroom';
import { fetchNotificationsAction } from 'app/ducks/notifications';
import Headroom from 'react-headroom';
import Logo from 'app/components/partials/logo';
import Navigation from 'app/components/partials/navigation';
import NotificationBanner from 'app/components/partials/notification-banner';
import updateBody from 'app/utilities/update-body';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Header = () => {
    const [isNavigationActive, setIsNavigationActive] = useState(false);
    const [navigationItemOpen, setNavigationItemOpen] = useState(null);

    const { isActive: isNotificationsActive } = useSelector((state) => state.notifications);

    const location = useLocation();
    const dispatch = useDispatch();

    const toggleNavigation = () => setIsNavigationActive(!isNavigationActive);

    const resetNavigation = () => {
        setIsNavigationActive(false);
        setNavigationItemOpen(null);
    };

    useEffect(() => {
        const baseEle = document.getElementsByClassName('base')[0];

        updateBody(isNavigationActive);

        if (isNavigationActive) {
            baseEle.classList.add('is-navigation-active');
        } else {
            baseEle.classList.remove('is-navigation-active');
        }
    }, [isNavigationActive]);

    useEffect(() => {
        resetNavigation();
    }, [location]);

    useEffect(() => {
        dispatch(fetchNotificationsAction());
        window.addEventListener('resize', resetNavigation, true);

        return window.removeEventListener('resize', resetNavigation);
    }, []);

    return (
        <Headroom className="top-headroom"
            downTolerance={DOWN_TOLERANCE} // Scroll tolerance in px when scrolling down before component is pinned
            disableInlineStyles={true} // Relies on page.scss styling
        >
            {isNotificationsActive && <NotificationBanner />}
            <header className="header">
                <div className="header-inner || constrain-width">
                    <Link className="header-logo" to="/">
                        <Logo name="aag-logo" title="Auckland Art Gallery" />
                    </Link>
                    <Navigation
                        isActive={isNavigationActive}
                        itemOpen={navigationItemOpen}
                        toggleActive={toggleNavigation}
                        updateOpenItem={setNavigationItemOpen}
                    />
                </div>
            </header>
        </Headroom>
    );
};

export default Header;
