import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import { THEME_DEFAULT } from 'app/utilities/content-blocks';

const SliderArrow = (props) => {
    const { className, style, onClick } = props;
    const display = onClick === null ? 'none' : 'block';

    return (
        <button className={className} style={{ ...style, display: display }} onClick={onClick}>
            <Icon name="arrow" width="25" height="25" />
        </button>
    );
};

SliderArrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
};

const Gallery = ({ blockTheme = THEME_DEFAULT, items }) => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SliderArrow />,
        prevArrow: <SliderArrow />,
        useTransform: false,
        variableWidth: true
    };

    return (
        <section className={`section || gallery-block || ${blockTheme} `}>
            <div className="gallery-block-inner">
                <Slider className="image-carousel" {...settings}>
                    {items.map(({ file, creditline }, index) => (
                        <div className="slider-item" key={index}>
                            <div className="image-container">
                                <img src={file} alt={creditline} />
                            </div>
                            <div className="content-container">
                                <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: formatContent(creditline) }}></div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

Gallery.propTypes = {
    blockTheme: PropTypes.string,
    items: PropTypes.array.isRequired
};

export default Gallery;
