import PropTypes from 'prop-types';
import React from 'react';
import SocialSharingBtn from './social-sharing-btn';

const ArticleTitleBar = ({ title }) => {
    return (
        <div className="article-title-bar">
            <h1>{title}</h1>
            <SocialSharingBtn popupDir="left" />
        </div>
    );
};

ArticleTitleBar.propTypes = {
    title: PropTypes.string.isRequired,
};

export default ArticleTitleBar;
