/*
    Routable entites are a map of the entites and their template

    Defined Attributes:
    - 'custom'          = is used to override the default template
    - 'default'         = fallback template, per entity and global
 */
export const CMS_ENTITIES = {
    pages: 'pages'
};

export const ROUTABLE_ENTITIES = {
    pages: {
        default: 'page',
        // Custom page slugs
        custom: [
            {
                slug: 'auckland-art-gallery',
                uri: '/page/auckland-art-gallery',
                template: 'home'
            },
            {
                slug: 'art-ideas',
                uri: '/art-ideas/explore-art-and-ideas',
                template: 'artIdeas'
            },
            {
                slug: 'whats-on',
                uri: '/visit/whats-on',
                template: 'whatsOn'
            },
            {
                slug: 'early-childhood',
                uri: '/visit/education-groups/early-childhood',
                template: 'educationGroup'
            },
            {
                slug: 'primary',
                uri: '/visit/education-groups/primary',
                template: 'educationGroup'
            },
            {
                slug: 'secondary',
                uri: '/visit/education-groups/secondary',
                template: 'educationGroup'
            },
            {
                slug: 'tertiary',
                uri: '/visit/education-groups/tertiary',
                template: 'educationGroup'
            },
        ]
    },
    // eslint-disable-next-line camelcase
    major_projects: {
        default: 'majorProject',
    },
    exhibitions: {
        default: 'exhibition',
    },
    artists: {
        default: 'artist',
    },
    artworks: {
        default: 'artwork',
    },
    archives: {
        default: 'archive',
    },
    'archive-items': {
        default: 'archiveItem',
    },
    news: {
        default: 'news',
    },
    events: {
        default: 'event',
    }
};

export const DEFAULT_TEMPLATE = ROUTABLE_ENTITIES.pages.default;


/**
 * Returns the template type using the entity slug and type. A template type is
 * is used to define which React template to render – see
 * app/components/templates/index.jsx
 *
 * @param  {string} slug entity slug
 * @param  {string} type entity type
 * @param  {string} uri entity uri
 * @return {string} templateType
 */
export function templateType(slug, type, uri) {
    // Check if type is in ROUTABLE_ENTITIES
    if (type in ROUTABLE_ENTITIES) {
        // If custom route used for entity return that
        if (
            ROUTABLE_ENTITIES[type].custom &&
            ROUTABLE_ENTITIES[type].custom.find((item) => item.slug === slug && item.uri === uri)
        ) {
            // Return the custom slug
            return ROUTABLE_ENTITIES[type].custom.find((item) => item.slug === slug && item.uri === uri).template;
        }

        // Return default if not
        return ROUTABLE_ENTITIES[type].default;
    }

    return DEFAULT_TEMPLATE;
}

export default templateType;
