import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const ZOOM = 3;
const GLASS_WIDTH = 120;
const GLASS_HEIGHT = 120;

const HeroArtwork = ({ title, details, text_color = '#ffffff', background_color, image }) => {
    const imgRef = useRef(null);

    const [magnifierStyle, setMagnifierStyle] = useState({});

    const initMagnifier = () => {
        const img = imgRef.current;

        // magnifiying glass background size is based on image dimensions
        // so we can only init the glass style after the image has been loaded
        setMagnifierStyle({
            ...magnifierStyle,
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: `${img.width * ZOOM}px ${img.height * ZOOM}px`,
            /* Set the size of the magnifier glass: */
            width: `${GLASS_WIDTH}px`,
            height: `${GLASS_HEIGHT}px`,
        });
    };

    const showMagnifier = (e) => {
        e.preventDefault();
        setMagnifierStyle({
            ...magnifierStyle,
            visibility: 'visible',
        });
    };

    const moveMagnifier = (e) => {
        const img = imgRef.current;
        const w = GLASS_WIDTH / 2;
        const h = GLASS_HEIGHT / 2;
        const zoom = ZOOM;
        const bw = 3;
        let x, y;
        /* prevent any other actions that may occur when moving over the image */
        e.preventDefault();
        /* get the cursor's x and y positions: */
        const pos = getCursorPos(e);
        x = pos.x;
        y = pos.y;
        /* prevent the magnifier glass from being positioned outside the image: */
        if (x > img.width - (w / zoom)) { x = img.width - (w / zoom); }
        if (x < w / zoom) { x = w / zoom; }
        if (y > img.height - (h / zoom)) { y = img.height - (h / zoom); }
        if (y < h / zoom) { y = h / zoom; }
        setMagnifierStyle({
            ...magnifierStyle,
            /* set the position of the magnifier glass: */
            left: `${x - w}px`,
            top: `${y - h}px`,
            /* display what the magnifier glass "sees": */
            backgroundPosition: `-${(x * zoom) - w + bw}px -${(y * zoom) - h + bw}px`,
        });
    };

    const getCursorPos = (e) => {
        let x = 0,
            y = 0;
        // eslint-disable-next-line no-param-reassign
        e = e || window.event;
        /* get the x and y positions of the image: */
        const a = imgRef.current.getBoundingClientRect();
        /* calculate the cursor's x and y coordinates, relative to the image: */
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /* consider any page scrolling: */
        x -= window.pageXOffset;
        y -= window.pageYOffset;

        return { x, y };
    };

    useEffect(() => {
        // img onLoad doesn't trigger when the image is fetched from cache
        // so we make sure to always init magnifier here
        // it's OK to call the method twice because it's just setting state
        if (imgRef.current && imgRef.current.complete) {
            initMagnifier();
        }
    }, []);

    return (
        <section className="hero-artwork-block full-width-block" style={{ backgroundColor: background_color }}>
            <div className="block-content">
                <div className="text-section" style={{ color: text_color }}>
                    <p className="title">{title}</p>
                    <hr />
                    <p>{details}</p>
                </div>
                <div className="image-section">
                    <div className="img-magnifier-glass"
                        style={magnifierStyle}
                        onMouseMove={moveMagnifier}
                        onTouchMove={moveMagnifier}
                    ></div>
                    <img src={image} alt="artwork"
                        ref={imgRef}
                        onLoad={initMagnifier}
                        onMouseOver={showMagnifier}
                        onMouseMove={moveMagnifier}
                        onTouchMove={moveMagnifier}
                    />
                </div>
            </div>
        </section>
    );
};

HeroArtwork.propTypes = {
    title: PropTypes.string.isRequired,
    details: PropTypes.string,
    text_color: PropTypes.string.isRequired,
    background_color: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default HeroArtwork;
