import AdvancedExhibition from '../partials/advanced-exhibition';
import ArticleTitleBar from '../partials/article-title-bar';
import Carousel from '../content-blocks/carousel';
import ContentBlocks from 'app/components/content-blocks/index';
import { DATE_FORMAT_EVENT_PAGE } from 'config/app';
import EventDate from '../partials/event-date';
import ExploreFurtherTags from '../partials/explore-further-tags';
import { getTagInfo } from 'app/utilities/entity-tags';
import Image from '../content-blocks/image';
import PropTypes from 'prop-types';
import React from 'react';
import Sponsor from '../content-blocks/sponsor';

const Event = ({ type = 'event', data }) => {
    const {
        name,
        description,
        main_images: mainImages,
        start_date: startDate,
        end_date: endDate,
        time_summary: timeSummary,
        date_info: dateInfo,
        tag_names: tagNames,
        event_group: eventGroupTags,
        event_type: eventTypeTags,
        curator,
        location,
        cost,
        booking_link: bookingLink,
        booking_link_text: bookingLinkText,
        blocks,
        sponsors,
        is_advanced_exhibition: isAex = 'No'
    } = data;

    const eventTypes = getTagInfo(eventTypeTags, tagNames);
    const eventGroups = getTagInfo(eventGroupTags, tagNames);
    // the frontend URL for search via tags is the plural version of event type (e.g. events, exhibitions)
    const tagCategory = `${type}s`;

    const renderHeader = (name, startDate, endDate, timeSummary) => {
        return (
            <header className="article-header">
                <ArticleTitleBar title={name} />
                <h6 className="dates">
                    <EventDate startDate={startDate} endDate={endDate} format={DATE_FORMAT_EVENT_PAGE} />
                    {timeSummary && ` ${timeSummary}`}
                </h6>
                {/* event type tags are only for events, not exhibitions */}
                {type === 'event' &&
                    <div>
                        <ExploreFurtherTags tags={eventTypes} searchBy="event-type" category="events" />
                    </div>
                }
            </header>
        );
    };

    const renderHeroCarousel = (images) => {
        if (images.length > 1) {
            return <Carousel
                isHero
                items={images.map((image) => ({ file: image, title: name }))}
            />;
        }

        return <Image file={images[0]} hero />;
    };

    const renderEventDetails = () => {
        return (
            <section className="event-details">
                <h2 className="heading">{type} Details</h2>
                <div className="event-details-body">
                    <div className="event-description">
                        <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: description }}></div>
                        {/* TODO: turn spec-details into component? it's used by other entity detail pages */}
                        <div className="spec-details">
                            <dl>
                                <dt>Date</dt>
                                <dd>
                                    <EventDate startDate={startDate} endDate={endDate} format={DATE_FORMAT_EVENT_PAGE} />
                                    <div className='rich-editor-content' dangerouslySetInnerHTML={{ __html: dateInfo }}></div>
                                </dd>

                                {curator &&
                                    <React.Fragment>
                                        <dt>Curated by</dt>
                                        <dd>{curator}</dd>
                                    </React.Fragment>
                                }

                                {location &&
                                    <React.Fragment>
                                        <dt>Location</dt>
                                        <dd>{location}</dd>
                                    </React.Fragment>
                                }

                                {/* TODO: I think this is always null, check */}
                                {/* @if ($event->type)
                                    <dt>Event Type</dt>
                                    <dd>{{ $event->type }}</dd>
                                @endif */}

                                {cost &&
                                    <React.Fragment>
                                        <dt>Cost</dt>
                                        <dd>{cost}</dd>
                                    </React.Fragment>
                                }
                            </dl>

                            {bookingLink &&
                                <a href={bookingLink} target="_blank" className="button primary center" rel="noreferrer">{bookingLinkText || 'Buy Now' }</a>
                            }
                        </div>
                    </div>

                    {eventGroups.length > 0 &&
                    <div className="event-group-tags">
                        <h4 className="heading heading-margin">Explore Further</h4>
                        <ExploreFurtherTags tags={eventGroups} category={tagCategory} />
                    </div>
                    }

                </div>
            </section>
        );
    };

    const renderContentBlocks = () => {
        return <ContentBlocks data={blocks} />;
    };

    const renderSponsors = () => {
        if (!sponsors.length) {
            return null;
        }

        // convert data format
        const sponsorData = sponsors.map((sponsor) => {
            return {
                ...sponsor,
                logo: sponsor.logoPath
            };
        });

        return (
            <aside>
                <h2 className="heading">Supported By</h2>
                {/* event sponsor block has different styles to default sponsor content block */}
                <Sponsor items={sponsorData} blockTheme="event" />
            </aside>
        );
    };

    // Advanced exhibition renders a different view
    if (isAex === 'Yes') {
        return <AdvancedExhibition data={data} />;
    }

    return (
        <div className="content-page constrain-width">
            <article className="event-page">
                {renderHeader(name, startDate, endDate, timeSummary)}
                {renderHeroCarousel(mainImages)}
                {renderEventDetails()}
                {renderContentBlocks()}
                {renderSponsors()}
            </article>
        </div>
    );
};

Event.propTypes = {
    type: PropTypes.string,
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        main_images: PropTypes.array,
        start_date: PropTypes.number,
        end_date: PropTypes.number,
        time_summary: PropTypes.string,
        date_info: PropTypes.string,
        tag_names: PropTypes.array,
        event_group: PropTypes.array,
        event_type: PropTypes.array,
        curator: PropTypes.string,
        location: PropTypes.string,
        cost: PropTypes.string,
        booking_link: PropTypes.string,
        booking_link_text: PropTypes.string,
        blocks: PropTypes.array,
        sponsors: PropTypes.array,
        is_advanced_exhibition: PropTypes.string,
    })
};

export default Event;
