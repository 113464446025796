/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const Jotform = ({ data }) => {
    const handleIFrameMessage = (e) => {
        if (typeof e.data === 'object') {
            return;
        }
        const args = e.data.split(':');
        let iframe;
        if (args.length > 2) {
            iframe = document.getElementById(`JotFormIFrame-${args[(args.length - 1)]}`);
        } else {
            iframe = document.getElementById('JotFormIFrame');
        }
        if (!iframe) {
            return;
        }
        switch (args[0]) {
            case 'scrollIntoView':
                iframe.scrollIntoView();
                break;
            case 'setHeight':
                iframe.style.height = `${args[1]}px`;
                break;
            case 'collapseErrorPage':
                if (iframe.clientHeight > window.innerHeight) {
                    iframe.style.height = `${window.innerHeight}px`;
                }
                break;
            case 'reloadPage':
                window.location.reload();
                break;
            case 'loadScript': // eslint-disable-line no-case-declarations
                let src = args[1];
                if (args.length > 3) { // eslint-disable-line no-magic-numbers
                    src = args[1] + ':' + args[2]; // eslint-disable-line prefer-template
                }
                const script = document.createElement('script');
                script.src = src;
                script.type = 'text/javascript';
                document.body.appendChild(script);
                break;
            case 'exitFullscreen':
                if (window.document.exitFullscreen)
                    window.document.exitFullscreen();
                else if (window.document.mozCancelFullScreen)
                    window.document.mozCancelFullScreen();
                else if (window.document.mozCancelFullscreen)
                    window.document.mozCancelFullScreen();
                else if (window.document.webkitExitFullscreen)
                    window.document.webkitExitFullscreen();
                else if (window.document.msExitFullscreen)
                    window.document.msExitFullscreen();
                break;
            default:
                break;
        }
        const isJotForm = (e.origin.indexOf('jotform') > -1);
        if (isJotForm && 'contentWindow' in iframe && 'postMessage' in iframe.contentWindow) {
            const urls = {
                docurl: encodeURIComponent(document.URL),
                referrer: encodeURIComponent(document.referrer)
            };
            iframe.contentWindow.postMessage(JSON.stringify({
                type: 'urls',
                value: urls
            }), '*');
        }
    };

    useEffect(() => {
        const ifr = document.getElementById(`JotFormIFrame-${data.form_id}`);
        if (window.location.href && window.location.href.indexOf('?') > -1) {
            const get = window.location.href.substr(window.location.href.indexOf('?') + 1);
            if (ifr && get.length > 0) {
                let src = ifr.src;
                src = src.indexOf('?') > -1 ? `${src}&${get}` : `${src}?${get}`;
                ifr.src = src;
            }
        }

        if (window.addEventListener) {
            window.addEventListener('message', handleIFrameMessage, false);
        } else if (window.attachEvent) {
            window.attachEvent('onmessage', handleIFrameMessage);
        }
    }, []);

    return (
        <section className="section">
            <iframe
                id={`JotFormIFrame-${data.form_id}`}
                title="Auckland Art Gallery"
                allowtransparency="true"
                allowFullScreen
                allow="geolocation; microphone; camera"
                src={`https://aucklandunlimited.jotform.com/${data.form_id}`}
                frameBorder="0"
                style={{
                    width: 1,
                    minWidth: '100%',
                    height: 539,
                    border: 'none'
                }}
                scrolling="no"
            />
        </section>
    );
};

Jotform.propTypes = {
    data: PropTypes.shape({
        form_id: PropTypes.string.isRequired
    })
};

export default Jotform;
