import * as Yup from 'yup';
import { ERROR_MESSAGE } from 'config/api';
import Input from 'app/components/partials/input';
import { Link } from 'react-router-dom';
import Modal from 'app/components/partials/modal';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

const NewsLetterModal = ({ modalStatus, modalHander, emailValue }) => {
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const formRef = useRef(null);
    const grecaptchaNodeRef = useRef(null);
    useEffect(() => {
        if (modalStatus && grecaptchaNodeRef && grecaptchaNodeRef.current && grecaptchaNodeRef.current.childNodes.length === 0) {
            grecaptcha.render( // eslint-disable-line
                grecaptchaNodeRef.current,
                {
                    sitekey: '6LcwIw8TAAAAACP1ysM08EhCgzd6q5JAOUR1a0Go',
                    callback: (token) => setRecaptchaToken(token),
                    'expired-callback': () => setRecaptchaToken(),
                },
                false
            );
        }
    }, [modalStatus, grecaptchaNodeRef]);

    const submitSuccessfullyHandler = () => {
        formik.resetForm();
        modalHander(false);
    };

    const renderSubmitSuccessfully = () => (
        <div>
            <h2>THANKS FOR SIGNING UP</h2>
            <p>We’ll be sharing the latest news from the Gallery and our online shop. You can change your email preferences at any time by following the link at the bottom of our newsletters.</p>
            <button className="button primary wide" onClick={submitSuccessfullyHandler}>OK</button>
        </div>
    );

    const formik = useFormik({
        initialValues: {
            email: emailValue,
            emailconfirm: '',
            firstname: '',
            lastname: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Not a valid email address'),
            emailconfirm: Yup.string().oneOf([Yup.ref('email'), null], 'Emails don\'t match'),
            firstname: Yup.string().required('First Name is required'),
            lastname: Yup.string().required('Last Name is required'),
        }),
        onSubmit: async(values, { resetForm, setStatus }) => {
            const {
                email,
                firstname,
                lastname,
            } = values;

            // ac verified recaptcha token.
            const formData = {
                u: '3',
                f: '3',
                s: '',
                c: '0',
                m: '0',
                act: 'sub',
                v: '2',
                email,
                firstname,
                lastname,
                'g-recaptcha-response': recaptchaToken
            };

            setStatus();

            try {
                await fetch('https://rfal13037.activehosted.com/proc.php', {
                    method: 'POST',
                    body: new URLSearchParams(formData),
                    mode: 'no-cors',
                });

                resetForm();
                setStatus({ success: true });
            } catch (err) {
                setStatus({ error: ERROR_MESSAGE.INTERNAL_SERVER_ERROR });
            }
        }
    });

    useEffect(() => {
        // set values and validate
        formik.setTouched({ email: true }, true);
        formik.setValues({ email: emailValue }, true);
    }, [emailValue]);

    const renderNewsletterSignupForm = () => {
        const baseInputProps = {
            className: 'input',
            onChangeHandler: formik.handleChange,
            onBlurHandler: formik.handleBlur,
            isRequired: true,
        };

        const emailInputProps = {
            ...baseInputProps,
            type: 'email',
            name: 'email',
            id: 'email',
            placeholder: 'Enter your email',
            value: formik.values.email,
            error: formik.touched.email && formik.errors.email,
        };
        const emailConfirmInputProps = {
            ...baseInputProps,
            type: 'email',
            name: 'emailconfirm',
            id: 'emailconfirm',
            placeholder: 'Confirm your email',
            value: formik.values.emailconfirm,
            error: formik.touched.emailconfirm && formik.errors.emailconfirm,
        };
        const firstNameInputProps = {
            ...baseInputProps,
            type: 'text',
            name: 'firstname',
            id: 'firstname',
            placeholder: 'First Name',
            value: formik.values.firstname,
            error: formik.touched.firstname && formik.errors.firstname,
        };
        const lastNameInputProps = {
            ...baseInputProps,
            type: 'text',
            name: 'lastname',
            id: 'lastname',
            placeholder: 'Last Name',
            value: formik.values.lastname,
            error: formik.touched.lastname && formik.errors.lastname,
        };

        return (
            <div className="newsletter-signup">
                <h2>Be in the know</h2>
                <p>Sign up to our newsletter to stay up to date</p>
                <form className="form newsletter-signup-form" ref={formRef} onSubmit={formik.handleSubmit}>
                    <Input {...emailInputProps} />
                    <Input {...emailConfirmInputProps} />
                    <Input {...firstNameInputProps} />
                    <Input {...lastNameInputProps} />

                    <div ref={grecaptchaNodeRef} className="g-recaptcha-container"></div>

                    <Link className="link privacy-policy-link" to="/page/copyright-and-privacy">
                        Read about our privacy policy
                    </Link>
                    {formik.status && formik.status.error && <div className="error-message">{formik.status.error}</div>}

                    <div className="button-group left-align">
                        <button className="button default" type="button" onClick={() => modalHander(false)}>Cancel</button>
                        <button type="submit" className="button primary" disabled={!recaptchaToken || formik.isSubmitting}>Submit</button>
                    </div>
                </form>
            </div>
        );
    };

    return (
        <Modal isActive={modalStatus} closeModalHandler={() => modalHander(false)}>
            {formik.status && formik.status.success ? renderSubmitSuccessfully() : renderNewsletterSignupForm()}
        </Modal>
    );
};

NewsLetterModal.propTypes = {
    modalStatus: PropTypes.bool.isRequired,
    modalHander: PropTypes.func.isRequired,
    emailValue: PropTypes.string.isRequired
};


export default NewsLetterModal;
