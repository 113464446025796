import ContentBlocks from 'app/components/content-blocks/index';
import PageHeader from 'app/components/partials/page-header';
import PropTypes from 'prop-types';
import React from 'react';

const Page = ({ data }) => {
    const {
        blocks,
        title,
        header_image: images,
        subtitle: subTitle,
        background_color: backgroundColor,
    } = data;

    return (
        <div className="content-page">
            <PageHeader
                image={images}
                subTitle={subTitle}
                title={title}
                backgroundColor={backgroundColor}
            />
            <ContentBlocks data={blocks} pageTitle={title} extraClass="constrain-width" />
        </div>
    );
};

Page.propTypes = {
    data: PropTypes.object.isRequired
};

export default Page;
