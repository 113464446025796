import ContentSetArchive from 'app/components/partials/content-sets/content-set-archive';
import ContentSetArtwork from 'app/components/partials/content-sets/content-set-artwork';
import ContentSetEvent from 'app/components/partials/content-sets/content-set-event';
import ContentSetNews from 'app/components/partials/content-sets/content-set-news';
import ContentSetPage from 'app/components/partials/content-sets/content-set-page';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import useReferenceSearch from 'app/hooks/use-reference-search';
import { useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const GlobalSearchGrid = ({ reference, show = true }) => {
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(1);

    const { data, hasMore, isLoading, currentEntity } = useReferenceSearch(
        reference,
        searchParams.toString(),
        page
    );

    // Hotfix: always reset the page when switching entities
    // otherwise it will fetch the previous page
    // Not ideal because it will reset results when switching entities (unlike old AAG which keeps the old results), but fixing that behaviour requires a big refactor in this component
    // This will actually trigger useReferenceSearch fetch twice
    // (once for changing reference, and again for setting page)
    // but the abort controller will cancel the first request so it's fine
    useEffect(() => {
        setPage(1);
    }, [reference]);

    // Handle scroll search by observer
    const observer = useRef();
    const lastEntityRef = useCallback((node) => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((preValue) => preValue + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    const renderSearchItem = (item, index, reference) => {
        switch (reference) {
            case 'events':
            case 'exhibitions':
                return <ContentSetEvent
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            case 'artworks':
                return <ContentSetArtwork
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            case 'archives':
                return <ContentSetArchive
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            case 'ideas':
                return <ContentSetPage
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            case 'articles':
                return <ContentSetNews
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            case 'more':
                return <ContentSetPage
                    key={index}
                    index={index}
                    item={item}
                    showTile={true}
                    ref={(data.length === index + 1) ? lastEntityRef : null} />;
            default:
                return 'default';
        }
    };

    const getStyle = () => {
        if (!show) {
            return {
                display: 'none'
            };
        }

        return null;
    };

    return (
        <div className="global-search-grid || constrain-width" style={getStyle()}>
            <div className="tile-block">
                <div className="tile-block-inner">
                    {/*
                    Hacky way to fix render errors selecting different entity type.
                    When switching entities, reference immediately changes to the new entity type but the data may not have been fetched yet, causing render error if ContentSet component don't expect that data structure (e.g. artwork => ideas)
                    useReferenceSearch's currentEntity indicates the real currently loaded entity type, which we can use to ensure data<->reference consistency
                    */}
                    {currentEntity === reference && data && data.map((item, index) => {
                        return renderSearchItem(item, index, reference);
                    })}
                </div>
                {isLoading && <Loader type="loader-center"/>}
                {!isLoading && data.length === 0 && <h6 className="no-results">0 results found.</h6>}
            </div>
        </div>
    );
};

GlobalSearchGrid.propTypes = {
    reference: PropTypes.string.isRequired,
    show: PropTypes.bool
};

export default GlobalSearchGrid;
