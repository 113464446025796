import iconSprite from 'static/icons/sprite.svg';
import PropTypes from 'prop-types';
import React from 'react';

const Icon = (props) => { // eslint-disable-line require-jsdoc
    let options = props;

    const { name, title, type } = props;

    // Turn file name into title
    const iconTitle = name.replace(/-/g, ' ').replace(/\b\w/g, (l) => { return l.toUpperCase(); });

    // Default options should hide SVG
    if (type === 'default') {
        options = {
            'aria-hidden': true,
            'focusable': true,
            ...options
        };
    }

    // If the title is supplied we allow screen readers to register
    if (title) {
        options['aria-hidden'] = false;
    }

    return (
        <svg viewBox="0 0 40 40" {...options}>
            <title>{title ? title : iconTitle}</title>
            <use xlinkHref={`${iconSprite}#${name}`}></use>
        </svg>
    );
};

Icon.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
};

Icon.defaultProps = {
    className: 'icon',
    type: 'default',
    width: '40',
    height: '40'
};

export default Icon;
