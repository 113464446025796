import getVideoId from 'get-video-id';

export const getVideoIframeUrl = (videoUrl, params = {}) => {
    const { id, service } = getVideoId(videoUrl);

    const { controls = '1', autoplay = '0' } = params;

    if (service === 'youtube') {
        return `https://www.youtube.com/embed/${id}?autoplay=${autoplay}&mute=1&loop=1&controls=${controls}&disablekb=1&modestbranding=1&rel=0&&playlist=${id}`;
    } else if (service === 'vimeo') {
        return `https://player.vimeo.com/video/${id}?autoplay=${autoplay}&muted=1&loop=1`;
    }

    return null;
};
