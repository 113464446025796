/* eslint-disable camelcase */
import classnames from 'classnames';
import formatContent from 'app/utilities/format-content';
import { getVideoIframeUrl } from 'app/utilities/video';
import PropTypes from 'prop-types';
import React from 'react';
import { THEME_DEFAULT } from 'app/utilities/content-blocks';

const Media = ({ blockTheme = THEME_DEFAULT, data }) => {
    const getMediaTypeCls = (type) => {
        return `type-${type}`;
    };

    const renderOembedCode = (code, type) => {
        // Remove width and height
        const newCode = code.replace(/width="[\w]*[%]*"\s/i, '').replace(/height="[\w]*[%]*"\s/i, '');

        return <div className={classnames(getMediaTypeCls(type), 'rich-editor-content')} dangerouslySetInnerHTML={{ __html: formatContent(newCode) }} />;
    };

    const renderVideo = () => {
        const oEmbed = JSON.parse(data.oEmbed);
        if (oEmbed.code) {
            return renderOembedCode(oEmbed.code, oEmbed.type);
        }

        return (
            // see https://oembed.com/ for possible oEmbed types
            // video (e.g. youtube), photo, link, rich (e.g. soundcloud)
            // used to control styling depending on the media type
            <div className={getMediaTypeCls(oEmbed.type)}>
                <iframe
                    className="video"
                    type="text/html"
                    src={getVideoIframeUrl(data.url, { autoplay: '0' })}
                    frameBorder="0"
                    title={oEmbed.title || ''}
                ></iframe>
            </div>
        );
    };

    return (
        <section className={`section || media-block || ${blockTheme}`}>
            {renderVideo()}
        </section>
    );
};

Media.propTypes = {
    blockTheme: PropTypes.string,
    data: PropTypes.shape({
        url: PropTypes.string.isRequired,
        oEmbed: PropTypes.string.isRequired
    })
};

export default Media;
