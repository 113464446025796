import PropTypes from 'prop-types';
import React from 'react';

const MenuItem = React.forwardRef(({ slug }, ref) => {
    return <div id={slug} ref={ref} className="menu-item-locator"></div>;
});

MenuItem.displayName = 'MenuItem';

MenuItem.propTypes = {
    slug: PropTypes.string.isRequired
};

export default MenuItem;
