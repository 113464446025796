export const ARTIST_CHUNKS = [
    {
        title: 'A - E',
        artists: [],
        indexLetters: [
            'A', 'B', 'C', 'D', 'E'
        ]
    },
    {
        title: 'F - J',
        artists: [],
        indexLetters: [
            'F', 'G', 'H', 'I', 'J'
        ]
    },
    {
        title: 'K - O',
        artists: [],
        indexLetters: [
            'K', 'L', 'M', 'N', 'O'
        ]
    },
    {
        title: 'P - Z',
        artists: [],
        indexLetters: [
            'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ]
    }
];
