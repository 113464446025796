import defaultImage from 'static/images/svgs/art-toi.svg';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const ContentSetMajorProject = ({ item, index, showTile }) => {
    const getMajorProjectSlug = (data) => {
        return `/${data}`;
    };
    const name = item.data.name;
    const url = getMajorProjectSlug(item.data.slug);
    const image = item.data.thumbnail_image;

    return (
        <article className={showTile ? 'tile tile-major-project' : 'tile-hide'} key={index}>
            <div>
                <Link className="tile-thumbnail-link" to={url}>
                    <div className="tile-thumbnail">
                        <picture className="hover-area">
                            <div data-hover-text>view more</div>
                            <div className="image-container square">
                                <img className="tile-thumbnail-image" src={image ? image : defaultImage} alt={name} />
                            </div>
                        </picture>
                    </div>
                </Link>
            </div>
            {/* detail section */}
            <div className="tile-details">
                <h5 className="tile-title">
                    <Link to={url}>
                        {name}
                    </Link>
                </h5>
            </div>
        </article>
    );
};

ContentSetMajorProject.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showTile: PropTypes.bool
};
export default ContentSetMajorProject;
