export const INITIAL_STATE = {
    items: []
};

// Actions
export const MENUS_FETCH_SUCCESS = 'rfa-aag-v3-website/menus/MENUS_FETCH_SUCCESS';

// Action Creators
export const menusFetchSuccessAction = (menus) => ({
    type: MENUS_FETCH_SUCCESS,
    payload: menus
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MENUS_FETCH_SUCCESS:
            return { ...state, items: action.payload };
        default:
            return state;
    }
};

/**
 * Formats the API response
 * @param  {object} response – Raw API response
 * @return {object}          - Formatted API response
*/
export function formatMenus(response) {
    if (response && response.data && response.data.menu_items && response.data.menu_items.length) {
        const { data : { menu_items: menuItems } } = response;

        return menuItems;
    }

    return [];
}
