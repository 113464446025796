import React from 'react';
import { useSelector } from 'react-redux';

const FooterLanguageMap = () => {
    const { foreign_maps: foreignMaps } = useSelector((state) => state.globalFields);

    if (!foreignMaps || !foreignMaps.length) {
        return null;
    }

    // For mobile language map select box
    const onSelectMap = (e) => {
        window.location = e.currentTarget.value;
    };

    return (
        <div className="footer-language-map">
            <div className="desktop">
                <ul className="localisation-list">
                    {foreignMaps.map((map, idx) =>
                        <li key={idx}><a href={map.file}>{map.label}</a></li>
                    )}
                </ul>
            </div>
            <div className="mobile">
                <label htmlFor="localisation-select">Select language</label>
                <select id="localisation-select" className="localisation-select" onChange={onSelectMap}>
                    <option value="" disabled selected>Multi-language downloads</option>
                    {foreignMaps.map((map, idx) =>
                        <option key={idx} value={map.file}>{map.label}</option>
                    )}
                </select>
            </div>
        </div>
    );
};

export default FooterLanguageMap;
