/* eslint-disable camelcase */
import Helmet from 'react-helmet';
import { isAppProd } from 'config/env';
import PropTypes from 'prop-types';
import React from 'react';
import { renderTitle } from 'app/utilities/format-content';
import SocialSharing from '../partials/social-sharing';
import { useLocation } from 'react-router-dom';
import {
    seoDescription,
    seoDomain,
    seoImagePath,
    seoKeywords,
    seoSiteName,
    seoTwitterCard,
    seoTwitterSite,
    seoType,
} from 'config/seo';

// This layout can be used for both CMS pages and pure frontend custom pages
const Layout = ({ children, pageAttrs = {}, noIndex = false, withoutShare = false }) => {
    const location = useLocation();

    // Retrieve attributes for CMS pages
    // These attributes are required to fill in per-page meta tags
    // If not supplied, the default SEO will be used
    const {
        is_published: isPublished,
        // ensure backend returns these attributes for all entity types
        seo_title,
        seo_description,
        seo_keywords,
        seo_image,
    } = pageAttrs;

    // Only add no index for CMS pages which are not published
    const isNoIndex = noIndex || !isAppProd || (!isPublished && isPublished !== undefined);

    const getSeoTitle = () => {
        return seo_title || seoSiteName;
    };

    const getSeoDescription = () => {
        return seo_description || seoDescription;
    };

    const getSeoKeywords = () => {
        return seo_keywords || seoKeywords;
    };

    const getSeoImage = () => {
        return seo_image || seoImagePath;
    };

    const renderSocialSharing = () => {
        if (withoutShare) {
            return null;
        }

        return <SocialSharing />;
    };

    return (
        <main className="page" role="main">
            <Helmet>
                {isNoIndex ? <meta name="robots" content="noindex, nofollow" /> :
                    <meta name="robots" content="index, follow" />}
                {renderTitle(getSeoTitle())}
                {/* render Seo Meta Data */}
                <meta name="description"
                    content={getSeoDescription()}/>
                <meta name="keywords"
                    content={getSeoKeywords()}/>

                {/* Open Graph */}
                <meta property="og:locale" content="en_NZ"/>
                <meta property="og:title"
                    content={getSeoTitle()}/>
                <meta property="og:description"
                    content={getSeoDescription()}/>
                <meta property="og:site_name"
                    content={seoSiteName}/>
                <meta property="og:image"
                    content={getSeoImage()}/>
                <meta property="og:type"
                    content={seoType}/>
                <meta property="og:url"
                    content={
                        `${seoDomain}${
                            location.pathname
                        }`
                    }/>

                {/* Twitter Card */}
                <meta name="twitter:card"
                    content={seoTwitterCard}/>
                <meta name="twitter:site"
                    content={seoTwitterSite}/>
                <meta name="twitter:title"
                    content={getSeoTitle()}/>
                <meta name="twitter:description"
                    content={getSeoDescription()}/>
                <meta name="twitter:url"
                    content={
                        `${seoDomain}${
                            location.pathname
                        }`
                    }/>
                <meta name="twitter:image"
                    content={getSeoImage()}/>
                <meta name="theme-color"
                    content="#D01533"
                    media="(prefers-color-scheme: light)" />
                <meta name="theme-color"
                    content="#D01533"
                    media="(prefers-color-scheme: dark)" />
            </Helmet>
            {children}
            {renderSocialSharing()}
        </main>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    pageAttrs: PropTypes.object,
    noIndex: PropTypes.bool,
    withoutShare: PropTypes.bool
};

export default Layout;
