import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React from 'react';

const TITLE_TEXT_LIMIT = 80;
const CTA_TEXT_LIMIT = 50;

const LargeThumbnail = ({ title, thumbnail_group }) => {
    const truncate = (str, limit) => {
        if (str.length > limit) {
            return `${str.substring(0, limit)}...`;
        }

        return str;
    };

    const renderItems = (items) => {
        return items.map(({ image, cta_text, description, title, url }, idx) =>
            // TODO: automatically detect if the url is internal, use <Link> if it is
            <a className="thumbnail-section-item" href={url} key={idx}>
                <img src={image} alt={title}/>
                <div className="thumbnail-section-item-content">
                    <div className="text-wrapper">
                        <h2 className="title">{truncate(title, TITLE_TEXT_LIMIT)}</h2>
                        <div className="description">{description}</div>
                    </div>
                    <div className="cta-text">
                        <h3>{truncate(cta_text, CTA_TEXT_LIMIT)}</h3>
                        <Icon name="right-arrow" title="" width="25" height="25" />
                    </div>
                </div>
            </a>
        );
    };

    return (
        <section className="large-thumbnail-block">
            <h3 className="block-heading">{title}</h3>
            <div className="block-content thumbnail-section">
                {renderItems(thumbnail_group)}
            </div>
        </section>
    );
};

LargeThumbnail.propTypes = {
    title: PropTypes.string.isRequired,
    thumbnail_group: PropTypes.array.isRequired
};

export default LargeThumbnail;
