/* eslint-disable camelcase */
import ArticleTitleBar from '../partials/article-title-bar';
import ArtistNames from '../partials/artist-names';
import Carousel from '../content-blocks/carousel';
import ContentSetArtwork from '../partials/content-sets/content-set-artwork';
import EntitySpecDetails from '../partials/entity-spec-details';
import ExploreFurtherTags from '../partials/explore-further-tags';
import { getArtistRoute } from 'app/utilities/routes';
import { getTagInfo } from 'app/utilities/entity-tags';
import Icon from '../partials/icon';
import Image from '../content-blocks/image';
import Jotform from '../content-blocks/jotform';
import { Link } from 'react-router-dom';
import Modal from 'app/components/partials/modal';
import PropTypes from 'prop-types';
import { renderVernonField } from 'app/utilities/format-content';
import { hideHeadroom, showHeadroom } from 'app/utilities/update-headroom';
import React, { useState } from 'react';

const REQUEST_PRINT_JOTFORM_ID = '230146488786064';
const REPRODUCTION_ENQUIRY_JOTFORM_ID = '230148473802958';
const ARTWORKS_GRID_LEN = 4;

const Artwork = ({ data }) => {
    const [isRequestPrintModalOpen, setIsRequestPrintModalOpen] = useState(false);
    const [isReproductionEnquiryModalOpen, setIsReproductionEnquiryModalOpen] = useState(false);

    const {
        name,
        accession_no,
        other_id,
        copyright,
        department,
        credit_line,
        dimensions,
        inscription,
        on_display_text,
        artists,
        images,
        production_date,
        material_desc,
        brief_desc,
        vernon_tags,
        vernon_tags_names,
        blocks
    } = data;

    const openRequestPrintModal = (e) => {
        e.preventDefault();
        hideHeadroom();
        setIsRequestPrintModalOpen(true);
    };

    const closeRequestPrintModal = (e) => {
        e.preventDefault();
        setIsRequestPrintModalOpen(false);
        showHeadroom();
    };

    const openReproductionEnquiryModal = (e) => {
        e.preventDefault();
        hideHeadroom();
        setIsReproductionEnquiryModalOpen(true);
    };

    const closeReproductionEnquiryModal = (e) => {
        e.preventDefault();
        setIsReproductionEnquiryModalOpen(false);
        showHeadroom();
    };

    const getArtistDisplayNames = () => {
        return artists.map((artist) => artist.display_name).join(', ');
    };

    const renderArtistNames = (withRole = false) => {
        return <ArtistNames artists={artists} withRole={withRole} />;
    };

    const getSpecDetailRows = () => {
        return [
            {
                label: 'Title',
                value: name,
            },
            {
                label: 'Artist/creator',
                value: renderArtistNames(),
            },
            {
                label: 'Production date',
                value: production_date,
            },
            {
                label: 'Medium',
                value: material_desc,
            },
            {
                label: 'Dimensions',
                value: dimensions,
            },
            {
                label: 'Inscription',
                value: inscription,
            },
            {
                label: 'Credit line',
                value: credit_line,
            },
            {
                label: 'Accession no',
                value: accession_no,
            },
            {
                label: 'Other ID',
                value: other_id,
            },
            {
                label: 'Copyright',
                value: copyright,
            },
            {
                label: 'Department',
                value: department,
            },
            {
                label: 'Display status',
                value: on_display_text,
            },
        ];
    };

    const renderHeader = () => {
        return (
            <header className="article-header">
                <h6>
                    {renderArtistNames(true)}
                </h6>
                <ArticleTitleBar title={name} />
            </header>
        );
    };

    const renderImageBanner = () => {
        const alt = `${name} by ${getArtistDisplayNames()}`;
        if (images.length > 1) {
            return <Carousel
                isHero
                items={images.map((image) => ({ file: image, title: alt }))}
            />;
        }

        return <Image file={images[0]} hero creditline={alt} />;
    };

    const canRequestPrint = () => {
        return copyright !== 'Copying restrictions apply';
    };

    const renderRequestPrintBtn = () => {
        if (canRequestPrint()) {
            return <button className="btn-request-print button primary tiny" onClick={openRequestPrintModal}><Icon name="image" width="16" height="16" />Request a print</button>;
        }

        return null;
    };

    const renderArtworkDetails = () => {
        return (
            <section className="artwork-details">
                <h2 className="heading">
                    Artwork Detail
                    {renderRequestPrintBtn()}
                </h2>

                <div className="artwork-details-body">
                    <div className="artwork-description">
                        {renderVernonField(brief_desc)}
                        <EntitySpecDetails rows={getSpecDetailRows()} />
                        <hr />
                        <p className="small">To find out which artworks are available for print requests and reproduction please <a onClick={openReproductionEnquiryModal}>enquire here</a>. This service only applies to select artworks in the Gallery's collection.</p>
                    </div>

                    {vernon_tags && vernon_tags.length &&
                        <div className="artwork-tags">
                            <h4 className="heading heading-margin">Explore Further</h4>
                            <ExploreFurtherTags tags={getTagInfo(vernon_tags, vernon_tags_names)} category="artworks" />
                        </div>
                    }
                </div>
            </section>
        );
    };

    const renderRelatedArtworks = () => {
        return artists.map((artist, key) => renderArtistArtworks(artist, key));
    };

    const renderArtistArtworks = (artist, idx) => {
        const { id, display_name, slug, artworks, total_artworks } = artist;

        if (!total_artworks) {
            return null;
        }

        return (
            <aside className="artist-artworks" key={idx}>
                <h2 className="heading"><span>Discover: <Link className="artist-link" to={getArtistRoute(id, slug)}>{display_name}</Link></span>
                    {total_artworks > ARTWORKS_GRID_LEN &&
                    <Link className="button default tiny btn-view-all" to={`/search/artworks?artist=${encodeURIComponent(display_name)}`}>View all artworks</Link>
                    }
                </h2>
                {renderArtworksGrid(artworks)}
            </aside>
        );
    };

    const renderArtworksGrid = (artworks) => {
        return (
            <section className="content-set">
                <div className="tile-block">
                    <div className="tile-block-inner">
                        {artworks.slice(0, ARTWORKS_GRID_LEN).map((artwork, idx) => {
                            // wrap data in format expected by ContentSetArtwork
                            const item = {
                                data: artwork
                            };

                            return <ContentSetArtwork key={idx} item={item} index={idx} showTile />;
                        })
                        }
                    </div>
                </div>
            </section>
        );
    };

    const renderRequestPrintModal = (title, jotformId, isOpen, closeHandler) => {
        return (
            <Modal isActive={isOpen} closeModalHandler={closeHandler}>
                <div className="artwork-request-modal">
                    <h2 className="heading heading-margin">{title}</h2>
                    <p className="small">You are enquiring about:</p>
                    <h3 className="title"><strong className="artist">{getArtistDisplayNames()}</strong><span className="artwork">{name}</span></h3>
                    <p className="small no-margin">This service only applies to select artworks in the Gallery's collection. Please fill out the form below and we will get in touch to confirm the details of your enquiry.</p>
                    <Jotform data={{ form_id: jotformId }} />
                    <p><em>We manage your personal information in accordance with our <a target="_blank" href="/page/copyright-and-privacy">Privacy Policy</a></em></p>
                </div>
            </Modal>
        );
    };

    return (
        <div className="content-page constrain-width">
            <article className="artwork-page">
                {renderHeader()}
                {renderImageBanner()}
                {renderArtworkDetails()}
                {renderRelatedArtworks()}
                {renderRequestPrintModal('Request a print', REQUEST_PRINT_JOTFORM_ID, isRequestPrintModalOpen, closeRequestPrintModal)}
                {renderRequestPrintModal('Reproduction enquiry', REPRODUCTION_ENQUIRY_JOTFORM_ID, isReproductionEnquiryModalOpen, closeReproductionEnquiryModal)}
                {/* TODO: content blocks? currently broken in old AAG */}
            </article>
        </div>
    );
};

Artwork.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        accession_no: PropTypes.string,
        other_id: PropTypes.string,
        copyright: PropTypes.string,
        department: PropTypes.string,
        credit_line: PropTypes.string,
        dimensions: PropTypes.string,
        inscription: PropTypes.string,
        on_display: PropTypes.bool,
        on_display_text: PropTypes.string,
        artists: PropTypes.array,
        images: PropTypes.array,
        production_date: PropTypes.string,
        material_desc: PropTypes.string,
        brief_desc: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array
        ]),
        vernon_tags: PropTypes.array,
        vernon_tags_names: PropTypes.array,
        blocks: PropTypes.array
    })
};

export default Artwork;
