/* eslint-disable jsx-a11y/anchor-is-valid */
import ArtistList from '../content-blocks/major-projects/artist-list';
import Button from '../content-blocks/button';
import Carousel from 'app/components/content-blocks/carousel';
import ContentSet from 'app/components/content-blocks/content-set';
import Donation from '../content-blocks/donation';
import Faq from '../content-blocks/faq';
import FileDownload from 'app/components/content-blocks/file-download';
import Gallery from 'app/components/content-blocks/gallery';
import Helmet from 'react-helmet';
import HeroArtwork from '../content-blocks/major-projects/hero-artwork';
import LargeThumbnail from '../content-blocks/major-projects/large-thumbnail';
import Media from '../content-blocks/media';
import MinimalisticVideo from '../content-blocks/major-projects/minimalistic-video';
import MOCK_DATA from 'config/mock-data';
import PagebreakAlgorithm from '../content-blocks/pagebreak-algorithm';
import PagebreakQuote from '../content-blocks/pagebreak-quote';
import PageHeader from 'app/components/partials/page-header';
import React from 'react';
import { renderTitle } from 'app/utilities/format-content';
import RollingText from '../content-blocks/rolling-text';
import Section from 'app/components/partials/style-guide-section';
import SocialSharing from '../partials/social-sharing';
import Sponsor from '../content-blocks/sponsor';
import StacklaWidget from '../content-blocks/stackla-widget';
import Swatch from 'app/components/partials/swatch';
import TextContent from '../content-blocks/text-content';


const pageMeta = {
    title: 'Style Guide',
    introduction: 'A working site style guide built to manage design and code quality control.',
    images: null
};

const StyleGuidePage = () => (
    <main className="page page-style-guide" role="main">
        <Helmet>
            {renderTitle(pageMeta.title)}
            <meta name="description" content={pageMeta.introduction} />
            <meta name="keywords" content="" />
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>

        <PageHeader {...pageMeta} />

        <Section title="Colours" type="constrain">
            <div className="style-guide-swatches">
                {MOCK_DATA.SWATCH.items.map((swatch, index) => <Swatch key={index} {...swatch} />)}
            </div>
        </Section>

        <Section title="Typography" type="constrain">
            <div className="rte-content">
                <h1>Heading 1</h1>
                <h1 className="inverted">Heading 1 Inverted</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h3 className="inverted">Heading 3 Inverted</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                <h6>Heading 6</h6>

                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                <p>Ut wisi enim ad minim veniam, quis nostrud <strong>exerci tation ullamcorper suscipit</strong> lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit <em>in vulputate velit esse molestie</em> consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue.</p>
                <p>Nihil imperdiet doming id <a href="#" title="Link">quod mazim placerat</a> facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
            </div>
        </Section>

        <Section title="Buttons" type="constrain">
            <div className="rte-content">
                <div role="group" className="style-guide-button-group">
                    <button className="button default">Default</button>
                    <button className="button primary">Primary</button>
                    <button className="button" disabled>Disabled</button>
                </div>

                <div role="group" className="style-guide-button-group">
                    <button className="button default">Default size</button>
                    <button className="button primary">Default size</button>
                </div>

                <div role="group" className="style-guide-button-group">
                    <button className="button default small">Small size</button>
                    <button className="button primary small">Small size</button>
                </div>

                <div role="group" className="style-guide-button-group">
                    <button className="button default tiny">Tiny size</button>
                    <button className="button primary tiny">Tiny size</button>
                </div>

                <div role="group" className="style-guide-button-group">
                    <button className="button default block">Block button</button>
                    <button className="button primary block">Block button</button>
                </div>

                <div role="group" className="style-guide-button-group bg-primary">
                    <button className="button default inverse">Default inverse</button>
                    <button className="button primary inverse">Primary inverse</button>
                </div>
            </div>
        </Section>

        <Section title="Constrain Widths" type="constrain">
            <p className="style-guide-line || constrain-width no-pad large">Large</p>
            <p className="style-guide-line || constrain-width no-pad">Default</p>
            <p className="style-guide-line || constrain-width no-pad medium">Medium</p>
        </Section>

        <Section title="Carousel" type="constrain">
            <Carousel {...MOCK_DATA.CAROUSEL} />
        </Section>
        <Section title="Text Content" type="constrain">
            <TextContent {...MOCK_DATA.TEXT_PROPS} />
        </Section>
        <Section title="Text Content with Aside" type="constrain">
            <TextContent {...MOCK_DATA.TEXT_ASIDE_PROPS} />
        </Section>
        <Section title="Text Content with Quote" type="constrain">
            <TextContent {...MOCK_DATA.TEXT_QUOTE_PROPS} />
        </Section>
        <Section title="Customized Button" type="constrain">
            <Button {...MOCK_DATA.BUTTON}></Button>
        </Section>
        <Section title="Stacla" type="constrain">
            <StacklaWidget {...MOCK_DATA.STACKLA}></StacklaWidget>
        </Section>
        <Section title="Content Set" type="constrain">
            <ContentSet {...MOCK_DATA.CONTENT_SET_ARTIST}></ContentSet>
            <ContentSet {...MOCK_DATA.CONTENT_SET_ARTWORK}></ContentSet>
            <ContentSet {...MOCK_DATA.CONTENT_SET_PAGE}></ContentSet>
            <ContentSet {...MOCK_DATA.CONTENT_SET_EVENT}></ContentSet>
        </Section>
        <Section title="Gallery" type="constrain">
            <Gallery {...MOCK_DATA.GALLERY}></Gallery>
        </Section>
        <Section title="File" type="constrain">
            <FileDownload {...MOCK_DATA.FILE_DOWNLOAD}></FileDownload>
            <FileDownload {...MOCK_DATA.FILE_DOWNLOAD}></FileDownload>
            <FileDownload {...MOCK_DATA.FILE_DOWNLOAD}></FileDownload>
        </Section>
        <Section title="FAQ" type="constrain">
            <Faq {...MOCK_DATA.FAQ}></Faq>
        </Section>
        <Section title="Pagebreak Quote" type="constrain">
            <p>Red background</p>
            <PagebreakQuote {...MOCK_DATA.PAGEBREAK_QUOTE_RED}></PagebreakQuote>
            <p>Black background</p>
            <PagebreakQuote {...MOCK_DATA.PAGEBREAK_QUOTE_BLACK}></PagebreakQuote>
        </Section>
        <Section title="Pagebreak algorithm" type="constrain">
            <p>Left text with red background</p>
            <PagebreakAlgorithm {...MOCK_DATA.PAGEBREAK_ALGORITHM_LEFT}></PagebreakAlgorithm>
            <p>Right text with black background</p>
            <PagebreakAlgorithm {...MOCK_DATA.PAGEBREAK_ALGORITHM_RIGHT}></PagebreakAlgorithm>
        </Section>
        <Section title="Rolling Text" type="constrain">
            <RollingText {...MOCK_DATA.ROLLING_TEXT} />
        </Section>
        <Section title="Media" type="constrain">
            <Media {...MOCK_DATA.MEDIA} />
        </Section>
        <Section title="Donation" type="constrain">
            <p>Overlay</p>
            <Donation {...MOCK_DATA.DONATION1}></Donation>
            <p>No overlay</p>
            <Donation {...MOCK_DATA.DONATION2}></Donation>
        </Section>
        <Section title="Sponsor" type="constrain">
            <Sponsor {...MOCK_DATA.SPONSOR}></Sponsor>
        </Section>
        {/* Force major project specific style */}
        <div className="major-project">
            <div className="content-blocks">
                <Section title="Artist List" type="constrain">
                    <ArtistList {...MOCK_DATA.ARTIST_LIST} />
                </Section>
                <Section title="Large Thumbnails" type="constrain">
                    <LargeThumbnail {...MOCK_DATA.LARGE_THUMBNAIL} />
                </Section>
                <Section title="Hero artwork">
                    <HeroArtwork {...MOCK_DATA.HERO_ARTWORK} />
                </Section>
                <Section title="Minimalistic video" type="constrain">
                    <MinimalisticVideo {...MOCK_DATA.MINIMALISTIC_VIDEO} />
                </Section>
            </div>
        </div>
        <SocialSharing />
    </main>
);

export default StyleGuidePage;
