import classnames from 'classnames';
import defaultImage from 'static/images/svgs/art-toi.svg';
import EventDate from '../event-date';
import ExploreFurtherTags from '../explore-further-tags';
import { getTagInfo } from 'app/utilities/entity-tags';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const ContentSetEvent = forwardRef(({ item, index, showTile, isFeatured, twoItemInOneLine = false }, ref) => {
    const getEventExhibitonSlug = (data) => {
        return `/whats-on/${data.type}/${data.slug}`;
    };

    const name = item.data.name;
    const url = getEventExhibitonSlug(item.data);
    const image = item.data.thumbnailImage;
    const bookingLink = item.data.booking_link;
    const bookingLinkText = item.data.booking_link_text;
    const eventCost = item.data.cost;
    const eventTimeSummary = item.data.time_summary;
    const eventTags = item.data.tag_names;
    const eventType = item.data.event_type;
    const startDate = item.data.start_date;
    const endDate = item.data.end_date;

    // type event or exhibitons
    // from home page exhibition type as exhibitions
    const type = item.data.type;
    const hoverText = (type) => {
        if (type === 'exhibitions' || type === 'exhibition') return 'view exhibtion';

        return 'view event';
    };
    const displayedTags = getTagInfo(eventType, eventTags || []);

    const cls = classnames('tile-event', {
        'tile-hide': !showTile,
        'tile': showTile && !isFeatured,
        'tile-featured': showTile && isFeatured,
        'tile-display-2': showTile && twoItemInOneLine
    });

    return (
        <article className={cls} key={index} ref={ref}>
            <div>
                <Link className="tile-thumbnail-link" to={url}>
                    <div className="tile-thumbnail">
                        <picture className="hover-area">
                            <div data-hover-text>{hoverText(type)}</div>
                            <div className={isFeatured ? 'image-container || featured' : 'image-container || square'}>
                                <img className="tile-thumbnail-image" src={image ? image : defaultImage} alt={name} />
                            </div>
                        </picture>
                    </div>
                </Link>
            </div>
            {/* detail section */}
            <div className="tile-details">
                <h5 className="tile-title">
                    <Link to={url}>
                        {name}
                    </Link>
                </h5>
                {/* only display on major project pages */}
                <hr className="tile-show--mp" />
                {startDate && endDate &&
                    <React.Fragment>
                        <p><strong><EventDate startDate={startDate} endDate={endDate} /></strong></p>
                        {eventTimeSummary && <p>{eventTimeSummary}</p>}
                    </React.Fragment>
                }
                {eventCost &&
                    <p>{eventCost} {bookingLinkText ? <span><a href={bookingLink} target="_blank" rel="noreferrer">{bookingLinkText}</a></span> : ''}</p>
                }
                {displayedTags &&
                    <div className="tile-event-tags tile-show--normal">
                        <ExploreFurtherTags tags={displayedTags} category="events" searchBy="event-type" />
                    </div>
                }
            </div>
        </article>
    );
});

ContentSetEvent.displayName = 'ContentSetEvent';

ContentSetEvent.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showTile: PropTypes.bool,
    isFeatured: PropTypes.bool,
    twoItemInOneLine: PropTypes.bool
};
export default ContentSetEvent;
