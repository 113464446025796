import Banner from '../partials/hero-banner';
import ContentBlocks from 'app/components/content-blocks/index';
import PropTypes from 'prop-types';
import VirtualTour from '../partials/virtual-tour';
import React, { useRef } from 'react';

const MajorProject = ({ data }) => {
    const {
        blocks,
        title,
        mobile_image: mobileImage,
        main_images: mainImages,
        virtual_tour_intro: virtualTourIntro,
        virtual_tour_link: virtualTourLink,
    } = data;

    const tour = useRef(null);
    const contentStart = useRef(null);

    const hasTour = virtualTourIntro && virtualTourLink;

    return (
        <div className="major-project">
            <header className="major-project-header">
                <Banner mainImage={mainImages[0]} mobileImage={mobileImage} target={hasTour ? tour : contentStart} />
                <VirtualTour intro={virtualTourIntro} link={virtualTourLink} ref={tour}/>
            </header>
            <div ref={contentStart}>
                <ContentBlocks data={blocks} pageTitle={title} extraClass="constrain-width large with-left-nav" />
            </div>
        </div>
    );
};

MajorProject.propTypes = {
    data: PropTypes.shape({
        blocks: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
        mobile_image: PropTypes.string,
        main_images: PropTypes.array,
        virtual_tour_intro: PropTypes.string,
        virtual_tour_link: PropTypes.string,
    })
};

export default MajorProject;
