import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const FilterDropdownForArchive = ({ className, formik }) => {
    const [openOptionList, setOpenOptionList] = useState(false);
    const filterRef = useRef();

    const toggleDropdownHandler = () => {
        setOpenOptionList((prevValue) => !prevValue);
    };

    const renderOptions = () => {
        return (
            <div className={ classnames('options', 'archive-options', openOptionList ? '' : 'is-hidden') }>
                <div className="column">
                    <h6 className="heading || plain">Dates:</h6>
                    <div className="inline-dates">
                        <input className="input" name="from-date" type="number" min="0" max="2030" placeholder="eg: 2022" value={formik.values['from-date']} onChange={formik.handleChange} />
                        <span className="highlight">to</span>
                        <input className="input" name="to-date" type="number" min="0" max="2030" placeholder="eg: 2022" value={formik.values['to-date']} onChange={formik.handleChange} />
                    </div>
                    <div className="field-group || checkbox">
                        <label>
                            <input type="checkbox" name="undated" value="undated" checked={formik.values.undated} onChange={formik.handleChange} />
                            <span>Include undated results</span>
                        </label>
                    </div>
                </div>
                <div className="controls">
                    <button className="button primary" type="submit">Apply</button>
                </div>
            </div>
        );
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpenOptionList(false);
        } else if (event.target.type === 'submit') {
            setOpenOptionList(false);
        }
    };

    useEffect(() => {
        const optionHeight = filterRef.current.getElementsByClassName('options')[0].clientHeight;
        const marginBottom = openOptionList ? `${optionHeight}px` : null;
        filterRef.current.style['margin-bottom'] = marginBottom;

        if (openOptionList === false) {
            return;
        }
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [openOptionList]);

    return (
        <div className={ classnames('filter-dropdown', 'wide', className, openOptionList ? 'open' : '') } ref={filterRef}>
            <div className="dropdown" onClick={toggleDropdownHandler} aria-hidden="true">
                <div className="label">Refine <span>your search results...</span></div>
                <Icon name="arrow" width="15" height="15" title="" />
            </div>
            { renderOptions() }
        </div>
    );
};

FilterDropdownForArchive.propTypes = {
    className: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired
};

export default FilterDropdownForArchive;
