import ContentSetEvent from 'app/components/partials/content-sets/content-set-event';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import React, { useEffect, useState } from 'react';

const WhatsOnExhibition = () => {
    const [exhibitions, setExhibitions] = useState(null);
    const [showAll, setShowAll] = useState(false);
    // line nubmer for display rows
    const initialMaxNumber = 5;
    const showAllTiles = () => {
        setShowAll(!showAll);
    };

    const formatExhibitionData = (entities) => {
        return entities.map((entity) => {
            return {
                data: entity
            };
        });
    };

    const fetchExhibitions = async() => {
        const queryString = 'date-range=today';
        const results = await fetch(`${ENDPOINTS.SEARCH('exhibitions')}?${queryString}`);
        setExhibitions(formatExhibitionData(results.exhibitions.data));
    };

    useEffect(async() => {
        await fetchExhibitions();
    }, []);

    useEffect(() => {
        if (exhibitions && exhibitions.length <= initialMaxNumber) {
            setShowAll(true);
        }
    }, [exhibitions]);

    return (
        <section className="whats-on-exhibition || content-set || constrain-width">
            <div className="tile-block">
                <h2 className="tile-block-title">EXHIBITIONS</h2>
                <div className="tile-block-inner">
                    {exhibitions && exhibitions.map((item, index) => {
                        return <ContentSetEvent key={index} item={item} index={index} showTile={index <= initialMaxNumber || showAll} isFeatured={true} twoItemInOneLine={[0, 1].includes(index)} />;
                    })}
                </div>

                {showAll ? '' : <div className="tile-showbtn"><button className="button default tiny" onClick={showAllTiles}>Show All</button></div>}
            </div>
        </section>
    );
};

WhatsOnExhibition.propTypes = {
};

export default WhatsOnExhibition;
