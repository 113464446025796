import ContentSetArchive from './content-sets/content-set-archive';
import ContentSetArchiveItem from './content-sets/content-set-archive-item';
import ContentSetArtist from './content-sets/content-set-artist';
import ContentSetArtwork from './content-sets/content-set-artwork';
import ContentSetEvent from './content-sets/content-set-event';
import ContentSetMajorProject from './content-sets/content-set-major-project';
import ContentSetNews from './content-sets/content-set-news';
import ContentSetPage from './content-sets/content-set-page';
import ContentSetProduct from './content-sets/content-set-product';
import PropTypes from 'prop-types';
import React from 'react';

const ContentSetContainer = (props) => {
    const type = props.item.type;
    const render = () => {
        switch (type) {
            case 'contentpage': return <ContentSetPage {...props} />;
            case 'major_project': return <ContentSetMajorProject {...props} />;
            case 'artwork': return <ContentSetArtwork {...props} />;
            case 'archive': return <ContentSetArchive {...props} />;
            case 'archive_item': return <ContentSetArchiveItem {...props} />;
            case 'person': return <ContentSetArtist {...props} />;
            case 'event': return <ContentSetEvent {...props} />;
            case 'news': return <ContentSetNews {...props} />;
            case 'product': return <ContentSetProduct {...props} />;
            default: return '';
        }
    };

    return (
        render()
    );
};

ContentSetContainer.propTypes = {
    blockTheme: PropTypes.string,
    blockStyle: PropTypes.object,
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showTile: PropTypes.bool
};
export default ContentSetContainer;
