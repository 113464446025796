/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ARCHIVE_SORT_FILTER } from 'config/search-options';
import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

const ArchiveFilter = ({ formik }) => {
    const [searchParams] = useSearchParams();
    const [openOptionList, setOpenOptionList] = useState(false);
    const filterRef = useRef();

    const sortSelectedOption = ARCHIVE_SORT_FILTER.options.find((option) => option.value === searchParams.get('sort')) || null;

    const toggleDropdownHandler = () => {
        setOpenOptionList((prevValue) => !prevValue);
    };

    const filterHandler = (key, value) => {
        formik.setFieldValue(key, value);
        setOpenOptionList(false);
        formik.submitForm();
    };

    const renderOptions = () => {
        return (
            <div className={ classnames('options', 'archive-options', openOptionList ? '' : 'is-hidden') }>
                <div className="column">
                    <h6>Select date range:</h6>
                    <div className="inline-dates">
                        <input className="input" name="from-date" type="number" min="0" max="2030" placeholder="eg: 2022" value={formik.values['from-date']} onChange={formik.handleChange} />
                        <span className="highlight">to</span>
                        <input className="input" name="to-date" type="number" min="0" max="2030" placeholder="eg: 2022" value={formik.values['to-date']} onChange={formik.handleChange} />
                    </div>
                    <div className="field-group || checkbox">
                        <label>
                            <input type="checkbox" name="undated" value="undated" checked={formik.values.undated} onChange={formik.handleChange} />
                            <span>Include undated results</span>
                        </label>
                    </div>
                </div>
                <div className="controls">
                    <button className="button primary" type="submit">Apply</button>
                </div>
            </div>
        );
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpenOptionList(false);
        } else if (event.target.type === 'submit') {
            setOpenOptionList(false);
        }
    };

    useEffect(() => {
        const optionHeight = filterRef.current.getElementsByClassName('options')[0].clientHeight;
        const marginBottom = openOptionList ? `${optionHeight}px` : null;
        filterRef.current.style['margin-bottom'] = marginBottom;

        if (openOptionList === false) {
            return;
        }
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [openOptionList]);

    return (
        <div className="filter-groups" ref={filterRef}>
            <span className="filter-group-label">Sort by</span>
            <div className="search-categories-dropdown">
                <button type="button" className="button || default">
                    <span>{ sortSelectedOption ? sortSelectedOption.label : 'Relevance'}</span>
                    <Icon name="arrow" width="15" height="15" title="" />
                </button>
                <select className="system-select" value={ARCHIVE_SORT_FILTER.options[ARCHIVE_SORT_FILTER.defaultIndex].value} onChange={(e) => filterHandler('sort', e.target.value)}>
                    {ARCHIVE_SORT_FILTER.options.map((item, index) => {
                        return <option key={index} value={item.value}>{item.label}</option>;
                    })}
                </select>
            </div>

            <div className={ classnames('filter-dropdown', openOptionList ? 'open' : '') } >
                <button type="button" className="button || default" onClick={toggleDropdownHandler}>
                    <span>Refine results</span>
                    <Icon name="arrow" width="15" height="15" title="" />
                </button>
            </div>
            { renderOptions() }
        </div>
    );
};

ArchiveFilter.propTypes = {
    formik: PropTypes.object.isRequired
};

export default ArchiveFilter;
