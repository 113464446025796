import Archive from './archive';
import ArchiveItem from './archive-item';
import ArtIdeas from './art-ideas';
import Artist from './artist';
import Artwork from './artwork';
import EducationGroup from './education-group';
import Event from './event';
import Home from './home';
import MajorProject from './major-project';
import News from './news';
import Page from './page';
import PropTypes from 'prop-types';
import React from 'react';
import WhatsOn from './whats-on';

function Template({ data, type }) { // eslint-disable-line require-jsdoc
    switch (type) {
        case 'home':
            return <Home data={data} />;
        case 'artIdeas':
            return <ArtIdeas data={data} />;
        case 'educationGroup':
            return <EducationGroup data={data} />;
        case 'whatsOn':
            return <WhatsOn data={data} />;
        case 'majorProject':
            return <MajorProject data={data} />;
        case 'event':
        case 'exhibition':
            return <Event data={data} type={type} />;
        case 'artist':
            return <Artist data={data} />;
        case 'artwork':
            return <Artwork data={data} />;
        case 'archive':
            return <Archive data={data} />;
        case 'archiveItem':
            return <ArchiveItem data={data} />;
        case 'news':
            return <News data={data} />;
        case 'page':
        default:
            return <Page data={data} />;
    }
}

Template.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string.isRequired
};

export default Template;
