/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import classnames from 'classnames';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import PropTypes from 'prop-types';
import { COLLECTION_OPTION, NATIONALITY_OPTION, ONLY_OPTION } from 'config/search-options';
import React, { useEffect, useState } from 'react';

const AdvancedSearchForm = ({ className, formik }) => {
    const [objectTypeOptions, setObjectTypeOptions] = useState([]);

    const fetchObjectTypeOptions = async() => {
        try {
            const resp = await fetch(ENDPOINTS.OBJECT_TYPES);
            setObjectTypeOptions(resp.map((item) => { return { label: item, value: item }; }));
        } catch (e) {
            //
        }
    };

    useEffect(async() => {
        await fetchObjectTypeOptions();
    }, []);

    return (
        <div className={ classnames('options', 'advanced-search-dropdown', className) }>
            <div className="column">
                <h6 className="heading || plain">Show:</h6>
                {ONLY_OPTION.map((option, index) => (
                    <div key={index} className="field-group || checkbox">
                        <label>
                            <input type="checkbox" name="only" value={option.value} checked={formik.values.only.includes(option.value)} onChange={formik.handleChange} />
                            <span>{option.label}</span>
                        </label>
                    </div>
                ))}
                <h6 className="heading || plain">Object type:</h6>
                {objectTypeOptions.map((option, index) => (
                    <div key={index} className="field-group || checkbox">
                        <label>
                            <input type="checkbox" name="objectType" value={option.value} checked={formik.values.objectType.includes(option.value)} onChange={formik.handleChange} />
                            <span>{option.label}</span>
                        </label>
                    </div>
                ))}
            </div>
            <div className="column">
                <h6 className="heading || plain">Collection:</h6>
                {COLLECTION_OPTION.map((option, index) => (
                    <div key={index} className="field-group || checkbox">
                        <label>
                            <input type="checkbox" name="collections" value={option.value} checked={formik.values.collections.includes(option.value)} onChange={formik.handleChange} />
                            <span>{option.label}</span>
                        </label>
                    </div>
                ))}
            </div>
            <div className="column">
                <h6 className="heading || plain">Dates:</h6>
                <div className="inline-dates">
                    <input className="input" name="from-date" type="number" min="0" max="2030" placeholder="eg: 2022" value={formik.values['from-date']} onChange={formik.handleChange} />
                    <span className="highlight">to</span>
                    <input className="input" name="to-date" type="number" min="0" max="2030" placeholder="eg: 2022" value={formik.values['to-date']} onChange={formik.handleChange} />
                </div>
                <div className="field-group || checkbox">
                    <label>
                        <input type="checkbox" name="undated" value="undated" checked={formik.values.undated} onChange={formik.handleChange} />
                        <span>Include undated results</span>
                    </label>
                </div>
            </div>
            <div className="column">
                <h6 className="heading || plain">Artist nationality:</h6>
                <div className="scrollable">
                    {NATIONALITY_OPTION.map((option, index) => (
                        <div key={index} className="field-group || checkbox">
                            <label>
                                <input type="checkbox" name="nationalities" value={option.value} checked={formik.values.nationalities.includes(option.value)} onChange={formik.handleChange} />
                                <span>{option.label}</span>
                            </label>
                        </div>
                    ))}
                </div>
                <h6 className="heading || plain">Artist:</h6>
                <input className="input" type="text" name="artist" placeholder="e.g. Marti Friedlander" value={formik.values.artist} onChange={formik.handleChange} />
            </div>
            <div className="controls">
                <button className="button primary" type="submit">Apply</button>
            </div>
        </div>
    );
};

AdvancedSearchForm.propTypes = {
    className: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired
};

export default AdvancedSearchForm;
