import AUCKLAND_UNLIMITED_LOGO from 'static/images/logos/auckland-unlimited-logo-black.svg';
import Icon from 'app/components/partials/icon';
import { initMap } from 'app/utilities/google-map';
import Input from 'app/components/partials/input';
import { Link } from 'react-router-dom';
import NewsLetterModal from './news-letter-modal';
import { SOCIAL_LINKS } from 'config/social-links.js';
import { useFormik } from 'formik';
import WomenRefuge from './women-refuge';
import React, { useEffect, useRef, useState } from 'react';
import FooterLanguageMap from './footer-language-map';

const Footer = () => {
    const [isModalActive, setIsModalActive] = useState(false);
    const modalHander = (state) => {
        setIsModalActive(state);
    };
    const mapRef = useRef(null);
    const year = new Date().getFullYear();

    const formik = useFormik({
        initialValues: { email: '' },
        onSubmit: () => {
            setIsModalActive(true);

            return false;
        }
    });


    const renderNewsletterSignupForm = () => {
        const inputProps = {
            type: 'email',
            className: 'input',
            name: 'email',
            id: 'email',
            placeholder: 'Enter your email',
            value: formik.values.email,
            isRequired: true,
            onChangeHandler: formik.handleChange,
        };

        return (
            <div className="newsletter-signup">
                <h2>Be in the know</h2>
                <p>Sign up to our newsletter to stay up to date</p>
                <form className="form newsletter-signup-form" onSubmit={formik.handleSubmit}>
                    <Input {...inputProps} />
                    <Link className="link privacy-policy-link" to="/page/copyright-and-privacy">
                        Read about our privacy policy
                    </Link>
                    <button className="button default" type="submit">Sign up</button>
                </form>
            </div>
        );
    };


    useEffect(() => {
        window.initMap = () => initMap(mapRef.current);
    }, []);

    return (
        <>
            <footer className="footer">
                {/* Map */}
                <div className="footer-information">
                    <div className="map-container" ref={mapRef}></div>
                    <div className="information-section || constrain-width">
                        <div className="inner">
                            <div className="info-card">
                                <h2>Auckland Art Gallery</h2>
                                <div className="info-card-section">
                                    <p>Open every day 10am — 5pm.</p>
                                    <p>Except Christmas day.</p>
                                </div>
                                <div className="info-card-section">
                                    <a href="tel:+6493791349" className="link phone">
                                        <Icon name="phone" width="25" height="25" /> +64 9 379 1349
                                    </a>
                                </div>
                                <div className="info-card-section">
                                    <Link to="/about/contact-us" className="link email">
                                        <Icon name="envelope" width="25" height="25" /> Email us
                                    </Link>
                                </div>
                                <Link className="button primary" to="/visit/visitor-information/getting-here">
                                    Getting here
                                </Link>
                            </div>
                            <div className="info-card promo">
                                {renderNewsletterSignupForm(false)}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Newsletter signup form on mobile */}
                <div className="newsletter-signup-banner">
                    <h4>Be in the know</h4>
                    <p>Sign up to our newsletter</p>
                    <button className="button default small" onClick={() => setIsModalActive(true)}>Sign up</button>
                </div>

                {/* Main */}
                <div className="footer-main || constrain-width">
                    <div className="footer-main-section">
                        <div className="contact-info || mobile-only">
                            <a href="tel:+6493791349" className="link phone">
                                +64 9 379 1349
                            </a>
                            <Link to="/about/contact-us" className="link email">
                                Email us
                            </Link>
                        </div>
                        <div className="social-links">
                            {SOCIAL_LINKS.map(({ name, link, width, height }) => (
                                <a className="link" href={link} key={name} rel="noopener noreferrer" target="_blank">
                                    <Icon name={name} width={width} height={height} />
                                </a>
                            ))}
                        </div>
                        <a className="link || logo" href="https://aucklandunlimited.com/" rel="noopener noreferrer" target="_blank">
                            <img src={AUCKLAND_UNLIMITED_LOGO} alt="Tātaki Auckland Unlimited" />
                        </a>
                    </div>
                    <div className="footer-main-section">
                        <FooterLanguageMap />
                        <div className="copyrights">
                            <small className="fine-text">
                                &copy;{year} Tātaki Auckland Unlimited
                            </small>
                            <div className="links">
                                <Link className="link || fine-text" to="/page/copyright-and-privacy">
                                    Copyright and privacy
                                </Link>
                                <Link className="link || fine-text" to="/page/conditions-of-entry">
                                    Conditions of entry
                                </Link>
                            </div>
                        </div>
                    </div>

                    <WomenRefuge />
                </div>
            </footer>
            <NewsLetterModal modalStatus={isModalActive} modalHander={modalHander} emailValue={formik.values.email}/>
        </>
    );
};

export default Footer;
