import Notification from './notification';
import React from 'react';
import { updateNotificationAction } from 'app/ducks/notifications';
import { useDispatch, useSelector } from 'react-redux';


const NotificationBanner = () => {
    const { isActive, notifications } = useSelector((state) => state.notifications);

    const dispatch = useDispatch();

    // Handler
    const handleToggle = (id) => dispatch(updateNotificationAction(id));

    return (
        <div className={`notification-banner ${isActive ? '' : 'is-hidden'}`}>
            {notifications && notifications.map((item, index) => {
                const {
                    description,
                    isPublished,
                    id,
                    title,
                    type,
                    url,
                    uncloseable
                } = item;

                return (
                    <Notification
                        description={description}
                        id={id}
                        title={title}
                        type={type}
                        url={url}
                        handleToggle={handleToggle}
                        isVisible={isPublished}
                        uncloseable={uncloseable}
                        key={index}
                    />
                );
            })}
        </div>
    );
};

export default NotificationBanner;
