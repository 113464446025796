import classnames from 'classnames';
import ContentBlocks from '../content-blocks';
import { DATE_FORMAT_EVENT_PAGE } from 'config/app';
import { DOWN_TOLERANCE } from 'config/headroom';
import EventDate from './event-date';
import Headroom from 'react-headroom';
import HeroBanner from './hero-banner';
import Icon from './icon';
import PropTypes from 'prop-types';
import SocialSharingBtn from './social-sharing-btn';
import Sponsor from '../content-blocks/sponsor';
import { THEME_AEX } from 'app/utilities/content-blocks';
import VirtualTour from './virtual-tour';
import React, { useEffect, useRef, useState } from 'react';

const AdvancedExhibition = ({ data }) => {
    const {
        name,
        mainImage,
        mobileImage,
        start_date: startDate,
        end_date: endDate,
        virtual_tour_intro: virtualTourIntro,
        virtual_tour_link: virtualTourLink,
        theme_colour: themeColour,
        theme_colour_secondary: textColor,
        booking_link: bookingLink,
        booking_link_text: bookingLinkText,
        sponsors
    } = data;

    const backgroundColor = {
        backgroundColor: themeColour,
    };

    const baseTextColor = {
        color: textColor,
    };

    const baseTheme = {
        ...backgroundColor,
        ...baseTextColor
    };

    const headerRef = useRef(null);
    const virtualTourRef = useRef(null);
    const pageContentRef = useRef(null);
    const sidebarRef = useRef(null);
    const mobileNavRef = useRef(null);
    const mobileDropdownMaskRef = useRef(null);
    const mobileDropdownRef = useRef(null);

    // controls hero banner height to fill up viewport
    const [heroBannerStyle, setHeroBannerStyle] = useState(backgroundColor);
    // controls opacity of hero banner images and button as the page is scrolled
    const [heroImageStyle, setHeroImageStyle] = useState({});
    const [proceedBtnStyle, setProceedBtnStyle] = useState({});

    // controls height of desktop header
    const [headerStyle, setHeaderStyle] = useState({});
    // adjusts desktop header text transform on scroll
    const [headerContentStyle, setHeaderContentStyle] = useState({});

    // controls pinning and offset of desktop header
    const [isHeadroomFixed, setIsHeadroomFixed] = useState(false);
    const [headroomStyle, setHeadroomStyle] = useState({});

    // controls pinning and offset of desktop sidebar
    const [sidebarStyle, setSidebarStyle] = useState({});

    // controls pinning and offset of mobile nav
    const [isMobileNavFixed, setIsMobileNavFixed] = useState(false);
    const [mobileNavStyle, setMobileNavStyle] = useState({});
    const [mobileNavHeadroomStyle, setMobileNavHeadroomStyle] = useState({});

    const [topHeadroomHeight, setTopHeadroomHeight] = useState(0);

    // toggle mobile navbar dropdown
    const [isMobileNavOpened, setIsMobileNavOpened] = useState(false);

    const getAexBlocks = () => {
        return [
            { label: 'about', name: 'About', blocks: data.extended_about },
            { label: 'tickets', name: 'Tickets', blocks: data.extended_tickets },
            { label: 'events', name: 'Events', blocks: data.extended_events },
            { label: 'visit', name: 'Visit', blocks: data.extended_visitings },
            { label: 'virtual_tour', name: 'Virtual tour', blocks: data.extended_virtual_tour },
            { label: 'extras', name: 'Extras', blocks: data.extended_extras },
            { label: 'faqs', name: 'FAQs', blocks: data.extended_faqs },
            { label: 'related', blocks: data.blocks },
        ];
    };

    const getVirtualTourHeight = () => {
        return virtualTourRef.current ? virtualTourRef.current.offsetHeight : 0;
    };

    const calcIsSidebarFixed = (isHeadroomPinned, headroomHeight) => {
        // Work out how much we need to scroll to reach the top of the sidebar
        let cmp = headroomHeight
            + pageContentRef.current.offsetTop
            // The header will be fixed so we need to take off its height
            - headerRef.current.offsetHeight;

        // If headroom is pinned we also need to take it off
        if (isHeadroomPinned) {
            cmp -= headroomHeight;
        }

        return window.scrollY > cmp;
    };

    const calcIsMobileNavFixed = (isHeadroomPinned, headroomHeight) => {
        // Work out how much we need to scroll to reach the top of the page contents div
        let cmp = headroomHeight + mobileNavRef.current.offsetTop;
        // If headroom is pinned we also need to take it off
        if (isHeadroomPinned) {
            cmp -= headroomHeight;
        }

        // Need gte comparison to allow mobile nav mask to be closeable
        return window.scrollY >= cmp;
    };

    const calcBannerScrollRatio = (isHeadroomPinned, headroomHeight) => {
        // The distance from top of title header to the top of the page
        let height = headerRef.current.offsetTop + headroomHeight;
        // If the headroom is pinned we also need to take it off
        if (isHeadroomPinned) {
            height -= headroomHeight;
        }

        return Math.min(window.scrollY / height, 1);
    };

    // For fading the hero banner as the page is scrolled
    const calcBannerStyles = (ratio) => {
        // Time it so that the effect progresses earlier
        const adjustedRatio = Math.min(1, ratio + 0.1);

        setHeroImageStyle((style) => ({
            ...style,
            opacity: 1 - adjustedRatio * adjustedRatio * adjustedRatio
        }));

        setProceedBtnStyle((style) => ({
            ...style,
            opacity: Math.max(1 - adjustedRatio * 1.5, 0)
        }));

        setHeaderContentStyle((style) => ({
            ...style,
            transform: `translateY(${(1 - adjustedRatio * adjustedRatio * adjustedRatio) * 150}%)`
        }));
    };

    // For styling the title header depending on the scroll position of the page and whether the top headroom is pinned
    const calcHeaderStyles = (isFixed) => {
        // Set a class on the headroom to fix the title header
        setIsHeadroomFixed(isFixed);

        // Add background colour only when header is fixed
        const newStyle = {
            backgroundColor: isFixed ? backgroundColor.backgroundColor : 'transparent',
        };

        // Clear headroom transforms when unfixed
        if (!isFixed) {
            newStyle.transform = 'none';
        }

        // Set header top position
        setHeadroomStyle((style) => ({
            ...style,
            ...newStyle
        }));
    };

    // Bump down the header when top headroom is pinned
    const onPinDesktopHeadroom = () => {
        if (isHeadroomFixed) {
            setHeadroomStyle((style) => ({
                ...style,
                transform: `translateY(${topHeadroomHeight}px)`
            }));
        }
    };

    const onUnpinDesktopHeadroom = () => {
        setHeadroomStyle((style) => ({
            ...style,
            transform: 'none'
        }));
    };

    // Bump down the header when top headroom is pinned
    const onPinMobileHeadroom = () => {
        if (isMobileNavFixed) {
            setMobileNavHeadroomStyle((style) => ({
                ...style,
                transform: `translateY(${topHeadroomHeight}px)`
            }));
        }
    };

    const onUnpinMobileHeadroom = () => {
        setMobileNavHeadroomStyle((style) => ({
            ...style,
            transform: 'none'
        }));
    };

    // Calculate whether to fix sidebar to viewport or absolute position to bottom of page
    const calcSidebarFixedPos = (currentStyle, isHeadroomPinned, topHeadroomHeight) => {
        const desiredTopOffset = (isHeadroomPinned ? topHeadroomHeight : 0) + headerRef.current.offsetHeight;

        if (currentStyle.position === 'absolute') {
            const rect = sidebarRef.current.getBoundingClientRect();
            // Only transition to fixed position once the page has scrolled up enough for the sidebar to not overlap with the title header + top headroom
            if (rect.y > desiredTopOffset) {
                return {
                    position: 'fixed',
                    top: desiredTopOffset
                };
            }

            return currentStyle;
        }

        // fix the sidebar to the bottom once it has reached the bottom of the page
        const pageContainer = document.querySelector('.page');
        const bottomPadding = 50;
        const height = sidebarRef.current.offsetTop + sidebarRef.current.offsetHeight + bottomPadding;
        const fixBottom = window.scrollY + height > pageContainer.offsetHeight;

        if (fixBottom) {
            return {
                position: 'absolute',
                bottom: `${bottomPadding}px`,
            };
        }

        // fixed to viewport by default
        return {
            position: 'fixed',
            top: desiredTopOffset
        };
    };

    // For styling the sidebar depending on the scroll position of hte page and whether the top headroom is pinned
    const calcSidebarStyles = (isHeadroomPinned, topHeadroomHeight) => {
        const isFixed = calcIsSidebarFixed(isHeadroomPinned, topHeadroomHeight);

        if (isFixed) {
            // After we've scrolled past the banner, set travelling sidebar top position to pin below the header, or to the bottom of the page if we've reached the bottom
            setSidebarStyle((currentStyle) => calcSidebarFixedPos(currentStyle, isHeadroomPinned, topHeadroomHeight));
        } else {
            // Don't fix to viewport if the page hasn't been scrolled down past the banner
            return setSidebarStyle({});
        }
    };

    const calcMobileNavStyles = (isHeadroomPinned, topHeadroomHeight) => {
        const isFixed = calcIsMobileNavFixed(isHeadroomPinned, topHeadroomHeight);
        // Set a class on the mobile nav to fix it
        setIsMobileNavFixed(isFixed);
        if (!isFixed) {
            // Clear headroom transforms if unfixed
            setMobileNavHeadroomStyle((style) => ({
                ...style,
                transform: 'none'
            }));
        }
    };

    const handleMobileNavClick = (e) => {
        e.currentTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
        setIsMobileNavOpened((isOpened) => !isOpened);
    };

    const stopEvent = (e) => {
        e.preventDefault();
    };

    // Run page scroll handler on the next event cycle
    // This fixes problems with clicking section links on mobile nav, which causes an instant scroll and pins the top headroom without updating the nav top offset because the scroll event handler executes before the top headroom is pinned
    const deferHandlePageScroll = () => {
        setTimeout(handlePageScroll, 0);
    };

    const handlePageScroll = (e) => {
        // add header pinned class when hero banner is about to scroll out of view
        const topHeadroomWrapper = document.querySelector('.top-headroom');
        const topHeadroom = topHeadroomWrapper.querySelector('.headroom');
        const isPinned = topHeadroom.classList.contains('headroom--pinned');

        const ratio = calcBannerScrollRatio(isPinned, topHeadroomWrapper.offsetHeight);

        // Set opacity for the hero banner and transform title header text
        calcBannerStyles(ratio);

        // Set top positions for title header
        const isDesktopHeaderFixed = ratio >= 1;
        calcHeaderStyles(isDesktopHeaderFixed, topHeadroomWrapper.offsetHeight);
        // Set top positions for travelling sidebar
        calcSidebarStyles(isPinned, topHeadroomWrapper.offsetHeight);
        // Set top positions for mobile nav
        calcMobileNavStyles(isPinned, topHeadroomWrapper.offsetHeight);
    };

    const setHeroBannerHeight = (topHeadroomHeight) => {
        // For landscape orientation only (viewport width > height)
        const isLandscape = window.innerWidth > window.innerHeight;
        // Set hero banner height to fill up the screen below the top header
        // If there is a virtual tour banner, it also needs to fit
        if (isLandscape && topHeadroomHeight) {
            setHeroBannerStyle({
                ...backgroundColor,
                height: `calc(100vh - ${topHeadroomHeight}px - ${getVirtualTourHeight()}px)`
            });
        } else {
            // Top headroom height is not ready yet
            setHeroBannerStyle(backgroundColor);
        }
    };

    const resizeHeader = (headroom) => {
        // Fix the height of the header for the pin/unpin logic to work properly
        setHeaderStyle({ height: headroom.offsetHeight });
    };

    const resizeMobileNav = (headroom) => {
        // Fix the height of the header for the pin/unpin logic to work properly
        setMobileNavStyle((style) => ({
            ...style,
            height: headroom.offsetHeight
        }));
    };

    const onTopHeadroomResize = (topHeadroom) => {
        setHeroBannerHeight(topHeadroom.offsetHeight);
        setTopHeadroomHeight(topHeadroom.offsetHeight);
    };

    useEffect(() => {
        // Adjust hero banner height whenever top header height changes (e.g. if notifications are closed)
        const topHeadroom = document.querySelector('.top-headroom');
        const topHeadroomObserver = new ResizeObserver((entries) => {
            entries.forEach((node) => onTopHeadroomResize(node.target));
        });
        topHeadroomObserver.observe(topHeadroom);

        // Adjust aex header height when contents change size
        const aexHeaderHeadroom = document.querySelector('.aex-header-desktop .aex-headroom');
        const aexHeaderObserver = new ResizeObserver((entries) => {
            entries.forEach((node) => resizeHeader(node.target));
        });
        aexHeaderObserver.observe(aexHeaderHeadroom);

        // Adjust mobile nav height when contents change size
        const mobileNavHeadroom = document.querySelector('.aex-mobile-nav .aex-headroom');
        const mobileNavObserver = new ResizeObserver((entries) => {
            entries.forEach((node) => resizeMobileNav(node.target));
        });
        mobileNavObserver.observe(mobileNavHeadroom);

        handlePageScroll();
        // Apply scroll effects on the hero banner and header
        document.addEventListener('scroll', deferHandlePageScroll);

        // Disallow scrolling when mobile nav dropdown is opened
        // We use wheel and touchmove events because the scroll event can't be canceled
        // We add event listener instead of using onWheel on the node because e.preventDefault() doesn't work on passive listeners
        // See https://stackoverflow.com/questions/63663025/react-onwheel-handler-cant-preventdefault-because-its-a-passive-event-listenev
        mobileDropdownMaskRef.current.addEventListener('wheel', stopEvent, true);
        mobileDropdownMaskRef.current.addEventListener('touchmove', stopEvent, true);
        mobileDropdownRef.current.addEventListener('touchmove', stopEvent, true);
        mobileDropdownRef.current.addEventListener('wheel', stopEvent, true);

        return () => {
            document.removeEventListener('scroll', deferHandlePageScroll);
            mobileDropdownMaskRef.current.removeEventListener('wheel', stopEvent, true);
            mobileDropdownMaskRef.current.removeEventListener('touchmove', stopEvent, true);
            mobileDropdownRef.current.removeEventListener('wheel', stopEvent, true);
            mobileDropdownRef.current.removeEventListener('touchmove', stopEvent, true);
            topHeadroomObserver.disconnect();
            aexHeaderObserver.disconnect();
            mobileNavObserver.disconnect();
        };
    }, []);

    const renderHeroBanner = () => {
        return (
            <HeroBanner style={heroBannerStyle}
                mainImage={mainImage}
                mainImageStyle={heroImageStyle}
                btnStyle={proceedBtnStyle}
                mobileImage={mobileImage || mainImage}
                target={headerRef}>
                {renderDesktopHeader()}
            </HeroBanner>
        );
    };

    // conditionally add fixed class based on the given state variable
    const addFixedClass = (cls, isFixed) => {
        return classnames(cls, {
            'fixed': isFixed,
        });
    };

    const renderHeaderContents = (contentStyle = {}) => {
        return (
            <div className="aex-header-contents" style={contentStyle}>
                <h1 className="aex-header-title" style={baseTextColor}>{name}</h1>
                <p className="aex-header-dates" style={baseTextColor}>
                    <EventDate startDate={startDate} endDate={endDate} format={DATE_FORMAT_EVENT_PAGE} />
                </p>
            </div>
        );
    };

    const renderDesktopHeader = () => {
        return (
            <header className="aex-header aex-header-desktop" ref={headerRef} style={headerStyle}>
                <Headroom className={addFixedClass('aex-headroom', isHeadroomFixed)} style={headroomStyle}
                    onPin={onPinDesktopHeadroom}
                    onUnpin={onUnpinDesktopHeadroom}
                    downTolerance={DOWN_TOLERANCE} // Scroll tolerance in px when scrolling down before component is pinned
                    disableInlineStyles={true} // Relies on page.scss styling
                >
                    <div className="constrain-width">
                        {/* header sliding animation for desktop */}
                        {renderHeaderContents(headerContentStyle)}
                    </div>
                </Headroom>
            </header>
        );
    };

    // First title header for mobile
    const renderMobileHeader = () => {
        return (
            <header className="aex-header aex-header-mobile" style={backgroundColor}>
                {renderHeaderContents()}
            </header>
        );
    };

    const renderVirtualTour = () => {
        return <VirtualTour intro={virtualTourIntro} link={virtualTourLink} ref={virtualTourRef} />;
    };

    const renderNavItem = (label, name, idx) => {
        return <a key={idx} href={`#${label}`}>{name}</a>;
    };

    // Travelling sidebar for desktop
    const renderDesktopSidebarNav = () => {
        return (
            <div className="aex-desktop-sidebar" style={sidebarStyle} ref={sidebarRef}>
                {renderNavbarContents()}
            </div>
        );
    };

    const getMobileNavDropdownCls = () => {
        return classnames('aex-mobile-navbar-dropdown', {
            open: isMobileNavOpened
        });
    };

    // Fixed collapsable nav + title header
    const renderMobileNav = () => {
        return (
            <div className="aex-mobile-nav" ref={mobileNavRef} style={mobileNavStyle}>
                <Headroom
                    className={addFixedClass('aex-headroom', isMobileNavFixed)}
                    style={mobileNavHeadroomStyle}
                    onPin={onPinMobileHeadroom}
                    onUnpin={onUnpinMobileHeadroom}
                    downTolerance={DOWN_TOLERANCE} // Scroll tolerance in px when scrolling down before component is pinned
                    disableInlineStyles={true} // Relies on page.scss styling
                >
                    <div className="aex-mobile-navbar" onClick={handleMobileNavClick} role="button"tabIndex={0}>
                        <span className="aex-mobile-section-label">About</span>
                        {bookingLink &&
                            <a href={bookingLink} target="_blank" className="btn-booking button primary center" rel="noreferrer">{bookingLinkText || 'Buy Now' }</a>
                        }
                    </div>
                    {/* dropdown covers the original navbar */}
                    <div className={getMobileNavDropdownCls()} ref={mobileDropdownRef}>
                        <div onClick={handleMobileNavClick} role="button" tabIndex={0}>
                            {renderMobileHeader()}
                        </div>
                        {renderNavbarContents()}
                    </div>
                    {/* Dropdown arrow */}
                    <Icon
                        className={classnames('icon', 'dropdown-arrow', { open: isMobileNavOpened })}
                        onClick={handleMobileNavClick}
                        name="arrow" width="15" height="15" title="" />
                    {/* Overlay for open dropdown */}
                    <div
                        className={classnames('aex-dropdown-mask', { open: isMobileNavOpened })}
                        onClick={handleMobileNavClick}
                        ref={mobileDropdownMaskRef}
                    ></div>
                </Headroom>
            </div>
        );
    };

    // In-page navbar
    const renderNavbarContents = () => {
        return (
            <nav className="aex-navbar">
                <div className="aex-navbar-links">
                    {getAexBlocks().map(({ label, name, blocks }, idx) => {
                        if (blocks && blocks.length) {
                            return renderNavItem(label, name, idx);
                        }

                        return null;
                    })}
                </div>
                {/* booking link and share buttons */}
                <div className="aex-navbar-actions">
                    {bookingLink &&
                        <a href={bookingLink} target="_blank" className="button primary center" rel="noreferrer">{bookingLinkText || 'Buy Now' }</a>
                    }
                    <SocialSharingBtn popupDir="right" />
                </div>
            </nav>
        );
    };

    const renderAexSections = () => {
        return (
            <div className="aex-sections">
                {getAexBlocks().map(({ label, name, blocks }, idx) => renderSection(label, name, blocks, idx))}
                {/* sponsors */}
                {renderSponsors()}
            </div>
        );
    };

    const renderSection = (label, name, blocks, idx) => {
        if (!blocks || !blocks.length) {
            return null;
        }

        // no need to display a title for the About section
        const displayName = name && name !== 'About';

        return (
            <section id={label} key={idx}>
                {displayName && <h2 className="aex-section-title">{name}</h2>}
                <ContentBlocks data={blocks} blockTheme="aex" blockStyle={baseTheme} />
            </section>
        );
    };

    const renderContents = () => {
        return (
            <div className="aex-page-contents constrain-width" ref={pageContentRef}>
                {/* travelling sidebar (desktop) */}
                {renderDesktopSidebarNav()}
                {/* aex sections */}
                {renderAexSections()}
            </div>
        );
    };

    const renderSponsors = () => {
        if (!sponsors || !sponsors.length) {
            return null;
        }

        // convert data format
        const sponsorData = sponsors.map((sponsor) => {
            return {
                ...sponsor,
                logo: sponsor.logoPath
            };
        });

        return (
            <aside>
                <h2 className="aex-section-title">Supported by</h2>
                {/* Similar styles as sponsor content block */}
                <Sponsor items={sponsorData} blockTheme={THEME_AEX} />
            </aside>
        );
    };

    return (
        <div className="aex-page">
            {/* banner */}
            {renderHeroBanner()}
            {/* virtual tour */}
            {renderVirtualTour()}
            {/* header (mobile) */}
            {renderMobileHeader()}
            {/* fixed nav dropdown (mobile) */}
            {renderMobileNav()}
            {renderContents()}
        </div>
    );
};

AdvancedExhibition.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        mainImage: PropTypes.string,
        mobileImage: PropTypes.string,
        start_date: PropTypes.number,
        end_date: PropTypes.number,
        virtual_tour_intro: PropTypes.string,
        virtual_tour_link: PropTypes.string,
        theme_colour: PropTypes.string,
        theme_colour_secondary: PropTypes.string,
        booking_link: PropTypes.string,
        booking_link_text: PropTypes.string,
        sponsors: PropTypes.array,
        // aex sections
        extended_about: PropTypes.array,
        extended_tickets: PropTypes.array,
        extended_events: PropTypes.array,
        extended_visitings: PropTypes.array,
        extended_virtual_tour: PropTypes.array,
        extended_extras: PropTypes.array,
        extended_faqs: PropTypes.array,
        // normal content blocks go at the end
        blocks: PropTypes.array,
    }),
};

export default AdvancedExhibition;
