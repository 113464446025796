import archiveImage from 'static/images/svgs/archive.svg';
import defaultImage from 'static/images/svgs/art-toi.svg';
import { getArchivesRoute } from 'app/utilities/routes';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

const ContentSetArchive = forwardRef(({ blockStyle = {}, item, index, showTile }, ref) => {
    const getIconStyle = () => {
        const style = {
            backgroundImage: `url(${thumbnailIcon})`
        };

        // Allow custom background color
        if (blockStyle.backgroundColor) {
            style.backgroundColor = blockStyle.backgroundColor;
        }

        return style;
    };

    const name = item.data.name;
    const url = getArchivesRoute(item.data.id);
    const image = item.data.images?.files[0]?.large;
    const thumbnailIcon = archiveImage;
    const productionDate = item.data.production_date;
    const creditLine = item.data.credit_line;
    const accessionNo = item.data.accession_no;

    return (
        <article className={showTile ? 'tile tile-archive' : 'tile-hide'} key={index} ref={ref}>
            <div >
                <Link className="tile-archive-artwork tile-thumbnail-link" to={url}>
                    <div className="tile-thumbnail || rectango">
                        <picture className="hover-area">
                            <div data-hover-text>view archive</div>
                            <div className="image-container rectango">
                                <img className="tile-thumbnail-image" src={image ? image : defaultImage} alt={name} />
                            </div>
                        </picture>
                        {thumbnailIcon && <div className="tile-thumbnail-icon" style={getIconStyle()}></div>}
                    </div>
                </Link>
            </div>
            {/* detail section */}
            <div className="tile-details">
                <h5 className="tile-title">
                    <Link to={url}>
                        {name}
                    </Link>
                </h5>
                {/* only need to display on a major project page */}
                <div className="tile-show--mp">
                    <hr />
                    <p>{productionDate}</p>
                    <p>{creditLine}</p>
                    <p>{accessionNo}</p>
                </div>
            </div>
        </article>
    );
});

ContentSetArchive.displayName = 'ContentSetArchive';

ContentSetArchive.propTypes = {
    blockStyle: PropTypes.object,
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    showTile: PropTypes.bool
};
export default ContentSetArchive;
