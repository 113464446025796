/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import updateBody from 'app/utilities/update-body';
import React, { useEffect } from 'react';

const Modal = ({ children, isActive, closeModalHandler }) => {
    useEffect(() => {
        updateBody(isActive);
    }, [isActive]);

    const modalClass = classnames('modal', { 'is-active': isActive });

    return (
        <div className={modalClass} onClick={closeModalHandler}>
            {/* Don't close the modal when content is clicked
            This is done by preventing click event from reaching the parent div */}
            <div className="content" onClick={(e) => e.stopPropagation()}>
                <button className="icon-button || button default || close-btn" onClick={closeModalHandler}>
                    <Icon name="close" width="30" height="30" />
                </button>
                {children}
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    isActive: PropTypes.bool.isRequired,
    closeModalHandler: PropTypes.func.isRequired
};

export default Modal;
