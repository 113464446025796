import classnames from 'classnames';
import { CONTENT_TYPE_OPTION } from 'config/search-options';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const IDEA_SEARCH_FILTERS = ['contentType'];

const FilterDropdownForIdea = ({ className, formik }) => {
    const [openOptionList, setOpenOptionList] = useState(false);
    const filterRef = useRef();

    const displayLabel = Object.keys(formik.values).filter((key) => {
        return IDEA_SEARCH_FILTERS.includes(key) && formik.values[key].length !== 0;
    }).reduce((prev, key) => {
        if (typeof formik.values[key] === 'string' && key !== 'undated') return prev ? `${prev},${formik.values[key]}` : formik.values[key];
        if (Array.isArray(formik.values[key])) return prev ? `${prev},${formik.values[key].join(',')}` : formik.values[key].join(',');

        return prev;
    }, '');

    const toggleDropdownHandler = () => {
        setOpenOptionList((prevValue) => !prevValue);
    };

    const renderOptions = () => {
        return (
            <div className={ classnames('options', 'idea-options', openOptionList ? '' : 'is-hidden') }>
                <div className="column">
                    <h6 className="heading || plain">Content type:</h6>
                    {CONTENT_TYPE_OPTION.map((option, index) => (
                        <div key={index} className="field-group || checkbox">
                            <label>
                                <input type="checkbox" name="contentType" value={option.value} checked={formik.values.contentType.includes(option.value)} onChange={formik.handleChange} />
                                <span>{option.label}</span>
                            </label>
                        </div>
                    ))}
                </div>
                <div className="controls">
                    <button className="button primary" type="submit">Apply</button>
                </div>
            </div>
        );
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpenOptionList(false);
        } else if (event.target.type === 'submit') {
            setOpenOptionList(false);
        }
    };

    useEffect(() => {
        const optionHeight = filterRef.current.getElementsByClassName('options')[0].clientHeight;
        const marginBottom = openOptionList ? `${optionHeight}px` : null;
        filterRef.current.style['margin-bottom'] = marginBottom;

        if (openOptionList === false) {
            return;
        }
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [openOptionList]);

    return (
        <div className={ classnames('filter-dropdown', 'wide', className, openOptionList ? 'open' : '') } ref={filterRef}>
            <div className="dropdown" onClick={toggleDropdownHandler} aria-hidden="true">
                <div className="label">Refine <span>{displayLabel === '' ? 'your search results' : displayLabel}</span></div>
                <Icon name="arrow" width="15" height="15" title="" />
            </div>
            { renderOptions() }
        </div>
    );
};

FilterDropdownForIdea.propTypes = {
    className: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired
};

export default FilterDropdownForIdea;
