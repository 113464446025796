import classnames from 'classnames';
import { dataLayerPush } from 'app/utilities/gtm';
import formatContent from 'app/utilities/format-content';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import { THEME_AEX } from 'app/utilities/content-blocks';
import React, { useState } from 'react';

const BUTTON_CLASS = 'button small';

const Faq = ({ items, blockTheme, blockStyle }) => {
    const [itemsOpen, setItemsOpen] = useState({});

    const isAex = () => blockTheme === THEME_AEX;

    const allowMultiOpen = () => {
        // Only allow advanced exhibition to open one FAQ item at a time
        return !isAex();
    };

    const isItemOpen = (index) => {
        return itemsOpen[index] === true;
    };

    const handleToggle = (index) => {
        if (isItemOpen(index)) {
            setItemsOpen({
                ...itemsOpen,
                [index]: false
            });
        } else {
            if (allowMultiOpen()) {
                setItemsOpen({
                    ...itemsOpen,
                    [index]: true
                });
            } else {
                // only allow one item to open at a time
                setItemsOpen({
                    [index]: true
                });
            }
            /* ↓ Tracking FAQ question expansion events */
            dataLayerPush({
                event: 'conversionTracking',
                category: 'Interaction',
                action: 'FAQ Click',
                label: items[index].title
            });
            /* ↑ Tracking FAQ question expansion events */
        }
    };

    const getHeaderStyle = (isOpen) => {
        // Only apply specific styles for aex
        if (isAex() && !isOpen) {
            // customized background color and text
            return blockStyle;
        }

        return {};
    };

    const renderItem = (index, { title, content }) => {
        const isOpen = isItemOpen(index);
        const openClass = {
            'is-open': isOpen
        };

        const headerClass = classnames('item-header', openClass);
        const contentClass = classnames('item-content', 'rich-editor-content', openClass);

        return (
            <div className="faq-item" key={index}>
                <button
                    className={headerClass}
                    style={getHeaderStyle(isOpen)}
                    role="tab"
                    tabIndex="0"
                    onClick={() => handleToggle(index)}
                >
                    <h4 className="title">{title}</h4>
                    <Icon name="arrow" width="15" height="15" title="" />
                </button>
                <div className={contentClass} dangerouslySetInnerHTML={{ __html: formatContent(content, BUTTON_CLASS) }}></div>
            </div>
        );
    };

    return (
        <section className={`faq ${blockTheme}`}>
            <div role="tablist" aria-multiselectable="true">
                {items.map((item, index) => {
                    return renderItem(index, item);
                })}
            </div>
        </section>
    );
};

Faq.propTypes = {
    items: PropTypes.array.isRequired,
    blockTheme: PropTypes.string,
    blockStyle: PropTypes.object
};

export default Faq;
