import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { THEME_DEFAULT } from 'app/utilities/content-blocks';

const Sponsor = ({ items, blockTheme = THEME_DEFAULT }) => {
    const renderCaption = (link, designation) => {
        if (link) {
            return <a href={link} target="_blank" rel="noreferrer">{designation}</a>;
        }

        if (designation) {
            return <p>{designation}</p>;
        }

        return null;
    };

    const renderSponsorTile = (item, index) => {
        const { link, logo, designation } = item;

        return (
            <figure className="sponsor-tile" key={index}>
                <figcaption className="sponsor-tile-title">
                    {renderCaption(link, designation)}
                </figcaption>
                <picture>
                    <img className="sponsor-tile-image" src={logo} alt={`${designation} Logo`} />
                </picture>
            </figure>
        );
    };

    return (
        <div className={classnames('sponsor-row', blockTheme)}>
            {items.map((item, index) => {
                return renderSponsorTile(item, index);
            })}
        </div>
    );
};

Sponsor.propTypes = {
    items: PropTypes.array.isRequired,
    blockTheme: PropTypes.string
};
export default Sponsor;

