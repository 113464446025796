import PropTypes from 'prop-types';
import React from 'react';

const EntitySpecDetails = ({ rows }) => {
    const renderValue = (value) => {
        // Render as element
        if (React.isValidElement(value)) {
            return <dd>{value}</dd>;
        }

        // Render as HTML innerText
        let renderVal;
        if (Array.isArray(value)) {
            renderVal = value.join('<br />');
        } else {
            renderVal = value || 'Unknown';
        }

        return <dd className='rich-editor-content' dangerouslySetInnerHTML={{ __html: renderVal }}></dd>;
    };

    const renderSpecDetailRow = (label, value, idx) => {
        // Production date entry should display even if the value is empty
        if (value || label.toLowerCase() === 'production date') {
            return (
                <React.Fragment key={idx}>
                    <dt>{label}</dt>
                    {renderValue(value)}
                </React.Fragment>
            );
        }

        return null;
    };

    if (!rows.length) {
        return null;
    }

    return (
        <div className="spec-details">
            <dl>
                {rows.map(({ label, value }, idx) => renderSpecDetailRow(label, value, idx))}
            </dl>
        </div>
    );
};

EntitySpecDetails.propTypes = {
    rows: PropTypes.array.isRequired
};

export default EntitySpecDetails;
