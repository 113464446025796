import Carousel from 'app/components/content-blocks/carousel';
import ContentBlocks from 'app/components/content-blocks/index';
import ContentSetEvent from 'app/components/partials/content-sets/content-set-event';
import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { Link } from 'react-router-dom';
import Loader from '../partials/loader';
import NewsLetterSignup from '../partials/news-letter-signup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Home = ({ data }) => { // eslint-disable-line require-jsdoc
    const { blocks, title } = data;

    const heroCarouselData = blocks.filter((block) => block.type === 'carousel')[0].data;
    const nonHeroCarouselBlockData = blocks.filter((block) => block.type !== 'carousel');
    const firstBlockData = [nonHeroCarouselBlockData[0]];
    const restBlockData = nonHeroCarouselBlockData.splice(1);

    const [isLoading, setIsLoading] = useState(false);
    const [featuredExhibitions, setFeaturedExhibitions] = useState();

    const formatFeaturedExhibitionData = (resp) => {
        let ary = resp.data || [];
        // filter out feature order not equals to 0
        ary = ary.filter((item) => {
            if (item.attributes.featured_order > 0) return item;

            return;
        });
        // sort by feature order
        ary = ary.sort((a, b) => {
            return a.attributes.featured_order - b.attributes.featured_order;
        });
        // only display 3 at home page
        // eslint-disable-next-line no-magic-numbers
        ary = ary.slice(0, 3);

        return ary;
    };

    const fetchFeaturedExhitbitions = async() => {
        setIsLoading(true);
        try {
            const resp = await fetch(ENDPOINTS.FEATURED_EXHIBITIONS);
            const ary = formatFeaturedExhibitionData(resp);
            const exhibitions = ary.map((data, index) => {
                const item = { data: { ...data.attributes, type: data.type } };
                // Home page only display 3 exhibtions

                return <ContentSetEvent key={index} item={item} index={index} showTile={true} isFeatured ={true} />;
            });
            setFeaturedExhibitions(exhibitions);
        } finally {
            setIsLoading(false);
        }
    };

    const renderAlgorithm = () => {
        return (
            <div className="constrain-width">
                <div className="algorithm-home banner">
                    <div className="algorithm-home-left">
                        <h2>Open every day 10am — 5pm.</h2>
                        <h3>except Christmas Day</h3>
                        <p>Cnr Kitchener and Wellesley Streets</p>
                        <Link to="/visit/visitor-information" className="button || primary || small" >PLAN YOUR VISIT</Link>
                    </div>
                    <div className="algorithm-home-right">
                        <p className="white || text-block">
                            Sto<br />
                            <br />
                        </p>
                        <p className="red || text-block">
                            A<br />
                            R<br />
                            T<br />
                        </p>
                        <p className="white || text-block">
                            Y<br />
                            OLD<br />
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        fetchFeaturedExhitbitions();
    }, []);

    // Featured exhibtion not used anywhere else. So not build it into component
    // and has viewed all on the title
    const renderFeaturedExhibtion = <div className="tile-block || content-set">
        <h2 className="tile-block-title || featured-row">CURRENT EXHIBITIONS
            <Link className="button || default || tiny" to="/search/exhibitions?date-range=today">View all</Link></h2>
        <div className="tile-block-inner">
            {featuredExhibitions}
        </div>
    </div>;

    return (
        <div className="content-page">
            <div className="constrain-width">
                <Carousel items={heroCarouselData} />
                {isLoading ? <Loader type="loader-center" /> : renderFeaturedExhibtion}
            </div>
            <NewsLetterSignup />
            <ContentBlocks data={firstBlockData} pageTitle={title} extraClass="constrain-width" />
            {renderAlgorithm()}
            <ContentBlocks data={restBlockData} pageTitle={title} extraClass="constrain-width" />
        </div>
    );
};

Home.propTypes = {
    data: PropTypes.object.isRequired
};

export default Home;
