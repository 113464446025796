import classnames from 'classnames';
import Icon from 'app/components/partials/icon';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

const FilterDropdown = ({ className, filterName, filterOptions, filterLable, defaultOptionIndex, formik, clickToSubmit = true, value }) => {
    const [searchParams] = useSearchParams();
    const [openOptionList, setOpenOptionList] = useState(false);
    const filterValue = value ? value : searchParams.get(filterName);
    const [selectedOption, setSelectedOption] = useState(filterOptions.find((option) => option.value === filterValue) || filterOptions[defaultOptionIndex] || filterOptions[0]);
    const filterRef = useRef();

    const toggleDropdownHandler = () => {
        setOpenOptionList((prevValue) => !prevValue);
    };

    const filterHandler = (key, value) => {
        formik.setFieldValue(key, value);
        setOpenOptionList(false);
        setSelectedOption(filterOptions.find((option) => option.value === value));
        if (clickToSubmit) formik.submitForm();
    };

    const renderOptions = (options, parameterName) => {
        const optionDoms = (
            <div className="options">
                <ul>
                    {options.map((option, index) => {
                        return <li key={index}><button type="button" className="link" onClick={() => filterHandler(parameterName, option.value)}>{option.label}</button></li>;
                    })}
                </ul>
            </div>
        );

        return optionDoms;
    };

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpenOptionList(false);
        }
    };

    useEffect(() => {
        if (openOptionList === false) {
            return;
        }
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [openOptionList]);

    return (
        <div className={ classnames('filter-dropdown', className, openOptionList ? 'open' : '') } ref={filterRef}>
            <div className="dropdown" onClick={toggleDropdownHandler} aria-hidden="true">
                {openOptionList ? (
                    <div className="label">Select <span>{filterLable}</span></div>
                ) : (
                    <div className="label">{ filterName === 'sort' ? 'Sort by' : 'For'} <span>{selectedOption && selectedOption.label}</span></div>
                )}
                <Icon name="arrow" width="15" height="15" title="" />
            </div>
            { openOptionList && renderOptions(filterOptions, filterName) }
        </div>
    );
};

FilterDropdown.propTypes = {
    className: PropTypes.string.isRequired,
    filterName: PropTypes.string.isRequired,
    filterOptions: PropTypes.array.isRequired,
    filterLable: PropTypes.string.isRequired,
    defaultOptionIndex: PropTypes.number,
    formik: PropTypes.object.isRequired,
    clickToSubmit: PropTypes.bool.isRequired,
    // support passing in a filter value instead of using search params (hack for what's on page)
    value: PropTypes.string,
};

export default FilterDropdown;
